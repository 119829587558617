import * as actionTypes from './actionTypes';

const initialState = {
    allDistrict: [],
    electricityDutyModal: false,
    allQuery: [],
    allQueryLength: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DISTRICT:
            state = {
                ...state,
                allDistrict: action.payload
            }
            break;
        case actionTypes.UPDATE_MODAL:
            state = {
                ...state,
                electricityDutyModal: action.payload,
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
    }
    return state;
}

export default reducer;