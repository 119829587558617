import React, { useEffect } from "react";
import { useLocation } from "react-router";
import MiddleBottomFooter from "./middleBottomFooter/middleBottomFooter";
import MiddleTopFooter from "./middleTopFooter/middleTopFooter";
import MiddleFooter from "./middleFooter/middleFooter";
import TopFooter from "./topFooter/topFooter";
import { useState } from "react";

const Footer = () => {

    const [middle, setMiddle] = useState(false)
    const location = useLocation();

    React.useEffect(() => {
        // const script = document.createElement("script");
        // script.setAttribute("src", "https://platform.twitter.com/widgets.js");
        // document.getElementsByClassName("lists")[0].appendChild(script);
    }, []);

    useEffect(() => {
        // console.log(location.pathname)
        if (!location.pathname.includes("investor-home")) {
            setMiddle(true)
            // console.log(location.pathname)
        }
        else {
            setMiddle(false)
        }
    }, [location.pathname])

    // console.log(middle)

    return (
        <div>
            {/* <TopFooter /> */}
            {/* {
                middle
                    ?
                    <MiddleFooter />
                    : null
            } */}

            <MiddleTopFooter />
            <MiddleBottomFooter />
            
            

        </div>
    );
};

export default Footer;
