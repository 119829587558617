import * as actionTypes from './actionTypes';
import { caf1Tabs, unitTabs, enterpriseTabs, caf2Tabs, caf3Tabs } from "../../../shared/investor/tabConstants";

const initialState = {
    operation: "view",
    enterpriseId: null,
    enterpriseName: null,
    unitId: null, //a57e3515-85a0-44d3-a894-9320eb89ab0c
    cafIIndex: 1,
    cafId: null,
    cafData: null,
    selectedCaf: "",
    selectedSection: "",

    caf1Tabs: {
        ...caf1Tabs
    },
    enterpriseTabs: {
        ...enterpriseTabs
    },
    unitTabs: {
        ...unitTabs
    },
    caf2Tabs: {
        ...caf2Tabs,
    },
    caf3Tabs: {
        ...caf3Tabs
    },
    unitGeneral: {},
    unitLocation: {},
    unitLand: {},
    allEnterprise: [],
    unitLogs: [],
    completedIncentive: [],
    completedFC: [],
    completedDOP: [],
    paymentInfo: {},
    paymentHtml: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DASHBOARD_OPERATION:
            state = {
                ...state,
                operation: action.payload
            }
            break;
        case actionTypes.UPDATE_ENTERPRIZE_ID:
            state = {
                ...state,
                enterpriseId: action.payload.eId,
                enterpriseName: action.payload.name,
            }
            break;
        case actionTypes.UPDATE_ENTERPRIZE_NAME:
            state = {
                ...state,
                enterpriseName: action.payload
            }
            break;
        case actionTypes.UPDATE_UNIT_ID:
            state = {
                ...state,
                unitId: action.payload
            }
            break;
        case actionTypes.UPDATE_CAF_ID:
            state = {
                ...state,
                cafId: action.payload.id,
                cafData: action.payload.cafData
            }
            break;
        case actionTypes.UPDATE_SELECTED_CAF:
            state = {
                ...state,
                selectedCaf: action.payload
            }
            break;
        case actionTypes.UPDATE_SELECTED_SECTION:
            state = {
                ...state,
                selectedSection: action.payload
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                [action.payload.tabs]: action.payload.data
            }
            break;
        case actionTypes.UPDATE_UNIT:
            state = {
                ...state,
                [action.payload.section]: action.payload.data
            }
            break;
        case actionTypes.UPDATE_ALL_ENTERPRISE:
            state = {
                ...state,
                allEnterprise: action.payload
            }
            break;
        case actionTypes.UPDATE_LOGS:
            state = {
                ...state,
                unitLogs: action.payload
            }
        case actionTypes.COMPLETED_INCENTIVE:
            state = {
                ...state,
                completedIncentive: action.payload
            }
        case actionTypes.COMPLETED_FC:
            state = {
                ...state,
                completedFC: action.payload
            }
        case actionTypes.COMPLETED_DOP:
            state = {
                ...state,
                completedDOP: action.payload
            }
        case actionTypes.UPDATE_PAYMENT_INFO:
            state = {
                ...state,
                paymentInfo: action.payload
            }
        case actionTypes.UPDATE_PAYMENT_HTML:
            state = {
                ...state,
                paymentHtml: action.payload
            }
        default:
            state = { ...state };
            break;

    }
    return state;
}

export default reducer;