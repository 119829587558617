import React from "react";
import Footer from "../containers/homepage/footer/footer";
import Nav from "../containers/homepage/nav/nav";

const Layout = (props) => {
  return (
    <>
      <Nav
        section={props.section}
      />
      {props.children}
      <Footer />
    </>
  );
};

export default Layout;
