//Check tab status 
import { caf1Tabs, unitTabs, enterpriseTabs } from "./tabConstants";

export const checkTabStatus = (filled, locked, queried, tabData) => {
    // console.log(filled, locked, queried, tabData)
    // if(filled){
    //     filled = JSON.parse(filled)
    // }
    // if(locked){
    //     locked = JSON.parse(locked)
    // }
    // if(queried){
    //     queried = JSON.parse(queried)
    // }
    let _tabs = { ...tabData };
    let keys = Object.keys(_tabs);
    filled && filled.map((f) => {
        // console.log(f)
        _tabs[keys[parseInt(f) - 1]] = "filled"
    })

    // console.log(_tabs)

    locked && locked.map((f) => {
        _tabs[keys[parseInt(f) - 1]] = "locked"
    })

    queried && queried.map((f) => {
        _tabs[keys[parseInt(f) - 1]] = "queried"
    })

    return _tabs;
}


