import {
    SET_REGISTER_USER_DATA,
    SET_SEND_OTP_DATA,
    SET_VERIFY_OTP_DATA,
    SET_USER_LOGIN_DATA,
    SET_GENDER_LIST_DATA,
    SET_AUTH_LOADING,
    SET_AUTH_SUCCESS,
    SET_AUTH_ERROR,
    OTP_ACTION,
    UPDATE_GENDER,
    SENT_FP_OTP
} from "./actionTypes";

const initialState = {
    registerUserData: {},
    sendOtpData: {},
    verifyOtpData: {},
    userLoginData: {},
    genderListData: {},
    loading: false,
    error: false,
    success: false,

    emailOTPAction: "new",
    phoneOTPAction: "new",
    allGender: [],
    forgotStatus: "new" // new, otp, completed,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REGISTER_USER_DATA:
            return {
                ...state,
                registerUserData: action.registerUserData,
            };
        case SET_SEND_OTP_DATA:
            return {
                ...state,
                sendOtpData: action.sendOtpData,
            };
        case SET_VERIFY_OTP_DATA:
            return {
                ...state,
                verifyOtpData: action.verifyOtpData,
            };
        case SET_USER_LOGIN_DATA:
            return {
                ...state,
                userLoginData: action.loginUserData,
            };
        case SET_GENDER_LIST_DATA:
            return {
                ...state,
                genderListData: action.genderListData,
            };
        case SET_AUTH_LOADING:
            return {
                ...state,
                loading: action.authLoadingValue,
            };
        case SET_AUTH_SUCCESS:
            return {
                ...state,
                success: action.authSuccessValue,
            };
        case SET_AUTH_ERROR:
            return {
                ...state,
                error: action.authErrorValue,
            };
        case OTP_ACTION:
            return {
                ...state,
                [action.payload.section]: action.payload.action,
            };
        case UPDATE_GENDER:
            return {
                ...state,
                allGender: action.payload,
            };
        case SENT_FP_OTP:
            return {
                ...state,
                forgotStatus: action.payload,
            };
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
