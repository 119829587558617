import * as actionTypes from './actionTypes'

const defaultFilter = {
    search: '',
    sort: {
        attributes: ['id'],
        sorts: ['desc'],
    },
    pageNo: 1,
    itemsPerPage: parseInt(process.env.REACT_APP_ITEMS_PER_PAGE),
}

const initialState = {
    serviceType: '',
    tableProcessed: {
        ...defaultFilter,
    },
    allFilter: [['stage','!=',''],
    ['finalSubmit','=','Yes']],
    allDataProcessed: [],
    totalProcessed: 0,
    AllPendingData: [],
    AllStatusData: [],
    firstLoad: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ON_LOAD:
            state = {
                ...state,
                AllPendingData: action.payload.pending,
                AllStatusData: action.payload.status,
                firstLoad: true,
            }
            break
        case actionTypes.UPDATE_TABLE_PARAMETER:
            state = {
                ...state,
                [action.payload.table]: action.payload.data,
            }
            break
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                allFilter: action.payload,
            }
            break
        case actionTypes.UPDATE_SECTION:
            state = {
                ...state,
                serviceType: action.payload,
            }
            break
        case actionTypes.UPDATE_PROCESSED:
            state = {
                ...state,
                allDataProcessed: action.payload.allDataProcessed,
                totalProcessed: action.payload.totalProcessed,
            }
            break
        case actionTypes.UPDATE_FIRST_LOAD:
            state = {
                ...state,
                firstLoad: action.payload,
            }
            break;
        case actionTypes.INITIAL_STATE:
            state = {
                ...state,
                tableProcessed: { ...defaultFilter },
                allFilter: [],
                allDataProcessed:[],
                totalProcessed:0
            }
            break
        default:
            state = { ...state }
            break
    }
    // console.log(state);
    return state
}

export default reducer
