import * as actionTypes from './actionTypes';


const initialState = {
    questions: [],
    unitId: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ONLOAD:
            state = {
                ...state,
                questions: action.payload.question,
            }
            break;
        case actionTypes.UPDATE_UNIT_ID:
            state = {
                ...state,
                unitId: action.payload
            }
            break;
    }
    return state;
}

export default reducer;