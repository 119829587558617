import * as actionTypes from './actionTypes';
import { dopTabs } from "./tabs"

const initialState = {
    dopId: "",
    dopTabs: {
        ...dopTabs
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DOP_ID:
            state = {
                ...state,
                dopId: action.payload,
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                dopTabs: action.payload
            }
            break;
    }
    return state;
}

export default reducer;