import * as actionTypes from './actionTypes';

const initialState = {
    allStackHolder: [],
    allDesignation: [],
    allSocialStatus: [],
    allGender: [],
    allStackHolderType: [],
    allQuery: [],
    allQueryLength: 0,
    complete: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ONLOAD:
            state = {
                ...state,
                allData: action.allData,
                total: action.total
            }
            break;
        case actionTypes.UPDATE_INITIAL:
            state = {
                ...state,
                allStackHolder: []
            }
            break;
        case actionTypes.UPDATE_STACK_HOLDERS:
            state = {
                ...state,
                allStackHolder: action.payload
            }
            break;
        case actionTypes.UPDATE_SOCIAL_TYPE_DESIGNATION:
            state = {
                ...state,
                allDesignation: action.payload.designation,
                allSocialStatus: action.payload.social,
                allStackHolderType: action.payload.type,
                allGender: action.payload.gender
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.COMPLETE:
            state = {
                ...state,
                complete: action.payload
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;