import * as actionTypes from './actionTypes';
import { quarterReport } from "../../../shared/investor/tabConstants"

const initialState = {
    selectedWizard: "1",
    quarterTabs: {
        ...quarterReport
    },
    quarterId: null,
    allData: {},
    allEnterprise: [],
    isExpansion: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DATA:
            state = {
                ...state,
                allData: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_ENT:
            state = {
                ...state,
                allEnterprise: action.payload,
            }
            break
        case actionTypes.UPDATE_WIZARD:
            state = {
                ...state,
                selectedWizard: action.payload,
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                quarterTabs: action.payload
            }
            break;
        case actionTypes.UPDATE_QUARTER_ID:
            state = {
                ...state,
                quarterId: action.payload
            }
            break;
    }
    return state;
}

export default reducer;