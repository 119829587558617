import axiosAPI from 'axios';

const axios = axiosAPI.create({
    baseURL: process.env.REACT_APP_API_URL
})

const departmentAxios = axiosAPI.create({
    baseURL: process.env.REACT_APP_API_URL_DEPARTMENT
})
 
export { departmentAxios , axios };