
const scene = "investor/caf1/unit";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ONLOAD = `${scene}UPDATE_ONLOAD`;
export const UPDATE_UNIT_DATA = `${scene}UPDATE_UNIT_DATA`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;

export const UPDATE_SECTOR = `${scene}UPDATE_SECTOR`;
export const UPDATE_SUB_SECTOR = `${scene}UPDATE_SUB_SECTOR`;
export const UPDATE_BLOCK = `${scene}UPDATE_BLOCK`;
export const UPDATE_VILLAGE = `${scene}UPDATE_VILLAGE`;
export const UPDATE_OWNERSHIP_TYPE = `${scene}UPDATE_OWNERSHIP_TYPE`;
export const UPDATE_COMPLETED = `${scene}UPDATE_COMPLETED`;