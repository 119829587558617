
let initialPath = "/department";


export const departmentMenu = [
    {
        name: "Dashboard",
        path: "",
        compulsory: false,
        children:[
            {
                name: "Dashboard",
                path: initialPath + "/dashboard",
            },
        ]
    },
    {
        name: "User management",
        path: "",
        component: "",
        compulsory: false,
        children: [
            {
                name: "Add Department User",
                path: initialPath + "/add_user",
            },
            {
                name: "All Users",
                path: initialPath + "/users",
            },
            {
                name: "Unblock user",
                path: initialPath + "/unblock_user",
            }
        ]
    },
    {
        name: "Workflow management",
        path: "",
        component: "",
        compulsory: false,
        children: [
            {
                name: "Add Role",
                path: initialPath + "/add_role",
            },
            {
                name: "Create Group",
                path: initialPath + "/create_group",
            },
            {
                name: "Add/Map Group",
                path: initialPath + "/add_map_group",
            },
            {
                name: "Workflow User Configuration",
                path: initialPath + "/workflow_user_configuration",
            },
            {
                name: "Workflow Configuration",
                path: initialPath + "/workflow_configuration",
            },
            {
                name: "Update Workflow Actions",
                path: initialPath + "/update_workflow_actions",
            }
        ]
    },
    {
        name: "MIS Report",
        path: "",
        component: "",
        compulsory: false,
        children: [
            {
                name: "MIS Report",
                path: initialPath + "/mis_report",
            }
        ]
    },
    {
        name: "Application Management",
        path: "",
        component: "",
        compulsory: false,
        children: [
            {
                name: "Admin Dashboard",
                path: initialPath + "/admin_dashboard",
            },
            {
                name: "Check Application Details",
                path: initialPath + "/application_detail",
            },
            {
                name: "Role Access",
                path: initialPath + "/role_access",
            },
            {
                name: "Document Management",
                path: initialPath + "/document_management",
            },
            {
                name: "Change Ownership",
                path: initialPath + "/ownership",
            },
            {
                name: "Upload Proceeding",
                path: initialPath + "/upload_proceeding",
            },
            {
                name: "DMS",
                path: initialPath + "/dms",
            }
        ]
    },
    {
        name: "CMS",
        path: "",
        component: "",
        compulsory: false,
        children: [
            {
                name: "Guidelines",
                path: initialPath + "/guidelines",
            },
            {
                name: "Policies",
                path: initialPath + "/policies",
            },
            {
                name: "Banner",
                path: initialPath + "/banner",
            },
            {
                name: "Contacts",
                path: initialPath + "/contacts",
            },
            {
                name: "MISC_DOCUMENTS",
                path: initialPath + "/documents",
            },
        ]
    },
    {
        name: "SIPB Meeting",
        path: "",
        component: "",
        compulsory: false,
        children: [
            {
                name: "SIPB Meeting",
                path: initialPath + "/sipb_meeting",
            }
        ]
    }

]