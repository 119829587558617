import {
  // Loading
  SET_LOCATION_DETAILS_LOADING,
  // Error
  SET_LOCATION_DETAILS_ERROR,
  // Success
  SET_LOCATION_DETAILS_SUCCESS,
  // Main Activity List
  SET_MAIN_ACTIVITY_LIST_DATA,
  // Located In
  SET_LOCATED_IN_DATA,
  // Sector List
  SET_SECTOR_LIST_DATA,
  // Sub Sector List
  SET_SUB_SECTOR_LIST_DATA,
  // Country List
  SET_COUNTRY_LIST_DATA,
  // State List
  SET_STATE_LIST_DATA,
  // District List
  SET_DISTRICT_LIST_DATA,
  // Block List
  SET_BLOCK_LIST_DATA,
  // Taluka List
  SET_TALUKA_LIST_DATA,
  // City List
  SET_CITY_LIST_DATA,
  SET_OWNERSHIP_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: false,
  success: false,
  // Main Activity List
  mainActivityListData: [],
  // Located In
  locatedInData: [],
  //Ownership type:[],
  ownershipType: [],
  // Sector List
  sectorListData: [],
  // Sub Sector List
  subSectorListData: [],
  // Country List
  countryListData: [],
  // State List
  stateListData: [],
  // District List
  districtListData: [],
  // Block List
  blockListData: [],
  // Taluka List
  talukaListData: [],
  // City List
  cityListData: [],
};

const UnitLocationDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Loading
    case SET_LOCATION_DETAILS_LOADING:
      return {
        ...state,
        loading: action.locationDetailsLoadingValue,
      };
    // Error
    case SET_LOCATION_DETAILS_ERROR:
      return {
        ...state,
        error: action.locationDetailsErrorValue,
      };
    // Success
    case SET_LOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        success: action.locationDetailsSuccessValue,
      };
    // Main Activity List
    case SET_MAIN_ACTIVITY_LIST_DATA:
      return {
        ...state,
        mainActivityListData: action.mainActivityListData,
      };
    // Located In
    case SET_LOCATED_IN_DATA:
      return {
        ...state,
        locatedInData: action.locatedInData,
      };
    case SET_OWNERSHIP_DATA:
      return {
        ...state,
        ownershipType: action.ownershipType,
      };
    // Sector List
    case SET_SECTOR_LIST_DATA:
      return {
        ...state,
        sectorListData: action.sectorListData,
      };
    // Sub Sector List
    case SET_SUB_SECTOR_LIST_DATA:
      return {
        ...state,
        subSectorListData: action.subSectorListData,
      };
    // Country List
    case SET_COUNTRY_LIST_DATA:
      return {
        ...state,
        countryListData: action.countryListData,
      };
    // State List
    case SET_STATE_LIST_DATA:
      return {
        ...state,
        stateListData: action.stateListData,
      };
    // District List
    case SET_DISTRICT_LIST_DATA:
      return {
        ...state,
        districtListData: action.districtListData,
      };
    // Block List
    case SET_BLOCK_LIST_DATA:
      return {
        ...state,
        blockListData: action.blockListData,
      };
    // Taluka List
    case SET_TALUKA_LIST_DATA:
      return { ...state, talukaListData: action.talukaListData };
    // City List
    case SET_CITY_LIST_DATA:
      return { ...state, cityListData: action.cityListData };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UnitLocationDetailsReducer;
