import * as actionTypes from './actionTypes';


const tableProcessed = {
    "search": "",
    "sort": {
        "attributes": ["applicationNo"],
        "sorts": ["desc"]
    },
    "pageNo": 1,
    "itemsPerPage": parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)
};


const initialState = {
    selectedSection: "",
    tableProcessed: {
        ...tableProcessed
    },
    allFilterAssigned: [],
    allDataProcessed: [],
    totalProcessed: 0,
    selectedId: [],
    allOwnershipUser:[]
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TABLE_PARAMETER:
            state = {
                ...state,
                [action.payload.table]: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_SECTION:
            state = {
                ...state,
                selectedSection: action.payload,
            }
            break;
        case actionTypes.UPDATE_ALL_DATA:
            state = {
                ...state,
                allDataProcessed: action.payload.allDataProcessed,
                totalProcessed: action.payload.totalProcessed
            }
            break;
        case actionTypes.UPDATE_PROCESSED:
            state = {
                ...state,
                allDataProcessed: action.payload.allDataProcessed,
                totalProcessed: action.payload.totalProcessed,
            }
            break;
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                allFilterAssigned:action.payload,
            }
            break;
        case actionTypes.INITIAL_STATE:
            state = {
                ...state,
                tableProcessed: {
                    ...tableProcessed
                },
                allDataProcessed: [],
                totalProcessed: 0,
            }
            break
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;