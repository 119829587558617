import * as actionTypes from './actionTypes';

const initialState = {
    infoJson:[],
    tableProcessed: {
        "search": "",
        "sort": {
            "attributes": ["id"],
            "sorts": ["desc"]
        },
        "pageNo": 1,
        "itemsPerPage": parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)
    },
    innerData:{
        approved:[],
        pending:[],
        rejected:[],
        query:[],
        services:[],
    },
    innerDataInc11:{
        approved:[],
        pending:[],
        rejected:[],
        query:[],
        services:[],
    },
    pendency:{},
    allDataProcessed: [],
    totalProcessed: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ALL_DATA:
            state = {
                ...state,
                infoJson: action.payload
            }
            break;
        case actionTypes.UPDATE_PROCESSED:
            state = {
                ...state,
                    allDataProcessed: action.payload.allDataProcessed,
                    totalProcessed: action.payload.totalProcessed
            }
            break;
        case actionTypes.UPDATE_INNERDATA:
                state = {
                    ...state,
                        innerData: action.payload.data
                }
                break;
        case actionTypes.UPDATE_INNERDATA_INC11:
                state = {
                    ...state,
                    innerDataInc11: action.payload.data
                }
                break;
        case actionTypes.UPDATE_PROCESSED_INITAL:
                state = {
                    ...state,
                    allDataProcessed: action.payload.allDataProcessed,
                    totalProcessed: action.payload.totalProcessed,
                    tableProcessed: {...state.tableProcessed, pageNo: 1, search: ""}
                }
                break;
        case actionTypes.UPDATE_TABLE_PARAMETER:
            state = {
                ...state,
                [action.payload.table]: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_PENDENCY:
                state = {
                    ...state,
                    pendency: action.payload,
                }
                break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;