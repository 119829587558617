import * as actionTypes from './actionTypes';

const initialState = {
    allData: {},
    finalSubmit: false,
    otpSent: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ALL_DATA:
            state = {
                ...state,
                allData: action.payload,
            }
            break;
        case actionTypes.UPDATE_FINAL_SUBMIT:
            state = {
                ...state,
                finalSubmit: action.payload,
            }
            break;
        case actionTypes.UPDATE_OTP_SENT:
            state = {
                ...state,
                otpSent: action.payload,
            }
            break;
        case actionTypes.UPDATE_INITIAL:
            state = {
                ...state,
                finalSubmit: false,
                otpSent: false,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;