const scene = "investor     /grievance";


export const UPDATE_GRIEVANCE = `${scene}UPDATE_GRIEVANCE`;
export const UPDATE_TABLE = `${scene}UPDATE_TABLE`;
export const UPDATE_DROPDOWN = `${scene}UPDATE_DROPDOWN`;
export const UPDATE_SELECTED_SECTION = `${scene}UPDATE_SELECTED_SECTION`;
export const UPDATE_SINGLE_DATA = `${scene}UPDATE_SINGLE_DATA`;
export const ADD_QUERY = `${scene}ADD_QUERY`;
export const TOGGLE_ADD_FLAG = `${scene}TOGGLE_ADD_FLAG`;
export const UPDATE_SINGLE = `${scene}UPDATE_SINGLE`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
export const UPDATE_TABS = `${scene}UPDATE_TABS`;





