import * as actionTypes from "./actionTypes";
import { getAPI, postAPI, putAPI, getToken } from "../../apiCalls/functions"
import {
    UPLOAD_FILE, GET_FILE, UPDATE_FILE
} from "../../apiCalls/urls/investor/dms/index";
import { axios } from '../../shared/axios';
import { getDataFromStore } from "../../store/getStore"
import { actionNotifier } from "../..//components/ui/toast/toast";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action";

const softwareSection = process.env.REACT_APP_INVESTOR_SECTION_NAME

export const getAllFle = async (filter) => {

    let url = GET_FILE + `pageNo=${filter.pageNo}&limit=${filter.limit}`;

    if (filter.fileType) {
        url += `&fileType=${filter.fileType}`
    }
    if (filter.tag) {
        url += `&tag=${filter.tag}`
    }
    if (filter.date) {
        url += `&date=${filter.date}`
    }

    return getAPI(url, softwareSection)
}

export const updateDesc = (tag, id) => {
    let data = {
        "tag": tag,
        "documentId": id
    }
    return putAPI(UPDATE_FILE, data, softwareSection)
}

export const uploadFile = (file, getAllData) => {
    return (dispatch) => {
        console.log(file);
        // dispatch(apiLoading())
        let auth = getDataFromStore("Auth")

        console.log(auth);

        let formData = new FormData()
        formData.append("file", file.file)
        formData.append("investorId", auth.userLoginData.data[0].investorId)
        formData.append("tag", file.tag)

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                token: getToken(softwareSection),
            },
            onUploadProgress: progressEvent => {
                dispatch(updateLoadingPercentage(Math.round(progressEvent.loaded / progressEvent.total * 100)))
                console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%');
            }
        }
        axios.post(UPLOAD_FILE, formData, config)
            .then(res => {
                let { data } = res.data;
                dispatch(apiLoadingEnd())
                dispatch(updateLoadingPercentage(0))
                actionNotifier.success("Added")
                getAllData()
                return data;
            })
            .catch(err => {
                console.log(err)
                dispatch(apiLoadingEnd())
                dispatch(updateLoadingPercentage(0))
                actionNotifier.error("Error")
                return err;
            })
    }
}

export const updateLoadingPercentage = (percent) => {
    return {
        type: actionTypes.UPDATE_PERCENT,
        payload: percent
    };
}


export const toggleDms = () => {
    return {
        type: actionTypes.TOGGLE_DMS,
    };
}

export const uploadDocument = (data, allStructDoc, features) => {
    return {
        type: actionTypes.UPDATE_DOCUMENT,
        payload: { data, allStructDoc, features }
    };
}
