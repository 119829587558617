import * as actionTypes from './actionTypes';

const initialState = {
    allData: {},
    allDocumentType: [],
    allDocuments: [],
    complete: false,
    allQuery: [],
    allQueryLength: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ONLOAD:
            state = {
                ...state,
            }
            break;
        case actionTypes.UPDATE_DOCUMENT_TYPE:
            state = {
                ...state,
                allDocumentType: action.payload
            }
            break;
        case actionTypes.UPDATE_DOCUMENT:
            state = {
                ...state,
                allDocuments: action.payload
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload?.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.COMPLETE:
            state = {
                ...state,
                complete: action.payload
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;