import {
  SET_CRUD_LOADING,
  SET_CRUD_ERROR,
  SET_CRUD_SUCCESS,
  SET_UNIT_LIST_DATA,
  SET_UNIT_BY_ID_DATA,
  SET_CREATE_UNIT_DATA,
  SET_EDIT_UNIT_DATA,
  SET_DELETE_UNIT_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: false,
  success: false,
  createUnitData: {},
  unitListData: {},
  unitByIdData: {},
  editUnitData: {},
  deleteUnitData: {},
};

const UnitCrudReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create Unit
    case SET_CREATE_UNIT_DATA:
      return {
        ...state,
        createUnitData: action.createUnitData,
      };
    // Unit List
    case SET_UNIT_LIST_DATA:
      return {
        ...state,
        unitListData: action.unitListData,
      };
    // Unit By ID
    case SET_UNIT_BY_ID_DATA:
      return {
        ...state,
        unitByIdData: action.unitByIdData,
      };
    // Edit Unit
    case SET_EDIT_UNIT_DATA:
      return {
        ...state,
        editUnitData: action.editUnitData,
      };
    // Delete Unit
    case SET_DELETE_UNIT_DATA:
      return {
        ...state,
        deleteUnitData: action.deleteUnitData,
      };
    // Loading
    case SET_CRUD_LOADING:
      return {
        ...state,
        loading: action.crudLoadingValue,
      };
    // Error
    case SET_CRUD_ERROR:
      return {
        ...state,
        error: action.crudErrorValue,
      };
    // Success
    case SET_CRUD_SUCCESS:
      return {
        ...state,
        success: action.crudSuccessValue,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UnitCrudReducer;
