
const scene = "investor/caf1/investment";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ONLOAD = `${scene}UPDATE_ONLOAD`;
export const UPDATE_INVESTMENT = `${scene}UPDATE_INVESTMENT`;
export const UPDATE_INVESTMENT_UNIT = `${scene}UPDATE_INVESTMENT_UNIT`
export const ADD_INVESTMENT = `${scene}ADD_INVESTMENT`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
export const COMPLETE = `${scene}COMPLETE`;
export const UPDATE_REDUCER = `${scene}UPDATE_REDUCER`;

