import * as actionTypes from './actionTypes';

const initialState = {
    allData: [],
    forwardRoles:[],
    forwardUsers:[],
    internalQueryRoles:[],
    internalQueryUsers:[],
    internalQueryChat:[],
    internalQueryId:null,
    draftQueries:[],
    draftQueriesSectionMap:{},
    externalQueriesSectionMap:{},
    forwardAction:false,
    financialAction:false,
    internalQueryAction:false,
    externalQueryAction:false,
    uploadDocumentAction:false,
    forwardStage:null,
    incentiveType:null,
    id: null,
    type: null,
    approve:null,
    reject:null,
    replyToUser:null,
    replyToRole:null,
    reply:false,
    resolve:false,
    mark:false,
    disbursePayment:false,
    paymentRequest:false,
    allLogs:[],
    mainActivities:[],
    sectorCategories:[],
    subSectors:[],
    sectorList:[],
    unitCategories:[],
    expandData:{},
    paymentRequestData:null,
    confirmationModal:false,
    confirmationUser:null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DETAILS:
            state = {
                ...state,
                allData: action.payload.data,
                forwardRoles:action.payload.actions.forwardRoles,
                internalQueryRoles:action.payload.actions.internalQueryRoles,
                forwardAction:action.payload.actions.forwardAction,
                financialAction:action.payload.actions.financialAction,
                internalQueryAction:action.payload.actions.internalQueryAction,
                externalQueryAction:action.payload.actions.externalQueryAction,
                uploadDocumentAction:action.payload.actions.uploadDocumentAction,
                forwardStage:action.payload.actions.forwardStage,
                replyToUser:action.payload.actions.replyToUser,
                replyToRole:action.payload.actions.replyToRole,
                paymentRequest:action.payload.actions.paymentRequest,
                reply:action.payload.actions.reply,
                disbursePayment:action.payload.actions.disbursePayment,
                approve:action.payload.actions.approve,
                mark: action.payload.actions.mark,
                resolve:action.payload.actions.resolve,
                reject:action.payload.actions.reject,
                internalQueryChat:action.payload.internalQueryData?.queryChat,
                internalQueryId:action.payload.internalQueryData?.queryId,
                draftQueries:action.payload.draftQueriesData,
                draftQueriesSectionMap:action.payload.draftQueriesSectionMap,
                externalQueriesSectionMap:action.payload.externalQueriesSectionMap,
                paymentRequestData:action.payload.paymentRequestsData,
                expandData:action.payload.expandData
            }
            break;
        case actionTypes.SET_DROPDOWN_VALUES:
            state={
                ...state,
                [action.payload.type]:action.payload.data
            }
            break;
        case actionTypes.SET_DRAFT_QUERIES:
            state={
                ...state,
                draftQueries:action.payload.data
            }
            break;
        case actionTypes.SET_USERS_OF_ROLE:
            console.log(action.payload)        
        state = {
                    ...state,
                    [action.payload.type]:action.payload.users
                }
                break;
        case actionTypes.UPDATE_ID_TYPE:
            state = {
                ...state,
                id: action.payload.id,
                type: action.payload.type,
                incentiveType: action.payload.incentiveType
            }
            break;
        case actionTypes.UPDATE_LOGS:
                state = {
                    ...state,
                    allLogs:action.payload
                }
                break;
        case actionTypes.OPEN_CONFIRMATION_MODAL:
            state = {
                ...state,
                confirmationModal:action.payload.open,
            }
            if(action.payload.user) {
                state = {
                    ...state,
                    confirmationUser:action.payload.user,
                }
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;