const initials = "/fc";

export const GET_CAF1_LIST = `${initials}/get-completed-caf1-applications`;
export const GET_APPLICATION_DETAILS = `${initials}/get-all-sections`;
export const GET_STAKE_HOLDERS = `${initials}/get-stakeholder-details`;
export const DELETE_STAKE_HOLDERS = `${initials}/delete-stakeholder-details`;
export const UPDATE_STAKE_HOLDERS = `${initials}/update-stakeholder-details`;
export const ADD_STAKE_HOLDERS = `${initials}/add-stakeholder-details`;
export const SAVE_APPLICATION_DETAILS = `${initials}/save-all-sections`;

export const SEND_OTP = `${initials}/send-otp`;
export const FINAL_SUBMIT = `${initials}/final-submit`;
export const VERIFY_OTP = `${initials}/verify-otp`;


export const GET_DOCUMENTS = `${initials}/get-document-details`;
export const GET_DOCUMENTS_TYPE = `${initials}/get-document-type/fc`;
export const ADD_DOCUMENTS = `${initials}/save-document-details`;
export const DELETE_DOCUMENTS = `${initials}/delete-document-details`;
export const EDIT_DOCUMENTS = `${initials}/edit-document-details`;
export const DOWNLOAD_FC_DOCUMENTS = `${initials}/download-fc-documents`;
export const UPDATE_DOCUMENTS_SUBMITTED = `${initials}/documents-submitted`;
export const GET_FC_SECTION_STATUS = `${initials}/get-section-details`;
export const GET_SECTOR_LAND_BANK = `${initials}/get-sector-land-category-and-banks`;

export const FC_PAYMENT = `${initials}/payment-status/`;

export const COMPLETE_PAYMENT_CCAVENUE = `${initials}/ccavenue/`;
export const COMPLETE_PAYMENT_OGRASS = `${initials}/ograss/`;

export const VIEW_PAYMENT_STATUS_FC = `${initials}/payment-info`;
export const VIEW_PAYMENT_STATUS_LICENSE = `license/payment-info`;

