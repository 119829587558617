import * as actionTypes from './actionTypes';
import { getAPI, postAPI, putAPI } from "../../../../../apiCalls/functions"
import {
    GET_ORGANIZATION_TYPE, GET_OFFICE_TYPE, GET_ENTERPRIZE_PROFILE, ADD_ENTERPRIZE_PROFILE, GET_STATE,
    GET_CITY, GET_DISTRICT, GET_BLOCK, EDIT_ENTERPRIZE_PROFILE
} from "../../../../../apiCalls/urls/investor/enterprizeProfile"
import { GET_QUERY_BY_SECTION, REPLY_TO_QUERY } from "../../../../../apiCalls/urls/investor/query"
import { apiLoading, apiLoadingEnd } from "../../../../../store/notification/action";
import { getDataFromInvestorStore } from "../../../../../store/getStore"
import { actionNotifier } from "../../../../../components/ui/toast/toast";
import { deleteDatFromObj } from "../../../../../shared/objFunctions"
import { deleteFromResponse } from "../../../../../shared/deleteJson"
import { updateEnterprizeId, redirectToNextPage, updateTabs } from "../../../dashboard/action"

const softwareSection = process.env.REACT_APP_INVESTOR_SECTION_NAME

//AllAPIS
export const fetchState = async (country) => {
    return await getAPI(GET_STATE + country, softwareSection)
}
export const fetchDistrict = async (state) => {
    return await getAPI(GET_DISTRICT + state, softwareSection)
}
export const fetchBlock = async (district) => {
    return await getAPI(GET_BLOCK + district, softwareSection)
}
export const fetchVillage = async (block) => {
    return await getAPI(GET_CITY + block, softwareSection)
}

export const fetchOrganizationType = async () => {
    let organization = await getAPI(GET_ORGANIZATION_TYPE, softwareSection)
    return organization
}
export const fetchOfficeType = async () => {
    let officeType = await getAPI(GET_OFFICE_TYPE, softwareSection)
    return officeType
}

export const fetchEnterprizeProfile = async (eid) => {
    let data = {
        "generalDetailId": eid
    }
    let profile = await postAPI(GET_ENTERPRIZE_PROFILE, data, softwareSection)
    return profile
}
export const addEnterprizeProfile = async (postData) => {
    let profile = await putAPI(ADD_ENTERPRIZE_PROFILE, postData, softwareSection)
    return profile
}
export const editEnterprizeProfile = async (postData) => {
    let profile = await putAPI(EDIT_ENTERPRIZE_PROFILE, postData, softwareSection)
    return profile
}

//query apis
export const fetchQueryBySection = async (cafId, section, serviceType = "CAF1") => {
    let data = {
        "applicationId": cafId,
        "serviceType": serviceType,
        "sectionId": section
    }
    return await postAPI(GET_QUERY_BY_SECTION, data, softwareSection)
}

export const replyQueryBySection = async (queryId, toUser, toUserRole, queryMessage) => {
    let data = {
        "queryId": queryId,
        "toUser": toUser,
        "toUserRole": toUserRole,
        "queryMessage": queryMessage
    }
    return await postAPI(REPLY_TO_QUERY, data, softwareSection)
}

export const onLoad = (eid) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        let organization = await fetchOrganizationType();
        let officeType = await fetchOfficeType();
        if (organization.status && officeType.status) {
            dispatch(updateOrganizationAndOffice(organization.data, officeType.data))
        }
        dispatch(onfetchQuery())
        if (eid) {
            let profile = await fetchEnterprizeProfile(eid)
            if (profile.status) {
                let enterPrizeProfile = {
                    "industryName": profile.data[0].nameOfIndustry,
                    "orgType": profile.data[0].typeOfIncorporation,
                    "cin": profile.data[0].cin,
                    "cinDate": profile.data[0].cinDate,
                    "tan": profile.data[0].tanNumber,
                    dateOfInc:profile.data[0].dateOfIncorporation,
                    pan: profile.data[0].pan,
                    "description": profile.data[0].description,
                    "aadhar": profile.data[0].aadhar,
                    "registrationNo": profile.data[0].registrationNo,
                    "registrationDate": profile.data[0].registrationDate
                }
                let _address = [];
                let headOffice = false;
                // console.log(profile.data[0].address)
                profile.data[0].address && profile.data[0].address.map((a) => {
                    let _single = deleteDatFromObj(a, [...deleteFromResponse, "generalAddressId", "generalDetailId"])
                    _address.push({
                        ..._single
                    })
                    if (_single.typeOfOffice === "Head office") {
                        headOffice = true
                    }
                })
                // console.log(headOffice)
                dispatch(updateProfile(enterPrizeProfile, _address, headOffice))
            }
        }
        dispatch(apiLoadingEnd())
        // dispatch(updateProfile(1))
    }
}

export const addProfile = (navigate) => {
    return async (dispatch) => {

        dispatch(apiLoading())
        let enterprizeReducer = getDataFromInvestorStore("EnterprizeReducer");
        let dashboardReducer = getDataFromInvestorStore("Dashboard")
        let data = {
            ...enterprizeReducer.enterPrizeProfile,
            address: enterprizeReducer.allAddress,
        }
        let profile;
        if (dashboardReducer.enterpriseId && dashboardReducer.enterpriseId != "null") {
            data = {
                ...data,
                tan: data.tan ? data.tan : "",
                aadhar:data.aadhar.toString(),
                generalDetailId: dashboardReducer.enterpriseId
            }
            profile = await editEnterprizeProfile(data)
        }
        else {
            profile = await addEnterprizeProfile(data)
        }
        // console.log(profile)
        if (profile.status) {
            actionNotifier.success(profile.message);
            if (profile.data && profile.data.generalDetailId) {
                //Update enterprise data
                dispatch(updateEnterprizeId(profile.data.generalDetailId, data.industryName))

                //Mark the tab filled
                let _enterpriseTabs = { ...dashboardReducer.enterpriseTabs };
                _enterpriseTabs["enterprise"] = "filled"
                dispatch(updateTabs("enterpriseTabs", _enterpriseTabs))

                //Redirect to next tab
                dispatch(redirectToNextPage(navigate, "edit", profile.data.generalDetailId, 2))
            }
            dispatch(updateComplete(true))
        }
        else {
            actionNotifier.error(profile.message);
        }
        dispatch(apiLoadingEnd())
    }
}

export const onfetchQuery = () => {
    return async (dispatch) => {
        let dashboardReducer = getDataFromInvestorStore("Dashboard")
        let result = await fetchQueryBySection(dashboardReducer.cafId, 1)
        // console.log(result)
        if (result.status) {
            dispatch(updateQuery(result.data[0]))
        }
    }
}

export const onReplyQuery = (message) => {
    return async (dispatch) => {
        let enterprizeReducer = getDataFromInvestorStore("EnterprizeReducer")
        let length = enterprizeReducer.allQuery.queryChat ? enterprizeReducer.allQuery.queryChat.length : 0;
        if (length > 0) {
            let result = await replyQueryBySection(enterprizeReducer.allQuery.queryId,
                enterprizeReducer.allQuery.queryChat[length - 1].fromUser,
                enterprizeReducer.allQuery.queryChat[length - 1].fromUserRole,
                message
            )
            // console.log(result)
            if (result.status) {
                dispatch(onfetchQuery())
            }
        }
    }
}

export const updateOrganizationAndOffice = (org, office) => {
    return {
        type: actionTypes.UPDATE_ORG_OFFICE,
        payload: { org, office }
    };
}


export const updateProfile = (profile, address, headOfficeUsed) => {
    return {
        type: actionTypes.UPDATE_PROFILE,
        payload: { profile, address, headOfficeUsed }
    };
}
export const updateOnlyProfile = (profile) => {
    return {
        type: actionTypes.UPDATE_ONLY_PROFILE,
        payload: profile
    };
}

export const updateAddress = (data) => {
    return {
        type: actionTypes.UPDATE_ADDRESS,
        payload: data
    };
}

export const updateQuery = (data) => {
    return {
        type: actionTypes.UPDATE_QUERY,
        payload: data
    };
}

export const updateHeadOffice = (data) => {
    return {
        type: actionTypes.UPDATE_HEAD_OFFICE,
        payload: data
    };
}

export const updateInitialEnt = () => {
    return {
        type: actionTypes.UPDATE_INITIAL,
        payload: {}
    };
}
export const updateComplete = (status) => {
    return {
        type: actionTypes.COMPLETE,
        payload: status
    };
}
