const scene = 'department/misReport'


export const ON_LOAD = `${scene}ON_LOAD`
export const UPDATE_PROCESSED = `${scene}UPDATE_PROCESSED`
export const UPDATE_SECTION = `${scene}UPDATE_SECTION`
export const UPDATE_TABLE_PARAMETER = `${scene}UPDATE_TABLE_PARAMETER`
export const UPDATE_FILTER = `${scene}UPDATE_FILTER`
export const UPDATE_FIRST_LOAD = `${scene}UPDATE_FIRST_LOAD`
export const INITIAL_STATE = `${scene}INITIAL_STATE`

