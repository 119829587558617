import * as actionTypes from './actionTypes';

const initialState = {
    allData: [],
    pagination: {
        page: 1,
        limit: 10,
        applicationNo: ''
    },
    totalSize:0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TABLE:
            state = {
                ...state,
                allData: action.payload.data,
                totalSize:action.payload.total
            }
            break;
        case actionTypes.UPDATE_SORT:
            state = {
                ...state,
                pagination: action.payload
            }
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
}

export default reducer;