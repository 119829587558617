import store from './index';

export const getDataFromInvestorStore = (section) => {
    return store.getState().InvestorReducer[section];
}

export const getDataFromDepartmentStore = (section) => {
    return store.getState().DepartmentReducer[section];
}

export const getDataFromStore = (section) => {
    return store.getState()[section];
}
