const scene = "investor/quarterReport/";

export const UPDATE_DATA = `${scene}UPDATE_DATA`;

export const UPDATE_INITIAL_DATA = `${scene}UPDATE_INITIAL_DATA`;
export const UPDATE_TABS = `${scene}UPDATE_TABS`;
export const UPDATE_WIZARD = `${scene}UPDATE_WIZARD`;
export const UPDATE_QUARTER_ID = `${scene}UPDATE_QUARTER_ID`;
export const UPDATE_ENT = `${scene}UPDATE_ENT`;




