import React, { useEffect } from "react";
import classes from "./topNav.module.scss";

const TopNav = () => {
    useEffect(() => {
        // in some cases, the google translate script adds a style to the opening html tag.
        // this added style disables scrolling.
        // the next 3 lines removes this added style in order to re-enable scrolling.
        if (window.document.scrollingElement.hasAttribute("style")) {
            window.document.scrollingElement.setAttribute("style", "");
        }
    });
    return (
        <div className={classes.container}>
            <div className={classes.navWrapper}>
                {/* <div className={classes.links}>Skip to Main Content</div>
        <div className={classes.vLine}>|</div>
        <div className={classes.links}>Screen Reader Access</div>
        <div className={classes.vLine}>|</div>
        <div className={classes.links}>
          English{" "}
          <span className={classes.downArrow}>
            <i className="fa-solid fa-caret-down"></i>
          </span>
        </div> */}
                <div  onClick={() =>{ window.open('https://www.nsws.gov.in/', '_blank');}} className={classes.nsws}>NSWS</div>
                <div id="google_translate_element"></div>
            </div>
        </div>
    );
};

export default TopNav;
