import React from "react";
import classes from "../commonCss/dropdownTippyStyles.module.scss";
import { useNavigate } from "react-router-dom";

const InvestorZoneDropdown = ({ setEodbHover }) => {
    const navigate = useNavigate();
    return (
        <div
            className={classes.listContainerGrid}
            onMouseEnter={() => setEodbHover(true)}
            onMouseLeave={() => setEodbHover(false)}
        >
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/calculator")}>
                Incentive Calculator
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/eodb")}>
                Dashboard
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/incentive-granted")}>
                Incentive granted dashboard
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/grievance-report")}>
                Grievance redressal dashboard
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/queries-handled")}>
                Queries handled dashboard
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/other-levies")}>
                Other Levies
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/feedback")}>
                Feedback
            </div>
        </div>
    );
};

export default InvestorZoneDropdown;
