import * as actionTypes from './actionTypes';
import { incentive5Tabs } from "../../../shared/investor/tabConstants"

const initialState = {
    selectedWizard: "1",
    incentiveTabs: {
        ...incentive5Tabs
    },
    incentiveType: "",
    incentiveId: null,
    allData: [],
    isExpansion: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DATA:
            state = {
                ...state,
                allData: action.payload.data,
                incentiveType: action.payload.type
            }
            break;
        case actionTypes.UPDATE_WIZARD:
            state = {
                ...state,
                selectedWizard: action.payload,
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                incentiveTabs: action.payload
            }
            break;
        case actionTypes.UPDATE_INCENTIVE_ID:
            state = {
                ...state,
                incentiveId: action.payload
            }
            break;
        case actionTypes.UPDATE_IS_EXPANSION:
            state = {
                ...state,
                isExpansion: action.payload
            }
            break;
    }
    return state;
}

export default reducer;