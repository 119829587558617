import { checkLastMonthDate, checkDateAvailableForSameMonth } from "./dateFunction"
export const deleteColumn = ["incentiveId", "createdBy", "createdDate", "updatedBy", "updatedDate", "status"]
export const deleteDatFromObj = (obj, data) => {
    // console.log(data, obj)
    if (!obj) {
        obj = {}
    }
    data && data.map((d) => {
        delete obj[d]
    })

    return obj
}

export const arrayToObject = (arr) => {
    let _arr = [];
    arr.forEach((a) => {
        _arr.push({
            value: a,
            label: a
        })
    })

    return _arr;
}

export const checkLocked = (tab, query) => {
    // console.log(tab , query)
    if (tab !== "locked" && tab !== "queried") {
        return true;
    }
    else if (tab == "queried") {
        // else if (tab == "queried" && query?.unlockSection === "Yes") {
        return true;
    }
    else {
        return false;
    }
}

export const extractLimitedDataFromJSON = (json, arr) => {
    let data = []
    json?.map((j) => {
        let single = {}
        arr.map((a) => {
            single[a] = j[a]
        })
        data.push(single)
    })
    return data;
}

export const checkNull = (data) => {
    return data ? data : null
}

//check date is from last month
export const compareColumn = (data, id, idName, dateName, date) => {
    return true;
    if (id) {
        data = data.filter((d) => d[idName] != id)
    }
    let filter = data.filter((d) => checkDateAvailableForSameMonth(d[dateName], date))
    if (filter.length > 0) {
        return false;
    }
    else {
        return checkLastMonthDate(date);
    }
}

export const getDocWithSection = (data) => {
    return new Promise((resolve, reject) => {
        let _allData = []
        try {
            data.forEach((a) => {
                let index = _allData.findIndex((s) => s.name == a.serviceType);
                if (index >= 0) {
                    _allData[index].children.push({
                        ...a,
                    })
                }
                else {
                    _allData.push({
                        name: a.serviceType,
                        children: [{ ...a }]
                    })
                }
            })
            resolve(_allData)
        } catch(err) {
            reject(err)
        }
    })
}