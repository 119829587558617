import React from "react";
import classes from "./middleBottomFooter.module.scss";

const bottomMiddleFooter = () => {
  return (
    <div className={classes.mainContainer} style={{ marginTop: -6 }}>
      <div className={classes.container}>
        <div className={classes.text}>
          Content owned by Department of Industries, Goverment of Bihar
        </div>
        <div className={`${classes.text} ${classes.text2}`}>This site is best viewed in 1024 * 768 resolution with latest version of Chrome, Firefox, Safari and Internet Explorer, Windows 8 and above.</div>
      </div>
    </div>
  );
};

export default bottomMiddleFooter;
