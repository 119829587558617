import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'

const MyLoader = ({ active, children }) => {
    // console.log(active)
    return (
        <LoadingOverlay
            active={active}
            spinner={<BounceLoader />}
            text=""
            styles={{position: 'absolute',
                width: '100%',
                height: '100%'
            }}
        >
            {children}
        </LoadingOverlay>
    )
}

export default MyLoader;