import React from "react";
import classes from "./nav.module.scss";
import { useNavigate } from "react-router";

import TopNav from "./topNav/topNav";
import Marquee from "./marquee";
import Typewriter from "./typewriter";

import MiddleNav from "./middleNav/middleNav";
import BottomNav from "./bottomNav/bottomNav";


const Nav = (props) => {
  const navigate = useNavigate()
  
  return (
    <div className={classes.container}>
      <div className={classes.navWrapper}>
        <TopNav />
        <MiddleNav />
        {/* <Marquee 
          title1="In case of any difficulty in using this portal, please call 18003456214" /> */}
        <Marquee 
          title1="In case of any difficulty in using this portal, please call 18003456214."
          onClick2={() => navigate("/investor/notice-board")}
          title2="Please click here to see latest notice."
          onClick3={() => navigate("/investor/policies-acts")}
          title3="Please click here to see latest leather policy."
        />
        {/* <Typewriter /> */}
        <BottomNav
          section={props.section}
        />
      </div>
    </div>
  );
};

export default Nav;
