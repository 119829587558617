import React from "react";
import { useNavigate } from "react-router-dom";

import classes from "../commonCss/dropdownTippyStyles.module.scss";

const ContactUsDropdown = ({ setContactUsNavbarHover }) => {
    const navigate = useNavigate();
    return (
        <div
            className={classes.listContainer}
            onMouseEnter={() => setContactUsNavbarHover(true)}
            onMouseLeave={() => setContactUsNavbarHover(false)}
        >
            <div
                className={classes.items}
                onClick={() => navigate("/investor/contact-us/key-contacts")}
            >
                Key Contacts
            </div>
            <div
                onClick={() => navigate("/investor/contact-us/head-office")}
                className={classes.items}
            >Head Office</div>
            <div
                onClick={() => navigate("/investor/contact-us/dic")}
                className={classes.items}
            >DICs</div>
            <div
                onClick={() => navigate("/investor/contact-us/authority")}
                className={classes.items}
            >Authority/Board/Corp</div>
            <div
                onClick={() => navigate("/investor/contact-us/others")}
                className={classes.items}
            >Others</div>
        </div>
    );
};

export default ContactUsDropdown;
