import * as actionTypes from './actionTypes';

const initialState = {
    allDistrict:[],
    allPolicy:[],
    allGuidelines:[],
    allScheme:[],
    allImportantDownload:[],
    feedbackModal: false,
    verifyModal: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DATA:
            state = {
                ...state,
                [action.payload.section]: action.payload.data,
            }
            break;
        default:
            state = { ...state };
            break;

            case actionTypes.UPDATE_MODAL_FEEDBACK:
                state = {
                    ...state,
                    feedbackModal: action.payload,
                }
                break;
    
                case actionTypes.UPDATE_MODAL_VERIFY:
                    state = {
                        ...state,
                        verifyModal: action.payload,
                    }
                    break;
           
        }
        return state;
    }
    

export default reducer;