import React from "react";
import { useNavigate } from "react-router-dom";

import classes from "../dropdownTippy/dropdownTippy.module.scss";

const SubMenu = (props) => {
  // console.log(props)
  const navigate = useNavigate();
  return (
    <div className={classes.listContainer}>
      {
        props.subMenu && props.subMenu.map((s, i) => (
          <div
            onClick={() => navigate(s.path)}
            className={classes.items}>{s.name}</div>
        ))
      }
    </div>
  );
};

export default SubMenu;
