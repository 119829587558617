const scene = "investor/settings";


export const UPDATE_GRIEVANCE = `${scene}UPDATE_GRIEVANCE`;
export const OTP_ACTION = `${scene}OTP_ACTION`;







