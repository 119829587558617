import * as actionTypes from './actionTypes';

const tableAssigned = {
    "search": "",
    "sort": {
        "attributes": ["applicationNo"],
        "sorts": ["desc"]
    },
    "pageNo": 1,
    "itemsPerPage": parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)
};

const tableProcessed = {
    "search": "",
    "sort": {
        "attributes": ["applicationNo"],
        "sorts": ["desc"]
    },
    "pageNo": 1,
    "itemsPerPage": parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)
};

const tableNew = {
    "search": "",
    "sort": {
        "attributes": ["applicationNo"],
        "sorts": ["desc"]
    },
    "pageNo": 1,
    "itemsPerPage": parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)
}

const initialState = {
    selectedSection: "",
    tableAssigned: {
        ...tableAssigned
    },
    tableProcessed: {
        ...tableProcessed
    },
    tableNew: {
        ...tableNew
    },
    allFilterAssigned: [],
    allFilterProcessed: [],
    allFilterNew: [],
    allDataAssigned: [],
    totalAssigned: 0,
    allDataProcessed: [],
    totalProcessed: 0,
    allDataNew: [],
    totalNew: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TABLE_PARAMETER:
            state = {
                ...state,
                [action.payload.table]: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_SECTION:
            state = {
                ...state,
                selectedSection: action.payload,
            }
            break;
        case actionTypes.UPDATE_ALL_DATA:
            state = {
                ...state,
                allDataAssigned: action.payload.assigned,
                allDataProcessed: action.payload.allDataProcessed,
                allDataNew: action.payload.new,
                totalNew: action.payload.totalNew,
                totalAssigned: action.payload.totalAssigned,
                totalProcessed: action.payload.totalProcessed
            }
            break;
        case actionTypes.UPDATE_PROCESSED:
            state = {
                ...state,
                allDataProcessed: action.payload.allDataProcessed,
                totalProcessed: action.payload.totalProcessed,
                // allFilterProcessed:action.payload.allFilterProcessed,
            }
            break;
        case actionTypes.UPDATE_NEW:
            state = {
                ...state,
                allDataNew: action.payload.allDataNew,
                totalNew: action.payload.totalNew,
                // allFilterNew:action.payload.allFilterNew,
            }
            break;
        case actionTypes.UPDATE_ASSIGNED:
            state = {
                ...state,
                allDataAssigned: action.payload.allDataAssigned,
                totalAssigned: action.payload.totalAssigned,
                // allFilterAssigned:action.payload.allFilterAssigned,
            }
            break;
        case actionTypes.UPDATE_FILTER:
            state = {
                ...state,
                allFilterAssigned:action.payload,
            }
            break;
        case actionTypes.INITIAL_STATE:
            state = {
                ...state,
                tableAssigned: {
                    ...tableAssigned
                },
                tableProcessed: {
                    ...tableProcessed
                },
                allDataAssigned: [],
                totalAssigned: 0,
                allDataProcessed: [],
                totalProcessed: 0,
            }
            break
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;