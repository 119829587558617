import * as actionTypes from './actionTypes';

const initialState = {
    selectedSection:"pending",
    selectedCaf1:[],
    selectedFc:[],
    tableCaf: {
        "search": "",
        "sort": {
            "attributes": ["id"],
            "sorts": ["desc"]
        },
        "pageNo": 1,
        "itemsPerPage": parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)
    },
    tableFc: {
        "search": "",
        "sort": {
            "attributes": ["id"],
            "sorts": ["desc"]
        },
        "pageNo": 1,
        "itemsPerPage": parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)
    },
    sipbAssignedCaf1Applications:[],
    sipbAssignedFCApplications:[],
    sipbInProgressCaf1Applications:[],
    sipbInProgressFCApplications:[],
    sipbCompletedApplications:[],
    inProgressSipbMeetingDetails:null,
    allSipbMeetings:[],
    assignedCaf1Count:0,
    inProgressCaf1Count:0,
    assignedFcCount:0,
    inProgressFcCount:0,
    completedCount:0,
    selectedSipbMeeting:null,
    latestSipbMeetingId:null,
    latestSipbMeetingDate:null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SELECTED_MEETING:
            state = {
                ...state,
                selectedSipbMeeting: action.payload.selectedSipbMeeting,
            }
            break;
        case actionTypes.UPDATE_TABLE_PARAMETER:
            state = {
                ...state,
                [action.payload.table]: action.payload.data,
            }
            break;
        case actionTypes.ON_LOAD_SUCCESS:
            state = {
                ...state,
                sipbAssignedCaf1Applications:action.payload.sipbAssignedCaf1Applications,
                sipbAssignedFCApplications:action.payload.sipbAssignedFCApplications,
                sipbInProgressCaf1Applications:action.payload.sipbInProgressCaf1Applications,
                sipbInProgressFCApplications:action.payload.sipbInProgressFCApplications,
                inProgressSipbMeetingDetails:action.payload.inProgressSipbMeetingDetails,
                allSipbMeetings:action.payload.allSipbMeetings,
                assignedCount:action.payload.assignedCount,
                inProgressCount:action.payload.inProgressCount,
                
            }
            break;
        case actionTypes.UPDATE_SELECTED_CAF1:
                state = {
                    ...state,
                    selectedCaf1: action.payload
                }
                break;
        case actionTypes.UPDATE_LATEST_MEETING:
                    state = {
                        ...state,
                        latestSipbMeetingId:action.payload.sipbMeetingId, 
                        latestSipbMeetingDate:action.payload.sipbMeetingDate
                    }
                    break;
        case actionTypes.UPDATE_SELECTED_FC:
                    state = {
                        ...state,
                        selectedFc: action.payload
                    }
            break;
        case actionTypes.UPDATE_ASSIGNED_FC_DATA:
                state = {
                    ...state,
                    sipbAssignedFCApplications: action.payload.allAssignedFc,
                    assignedFcCount:action.payload.totalAssigned
                }
                break;
        case actionTypes.UPDATE_ASSIGNED_CAF_DATA:
                state = {
                    ...state,
                    sipbAssignedCaf1Applications: action.payload.allAssignedCaf,
                    assignedCaf1Count:action.payload.totalProcessed
                }
                break;
        case actionTypes.UPDATE_INPROGRESS_CAF_DATA:
                    state = {
                        ...state,
                        sipbInProgressCaf1Applications: action.payload.allAssignedCaf,
                        inProgressCaf1Count:action.payload.totalProcessed
                    }
            break;
        case actionTypes.UPDATE_INPROGRESS_FC_DATA:
                    state = {
                        ...state,
                        sipbInProgressFCApplications: action.payload.allAssignedFC,
                        inProgressFcCount:action.payload.totalProcessed
                    }
            break;
        case actionTypes.ON_GET_COMPLETED_APPLICATIONS:
                state = {
                    ...state,
                    sipbCompletedApplications:action.payload.sipbCompletedApplications,
                    completedCount:action.payload.totalProcessed,
                }
            break;
        case actionTypes.UPDATE_SECTION:
                state = {
                    ...state,
                    selectedSection: action.payload,
                }
                break;
        case actionTypes.ON_GET_MEETINGS:
            state = {
                ...state,
                allSipbMeetings: action.payload.allSipbMeetings,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;