import * as actionTypes from './actionTypes';

const initialState = {
    allData: {},
    allDocumentType: [],
    allDocumentsCAFExisting: [],
    allDocumentsCAF: [],
    allDocumentsEnt: [],
    allQuery: [],
    allQueryLength: 0,
    complete: false,
    isExpand:false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ONLOAD:
            state = {
                ...state,
            }
            break;
        case actionTypes.UPDATE_DOCUMENT_TYPE:
            state = {
                ...state,
                allDocumentType: action.payload
            }
            break;
        case actionTypes.UPDATE_DOCUMENT:
            state = {
                ...state,
                allDocumentsCAF: action.payload.caf,
                allDocumentsEnt: action.payload.ent,
                allDocumentsCAFExisting: action.payload.existing,
                isExpand: action.payload.isExpand
            }
            break;
        case actionTypes.UPDATE_DOCUMENT_BY_SECTION:
            state = {
                ...state,
                [action.payload.section]: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.COMPLETE:
            state = {
                ...state,
                complete: action.payload
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;