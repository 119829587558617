import * as actionTypes from './actionTypes';

const initialState = {
    allUnit: [],
    allData: {
        main: [],
        by: [],
        raw: [],
        processing: []
    },
    allQuery: [],
    allQueryLength: 0,
    complete: false,
    isExpand: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PRODUCTS_UNIT:
            state = {
                ...state,
                allData: action.payload.products,
                allUnit: action.payload.unit,
                isExpand: action.payload.expand
            }
            break;
        case actionTypes.UPDATE_PRODUCTS:
            state = {
                ...state,
                allData: action.payload
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.COMPLETE:
            state = {
                ...state,
                complete: action.payload
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;