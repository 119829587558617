const scene = "investor/fc";

export const UPDATE_ALL_CAF1 = `${scene}UPDATE_ALL_CAF1`;
export const UPDATE_CAF1_ID = `${scene}UPDATE_CAF1_ID`;
export const UPDATE_WIZARD_SECTION = `${scene}UPDATE_WIZARD_SECTION`;
export const UPDATE_FC_DATA = `${scene}UPDATE_FC_DATA`;
export const UPDATE_FC_ALL_DATA = `${scene}UPDATE_FC_ALL_DATA`;
export const UPDATE_SOCIAL_TYPE_DESIGNATION = `${scene}UPDATE_SOCIAL_TYPE_DESIGNATION`;
export const UPDATE_DROPDOWN = `${scene}UPDATE_DROPDOWN`;

export const UPDATE_OTP_SENT = `${scene}UPDATE_OTP_SENT`;
export const UPDATE_FINAL_SUBMIT = `${scene}UPDATE_FINAL_SUBMIT`;
export const UPDATE_TABS = `${scene}UPDATE_TABS`;
export const APPLICATION_COMPLETE = `${scene}APPLICATION_COMPLETE`;
export const UPDATE_SECTOR_LAND_BANK = `${scene}UPDATE_SECTOR_LAND_BANK`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
