import * as actionTypes from './actionTypes';

const initialState = {
    allData: {

        unitStatus: null,
        unitName: null,
        mainActivity: null,
        unitSector: null,
        subSector: null,
        isAncillary: null,
        unitDescription: null,

        locatedIn: null,
        ownershipType: null,
        industrialEstate: null,
        houseNumber: null,
        plotNumber: null,
        holdingNumber: null,
        street: null,
        country: null,
        state: null,
        district: null,
        block: null,
        // taluka: data?.taluka,
        gramPanchayat: null,
        city: null,
        wardNo: null,
        pincode: null,
        policeStation: null,
        nearestRailwayStation: null,
        postOffice: null,
        email: null,
        registeredPhone: null,
        alternatePhone: null,

        requiredLandArea: null,
        isLandAlloted: null,
    },

    allQuery: [],

    allLocated: [],
    allMainActivity: [],
    allSectors: [],
    allSubSectors: [],
    allOwnershipType: [],
    allDistrict: [],
    allBlock: [],
    allVillage: [],

    allQueryLength: 0,

    completed: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ONLOAD:
            state = {
                ...state,
                allLocated: action.payload.located,
                allMainActivity: action.payload.main,
                allDistrict: action.payload.district,
                allBlock: action.payload.district,
                allVillage: action.payload.district
            }
            break;
        case actionTypes.UPDATE_SECTOR:
            state = {
                ...state,
                allSectors: action.payload,
            }
            break;
        case actionTypes.UPDATE_SUB_SECTOR:
            state = {
                ...state,
                allSubSectors: action.payload,
            }
            break;
        case actionTypes.UPDATE_BLOCK:
            state = {
                ...state,
                allBlock: action.payload,
            }
            break;
        case actionTypes.UPDATE_VILLAGE:
            state = {
                ...state,
                allVillage: action.payload,
            }
            break;
        case actionTypes.UPDATE_OWNERSHIP_TYPE:
            state = {
                ...state,
                allOwnershipType: action.payload,
            }
            break;

        case actionTypes.UPDATE_UNIT_DATA:
            state = {
                ...state,
                allData: action.payload
            }
            break;
        case actionTypes.UPDATE_COMPLETED:
            state = {
                ...state,
                completed: action.payload
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;