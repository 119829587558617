

const initials = "/caf-one";

export const GET_ALL_DATA = `${initials}/get-all-details`;
export const SEND_OTP = `${initials}/send-otp`;
export const VERIFY_OTP = `${initials}/verify-otp`;
export const FINAL_SUBMIT = `${initials}/final-submit`;
export const DOWNLOAD_CAF1_DOCUMENT = `${initials}/download-caf1-documents`;
export const DOWNLOAD_ENTERPRISE_DOCUMENT = `${initials}/download-enterprise-documents`;
export const GET_ALL_TERMS = `/service-terms-and-condition`;
export const UPDATE_ALL_TERMS = `/service-terms-and-condition`;

export const COMPLETE_PAYMENT_CCAVENUE = `${initials}/ograss/`;
export const COMPLETE_PAYMENT_OGRASS = `${initials}/ograss-old/`;

export const VIEW_PAYMENT_STATUS = `${initials}/payment-info`;
