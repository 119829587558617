import React from "react";
import { useNavigate } from "react-router";

import classes from "../commonCss/dropdownTippyStyles.module.scss";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "tippy.js/animations/shift-away.css";
import NoticeBoardDropdown from "./noticeBoardDropdown/noticeBoardDropdown";

const ServicesAndSchemesDropdown = ({
    noticeBoardNavbarHover,
    setNoticeBoardNavbarHover,
    setServicesAndSchemesNavbarHover,
}) => {
    const navigate = useNavigate();
    return (
        <div
            className={classes.listContainer}
            onMouseEnter={() => setServicesAndSchemesNavbarHover(true)}
            onMouseLeave={() => setServicesAndSchemesNavbarHover(false)}
        >
            <div
                onClick={() => navigate("/investor/services-and-schemes/single-window")}
                className={classes.items}>Single Window</div>
            <div
                onClick={() => navigate("/investor/services-and-schemes/schemes")}
                className={classes.items}>Schemes</div>
            {/* <Tippy
                placement="right-start"
                arrow={false}
                delay={[0, 500]}
                animation="shift-away"
                interactive={true}
                content={<NoticeBoardDropdown setNoticeBoardNavbarHover={setNoticeBoardNavbarHover} />}
                className={classes.tippyGridAlt}
            >
                <div
                    className={noticeBoardNavbarHover ? classes.itemActive : classes.items}
                    onClick={() => navigate("/investor/notice-board")}
                >
                    Notice Board
                </div>
            </Tippy> */}
        </div>
    );
};

export default ServicesAndSchemesDropdown;
