import {
  SET_ENTERPRISE_LOADING,
  SET_ENTERPRISE_SUCCESS,
  SET_ENTERPRISE_ERROR,
  SET_ENTERPRISE_DATA,
  SET_ORGINATION_TYPE_DATA,
  SET_OFFICE_TYPE_DATA,
  SET_CAF_DATA,
  SET_ENTERPRISE_BY_ID_DATA,
  SET_NEW_ENTERPRISE_DATA,
} from "./actionTypes";

const initialState = {
  enterpriseData: {},
  orginationTypeData: {},
  officeTypeData: {},
  addEnterpriseData: {},
  individualEnterpriseProfile: {},
  loading: false,
  error: false,
  success: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENTERPRISE_DATA:
      return {
        ...state,
        enterpriseData: action.enterpriseData,
      };
    case SET_ORGINATION_TYPE_DATA:
      return {
        ...state,
        orginationTypeData: action.orginationTypeData,
      };

    case SET_OFFICE_TYPE_DATA:
      return {
        ...state,
        officeTypeData: action.officeTypeData,
      };

    case SET_NEW_ENTERPRISE_DATA:
      return {
        ...state,
        addEnterpriseData: action.newEnterpriseData,
      };

    case SET_ENTERPRISE_BY_ID_DATA:
      return {
        ...state,
        individualEnterpriseProfile: action.individualEnterpriseProfile,
      };

    case SET_ENTERPRISE_LOADING:
      return {
        ...state,
        loading: action.enterpriseLoadingValue,
      };

    case SET_ENTERPRISE_SUCCESS:
      return {
        ...state,
        success: action.enterpriseSuccessValue,
      };

    case SET_ENTERPRISE_ERROR:
      return {
        ...state,
        error: action.enterpriseErrorValue,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
