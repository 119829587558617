import React from "react";
import { useNavigate } from "react-router-dom"
import classes from "./dropdownTippy.module.scss";

const DropdownTippy = (props) => {

    const navigate = useNavigate();

    return (
        <div className={classes.listContainer}>
            {
                props.menu.map((d, i) => (
                    <div onClick={() => navigate(d.path)} className={classes.items}>{d.name}</div>
                ))
            }
        </div>
    );
};

export default DropdownTippy;
