import * as actionTypes from './actionTypes';
import { biadaLand } from "../tabs"
const initialState = {
    licenseId: "",
    biadatabs: {
        ...biadaLand
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_LICENCE_ID:
            state = {
                ...state,
                licenseId: action.payload,
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                biadatabs: action.payload
            }
            break;
    }
    return state;
}

export default reducer;