// Loading
export const SET_LOCATION_DETAILS_LOADING = "setLocationDetailsLoading";
// Error
export const SET_LOCATION_DETAILS_ERROR = "setLocationDetailsError";
// Success
export const SET_LOCATION_DETAILS_SUCCESS = "setLocationDetailsSuccess";
// Main Activity List
export const SET_MAIN_ACTIVITY_LIST_DATA = "setMainActivityListData";
// Located In
export const SET_LOCATED_IN_DATA = "setLocatedInData";
export const SET_OWNERSHIP_DATA = "setOwnershipType";
// Sector List
export const SET_SECTOR_LIST_DATA = "setSectorListData";
// Sub Sector List
export const SET_SUB_SECTOR_LIST_DATA = "setSubSectorListData";
// Country List
export const SET_COUNTRY_LIST_DATA = "setCountryListData";
// State List
export const SET_STATE_LIST_DATA = "setStateListData";
// District List
export const SET_DISTRICT_LIST_DATA = "setDistrictListData";
// Block List
export const SET_BLOCK_LIST_DATA = "setBlockListData";
// Taluka List
export const SET_TALUKA_LIST_DATA = "setTalukaListData";
// City List
export const SET_CITY_LIST_DATA = "setCityListData";
