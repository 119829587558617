import * as actionTypes from './actionTypes';

let initialProfile = {
    "industryName": null,
    "orgType": null,
    "cin": "",
    "cinDate": null,
    "tan": "",
    "description": null,
    "aadhar": null,
    pan: null,
    dateOfInc: null,
    registrationNo: null,
    registrationDate: null,
}

const initialState = {
    enterPrizeProfile: {
        ...initialProfile
    },
    allAddress: [],
    allOrganizationType: [],
    cafId: null,
    allOfficeType: [],
    allQuery: [],
    allQueryLength: 0,
    headOfficeUsed: false,
    complete: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ONLOAD:
            state = {
                ...state,
                allData: action.allData,
                total: action.total
            }
            break;
        case actionTypes.UPDATE_INITIAL:
            state = {
                ...state,
                enterPrizeProfile: { ...initialProfile },
                allAddress: [],
                headOfficeUsed:false, 
                complete:false,
            }
            break;
        case actionTypes.UPDATE_PROFILE:
            state = {
                ...state,
                enterPrizeProfile: action.payload.profile,
                allAddress: action.payload.address,
                headOfficeUsed: action.payload.headOfficeUsed
            }
            break;
        case actionTypes.UPDATE_ONLY_PROFILE:
            state = {
                ...state,
                enterPrizeProfile: action.payload,
            }
            break;
        case actionTypes.UPDATE_ADDRESS:
            state = {
                ...state,
                allAddress: action.payload
            }
            break;
        case actionTypes.UPDATE_ORG_OFFICE:
            state = {
                ...state,
                allOrganizationType: action.payload.org,
                allOfficeType: action.payload.office
            }
            break;
        case actionTypes.UPDATE_CAF_ID:
            state = {
                ...state,
                cafId: action.payload,
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload?.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.UPDATE_HEAD_OFFICE:
            state = {
                ...state,
                headOfficeUsed: action.payload
            }
            break;
        case actionTypes.COMPLETE:
            state = {
                ...state,
                complete: action.payload
            }
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
}

export default reducer;