import React from "react";
import Tippy from "@tippyjs/react";
import { useNavigate } from "react-router-dom";

import SubMenu from "./subMenu"

import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "tippy.js/animations/shift-away.css";
import classes from "./styles.module.scss";

const SubMenuSection = (props) => {
    const navigate = useNavigate();
    // console.log(props)
    return (
        <>
            {props.subMenu && props.subMenu.children
                ?
                <Tippy
                    placement="bottom-start"
                    arrow={false}
                    delay={[0, 500]}
                    animation="shift-away"
                    interactive={true}
                    content={<SubMenu
                        subMenu={props.subMenu.children}
                    />}
                    className={classes.tippyAlt}
                >
                    <div className={classes.links}>{props.subMenu.name}</div>
                </Tippy>
                : <div
                    onClick={() => navigate(props.subMenu.path)}
                    className={classes.links}>{props.subMenu.name}</div>
            }
        </>
    )
}

export default SubMenuSection