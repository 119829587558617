export const quickLinks = [
    {
        "name": "Labour Resources Department",
        "url": "https://state.bihar.gov.in/labour/"
    },
    {
        "name": "Bihar State Pollution Control Board (BSPCB)",
        "url": ""
    },
    {
        "name": "Urban Development & Housing Department",
        "url": "https://state.bihar.gov.in/urban/"
    },
    {
        "name": "Health Department",
        "url": "https://state.bihar.gov.in/health/"
    },
    {
        "name": "Water Resource Department",
        "url": "https://state.bihar.gov.in/wrd/"
    },
    {
        "name": "Commercial Taxes Department",
        "url": "https://state.bihar.gov.in/biharcommercialtax/"
    },
    {
        "name": "Environment,Forest and Climate Change Department",
        "url": "https://state.bihar.gov.in/forest/"
    },
    {
        "name": "Bihar State Power Holding Corpoation Limited",
        "url": "https://state.bihar.gov.in/energy/"
    },
    {
        "name": "Bihar Home Guard & Fire Service Directorate",
        "url": "https://state.bihar.gov.in/home/"
    },
    {
        "name": "Bihar Industrial Area Development Authoritv (BIADA)",
        "url": "https://www.biadabihar.in/"
    },
    {
        "name": "Department of Revenue & Land Reform",
        "url": "https://state.bihar.gov.in/lrc/"
    },
    {
        "name": "Prohibition Excise & Registration Department (Excise)",
        "url": "https://state.bihar.gov.in/excise/"
    },
    {
        "name": "Prohibition Excise & Registration Department (Registration)",
        "url": "https://state.bihar.gov.in/registration/"
    },
    {
        "name": "Road Construction Department",
        "url": "https://state.bihar.gov.in/rcd/"
    },
    {
        "name": "Weights & Measures Division, \nAgriculture Department, \nGovt. of Bihar",
        "url": "https://maaptaul.bih.nic.in/lmd/index.jsp"
    },
    {
        "name": "Energy Department",
        "url": "https://state.bihar.gov.in/energy/"
    },
    {
        "name": "Department of Industries",
        "url": "https://state.bihar.gov.in/industries/"
    },
    {
        "name": "Upendra Maharathi Shilp Anusandhan Sansthan",
        "url": "https://umsas.org.in/"
    },
    {
        "name": "Udyog Mitra",
        "url": "http://www.udyogmitrabihar.in/"
    },
    {
        "name": "Food and Consumer Protection Department",
        "url": "https://state.bihar.gov.in/fcp/"
    }
]