import * as actionTypes from './actionTypes';

const initialState = {
    selectedSection: "all",
    Generic: [],
    Application: [],
    grievanceArea: [],
    incentive: [],
    service: [],
    policy: [],
    genericSort: {
        page: 1,
        limit: 10,
        trackingNo: ''
    },
    applicationSort: {
        page: 1,
        limit: 10,
        trackingNo: ''
    },

    singleData: {},
    grvId: "",
    grvTrackingId: "",
    allQueryLength: 0,
    allQuery: {},

    genericAdded: false,
    applicationAdded: false,
    allUnit: [],
    grievanceTab: "",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_GRIEVANCE:
            state = {
                ...state,
                [action.payload.section]: action.payload.data
            }
            break;
        case actionTypes.UPDATE_TABLE:
            state = {
                ...state,
                [action.payload.section]: action.payload.data
            }
            break;
        case actionTypes.UPDATE_DROPDOWN:
            state = {
                ...state,
                incentive: action.payload.incentive,
                service: action.payload.service,
                grievanceArea: action.payload.grievanceArea,
                policy: action.payload.policy,
                allUnit: action.payload.unit
            }
            break;
        case actionTypes.UPDATE_SELECTED_SECTION:
            state = {
                ...state,
                selectedSection: action.payload
            }
            break;
        case actionTypes.TOGGLE_ADD_FLAG:
            state = {
                ...state,
                [action.payload.flag]: action.payload.data
            }
            break;
        case actionTypes.UPDATE_SINGLE_DATA:
            state = {
                ...state,
                singleData: action.payload.data
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                grievanceTab: action.payload
            }
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
}

export default reducer;