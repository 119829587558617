import { departmentAxios } from "../../shared/axios";
import { getDataFromDepartmentStore } from "../../store/getStore"
const errorMessage = "Something went wrong. Please try again";


export const getToken = (section) => {
    // console.log(section, "amrit")

    if (section === "DEPARTMENT") {
        let auth = getDataFromDepartmentStore("Login");
        // console.log(auth.authDetails.token)
        return auth.authDetails.token
    }

    else return false

};

export const apiFunction = async (url, method, postData, section, token, extraConfig) => {
    // console.log("sadsadas")
    let config = {
        method: method,
        url: url,
        data: postData ? postData : {},
    };
    let allData;
    if (token) {
        let token = getToken(section);
        // console.log(token)
        config = {
            ...config,
            headers: { token: `${token}` },
        };
    }

    if (extraConfig == "blob") {
        config = {
            ...config,
            responseType: 'blob',
        }
    }


    await departmentAxios({ ...config })
        .then((res) => {
            
            if (extraConfig == "blob") {
                allData = res.data
            }
            else {
                // console.log(res.data.data);
                if (res.data) {
                    // console.log(res.data.data);
                    if(extraConfig === "returnData"){
                        // console.log(res.data.data);
                        allData = {
                            data:res.data.data,
                            status: true,
                            message: "Success",
                        };
                        // console.log(allData)
                    }
                    else{
                        allData = {
                            data: res.data.data,
                            status: true,
                            message: "Success",
                        };
                    }
                    // console.log(res.data.data);
                } else {
                    allData = {
                        status: false,
                        message: "This action is not allowed",
                    };
                }
                // console.log(res.data.data);
            }
        })
        .catch((err) => {
            // console.log(err.response.data.message);
            // console.log(err);
            allData = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(allData);
    if (extraConfig == "blob") 
        return allData;
    return {...allData};
};

export const postFormDataAPI = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    const config = {
        headers: {
            "content-type": "multipart/form-data",
            token: `${token}`,
        },
    };
    console.log(config,postData);
    await departmentAxios
        .post(url, postData, config)
        .then((res) => {
            data = res.data;
        })
        
        .catch((err) => {
            // console.log(err.response.data.message);
            // console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    return data;
};

export const postAPIBlob = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    // console.log(token)
    const config = {
        headers: { token: `${token}` },
        responseType: "blob",
    };
    await departmentAxios
        .post(url, postData, config)
        .then((res) => {
            // console.log(res);
            data = res.data;
        })
        .catch((err) => {
            // console.log(err.response);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};