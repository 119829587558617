import * as actionTypes from './actionTypes';

const table = {
    "search": "",
    "sort": {
        "attributes": ["id"],
        "sorts": ["desc"]
    },
    "filters":[ 
        
    ] ,
    "pageNo": 1,
    "itemsPerPage": 10000
};


const initialState = {
    table: {
        ...table
    },
    allData: [],
    total: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TABLE_PARAMETER:
            state = {
                ...state,
                [action.payload.table]: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_ALL_DATA:
            state = {
                ...state,
                allData: action.payload.allData,
                total: action.payload.total
            }
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
}

export default reducer;