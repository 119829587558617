const scene = "investor/incentive";

export const UPDATE_ALL = `${scene}UPDATE_ALL`;
export const UPDATE_GENERAL_DATA = `${scene}UPDATE_GENERAL_DATA`;
export const UPDATE_STAMP_DATA = `${scene}UPDATE_STAMP_DATA`;
export const UPDATE_WIZARD = `${scene}UPDATE_WIZARD`;
export const UPDATE_TABS = `${scene}UPDATE_TABS`;

export const UPDATE_LAND_CONVERSION_DATA = `${scene}UPDATE_LAND_CONVERSION_DATA`;
export const UPDATE_ELECTRICITY_DATA = `${scene}UPDATE_ELECTRICITY_DATA`;
export const UPDATE_TERM_LOAN_DATA = `${scene}UPDATE_TERM_LOAN_DATA`;
export const UPDATE_INT_AVAILED_DATA = `${scene}UPDATE_INT_AVAILED_DATA`;
export const UPDATE_INT_SUB_CLAIM_DATA = `${scene}UPDATE_INT_SUB_CLAIM_DATA`;
export const UPDATE_INT_CHECK = `${scene}UPDATE_INT_CHECK`;

export const UPDATE_TAX_DETAILS_A = `${scene}/UPDATE_TAX_DETAILS_A`;
export const UPDATE_TAX_DETAILS_B = `${scene}/UPDATE_TAX_DETAILS_B`;
export const UPDATE_STAMP_DATA_HP = `${scene}UPDATE_STAMP_DATA_HP`;
export const UPDATE_LAND_CONVERSION_DATA_HP =`${scene}UPDATE_LAND_CONVERSION_DATA_HP`;
export const UPDATE_FINAL_SUBMIT = `${scene}UPDATE_FINAL_SUBMIT`;
export const UPDATE_EMPLOY_DETAILS = `${scene}UPDATE_EMPLOY_DETAILS`;  
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
export const UPDATE_EMPLOYMENT_DATA = `${scene}UPDATE_EMPLOYMENT_DATA`;
export const UPDATE_CAPITAL_SUBSIDY = `${scene}UPDATE_CAPITAL_SUBSIDY`;
export const UPDATE_EMPLOYMENT_SUBSIDY = `${scene}UPDATE_EMPLOYMENT_SUBSIDY`;
export const UPDATE_SKILL_SUBSIDY = `${scene}UPDATE_SKILL_SUBSIDY`;
export const UPDATE_POWER_TARRIF = `${scene}UPDATE_POWER_TARRIF`;
export const UPDATE_PATENT_REGISTRATION = `${scene}UPDATE_PATENT_REGISTRATION`;
export const UPDATE_FREIGHT_REIMBURSEMENT = `${scene}UPDATE_FREIGHT_REIMBURSEMENT`;


export const UPDATE_DATA = `${scene}UPDATE_DATA`;
export const UPDATE_FINANCE_DATA = `${scene}UPDATE_FINANCE_DATA`;
export const UPDATE_INITIAL_DATA = `${scene}UPDATE_INITIAL_DATA`;

