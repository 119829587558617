import * as actionTypes from './actionTypes';
import { apiFunction } from "../../../apiCalls/functions/department"
import { LOGIN } from "../../../apiCalls/urls/department/login"
import { apiLoading, apiLoadingEnd } from "../../../store/notification/action";
import { actionNotifier } from "../../../components/ui/toast/toast";
import { departmentMenu } from '../../../containers/homepage/nav/bottomNav/departmentMenu';

const softwareSection = process.env.REACT_APP_DEPARTMENT_SECTION_NAME

//AllAPIS
export const fetchLoginDetails = async (data) => {
    return await apiFunction(LOGIN, 'post', data, softwareSection, false)
}

export function onLogin(username, password, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let data = {
            "userName": username,
            "password": password
        }
        let result = await fetchLoginDetails(data);
        // console.log(result)
        if (result.status) {
            localStorage.setItem("swc-department-login-data", JSON.stringify(result.data))
            let menuItem = generateMenu(result.data.permissions)
            // console.log(menuItem)
            dispatch(updateLoginDetails(result.data, menuItem, result.data.permissions))
            if (username === "dir.ind" || username === "dd.ind" || username === "ps.ind") {
                navigate("/department/admin_dashboard")
            }
            else {
                navigate(menuItem[0].path ? menuItem[0].path : menuItem[0].children[0].path)
            }
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}

const getFirstPath = (menuItem) => {
    return menuItem[0].path ? menuItem[0].path : menuItem[0].children[0].path
}


const generateMenu = (permissions) => {

    // console.log(permissions);

    let completeMenu = [];

    departmentMenu.forEach((d) => {
        if (d.compulsory) {
            completeMenu.push({ ...d })
        }
        else {
            let singleMenu = {
                ...d,
                children: []
            }
            let items = permissions.filter(f => {
                let string = f[1].split(":");
                if (string[0] == "Menu" && f[1].toLowerCase().includes(d.name.toLowerCase())) {
                    return f;
                }
            })
            // console.log(items, d.children)
            if (items.length > 0 && d.children.length > 0) {
                d.children.map((c) => {
                    let subMenu = items.filter(i => i[1].includes(c.name))
                    if (subMenu.length > 0) {
                        singleMenu.children = [
                            ...singleMenu.children,
                            {
                                ...c
                            }
                        ]
                    }
                })

            }
            if (singleMenu.children.length > 0) {
                completeMenu.push({ ...singleMenu })
                // console.log(singleMenu)
            }

        }

    })

    if (permissions[0][0] == "ROAD") {
        completeMenu = [
            ...completeMenu,
            {
                "name": "Report",
                "path": "",
                "component": "",
                "compulsory": false,
                "children": [
                    {
                        "name": "Check Report",
                        "path": "/department/report/:Road Construction Department"
                    }
                ]
            }
        ]
        // console.log(completeMenu)
    }
    console.log(completeMenu)
    return completeMenu

}


export const logoutDepartment = (navigate) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        localStorage.removeItem("swc-department-login-data");
        dispatch(updateLoginDetails([], [], []))
        // navigate('/department-login');
        window.location.href = "/department-login"
        dispatch(apiLoadingEnd())
    }
}


// ACTIONS
export const updateLoginDetails = (data, menu, permissions) => {
    return {
        type: actionTypes.UPDATE_LOGIN_DETAILS,
        payload: { data, menu, permissions }
    };
}

export const importDepartmentDataFromLocal = () => {
    return (dispatch) => {
        let data = localStorage.getItem("swc-department-login-data")
        // console.log(data)
        if (data) {
            data = JSON.parse(data)
            let menuItem = generateMenu(data.permissions)
            dispatch(updateLoginDetails(data, menuItem, data.permissions))
        }
    }
}

