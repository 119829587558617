import React from "react";
import classes from "../commonCss/dropdownTippyStyles.module.scss";
import { useNavigate } from "react-router-dom";
import swcManual from "../../../../../assets/pdf/SWC User Manual .pdf";
import { openInNewTab } from "../../../../../shared/saveAs";

const InvestorZoneDropdown = ({ setInvestorZoneNavbarHover }) => {
    const navigate = useNavigate();
    return (
        <div
            className={classes.listContainerGrid}
            onMouseEnter={() => setInvestorZoneNavbarHover(true)}
            onMouseLeave={() => setInvestorZoneNavbarHover(false)}
        >
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/sectors")}>
                Sectors
            </div>
            {/* <div className={classes.itemsAlt}>Udyog Mitra</div> */}
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/licence-permit-wizard")}>License Permit Wizard</div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/faq")}>
                FAQ
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/district")}>
                District
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/notice-board")}>
                Notice Board
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/important-downloads")}>
                Important Downloads
            </div>
            <div className={classes.itemsAlt} onClick={() => openInNewTab(swcManual)}>
                SWC User Manual
            </div>
            <div className={classes.itemsAlt} onClick={() => navigate("/investor/proceeding")}>
                Proceeding
            </div>
            {/* <div className={classes.itemsAlt} onClick={() => navigate("/investor/calculator")}>
                Incentive Calculator 
            </div> */}
        </div>
    );
};

export default InvestorZoneDropdown;
