const scene = "investor/incentive/employmentSubsidy";

export const UPDATE_DISTRICT = `${scene}UPDATE_DISTRICT`;
export const UPDATE_MODAL = `${scene}UPDATE_MODAL`;
export const UPDATE_DATA = `${scene}UPDATE_DATA`;
export const UPDATE_ALLESI_TABLE = `${scene}UPDATE_ALLESI_TABLE`;
export const UPDATE_EMPLOYMENT_DATA = `${scene}UPDATE_EMPLOYMENT_DATA`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;


