
const scene = "investor/caf1/financeAndTimeLine";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ONLOAD = `${scene}UPDATE_ONLOAD`;
export const UPDATE_FINANCE = `${scene}UPDATE_FINANCE`;
export const UPDATE_FINANCE_UNIT = `${scene}UPDATE_FINANCE_UNIT`
export const ADD_FINANCE = `${scene}ADD_FINANCE`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
export const COMPLETE = `${scene}COMPLETE`;
export const UPDATE_FINANCE_ON_LOAD =  `${scene}UPDATE_FINANCE_ON_LOAD`;
export const UPDATE_INITIAL = `${scene}UPDATE_INITIAL`;
export const UPDATE_REDUCER = `${scene}UPDATE_REDUCER`;
