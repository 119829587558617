import classes from "./styles.module.scss";
import Modal from 'react-modal';
// import Modal from 'react-responsive-modal';
const ModalLayout = (props) => {
    // let windowWidth = window.innerWidth;
    const content = {
        padding: 0,
        minHeight: '40vh',
        top: '10%',
        left: '20%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '60vw',
        maxHeight: '80vh'
        // transform: 'translate(-50%, -40%)'
    }
    // {windowWidth < 600 ? 
    //     content['width'] = '80vw'
    // :
    //     content['width'] = '60vw'
    // }
    const overlay = {
        position: 'fixed',
        zIndex: 1020,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: '#393939bf',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const customStyles = {
        content: { ...content, ...props.content },
        overlay: { ...overlay },
    };

    const customStylesSmall = {
        content: {
            ...content,
            width: '40vw',
            left: '30%',
            top: '20%'
        },
        overlay: { ...overlay },
    };

    const customStylesLarge = {
        content: {
            ...content,
            width: '95vw',
            left: '2.5%',
            top: '5%'
        },
        overlay: { ...overlay },
    };

    const getStyle = () => {
        if (props.size == "small") {
            return { ...customStylesSmall, ...props.styles }
        }
        else if (props.size == "large") {
            return { ...customStylesLarge, ...props.styles }
        }
        else {
            return { ...customStyles, ...props.styles }
        }
    }

    const getHeader = () => {
        if (props.size == "small") {
            return classes.modalHeader + " " + classes.modalSmall
        }
        else if (props.size == "large") {
            return classes.modalHeader + " " + classes.modalLarge
        }
        else {
            return classes.modalHeader
        }

    }

    console.log(props.styles);

    return (
        <Modal
            isOpen={props.isOpen}
            onAfterOpen={props.onAfterOpen}
            onRequestClose={props.toggleModal}
            style={getStyle()}
            contentLabel={props.title}
        >
            {
                props.hideBody ?
                    <>
                        {props.children}
                    </>
                    :
                    <>
                        <div className={getHeader()}>
                            <div className={classes.head} style={{...props.styleTitle}}>
                                {props.title}
                            </div>
                            <div className={classes.cross}>
                                <i className="fa fa-times" aria-hidden="true"
                                    onClick={props.toggleModal}
                                ></i>
                            </div>
                        </div>
                        <div className={classes.modalBody}>
                            {props.children}
                        </div>
                    </>
            }
        </Modal>
    )
}

export default ModalLayout;