
const scene = "investor/caf1/productAndRawMaterial";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ONLOAD = `${scene}UPDATE_ONLOAD`;
export const UPDATE_PRODUCTS = `${scene}UPDATE_PRODUCTS`;
export const UPDATE_PRODUCTS_UNIT = `${scene}UPDATE_PRODUCTS_UNIT`;
export const ADD_UPDATE_PROFILE = `${scene}ADD_UPDATE_PROFILE`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
export const COMPLETE = `${scene}COMPLETE`;
