import * as actionTypes from './actionTypes';
import { getAPI, postAPI, postAPIData } from "../../../apiCalls/functions"
import { GET_CAF_ID, GET_CAF_SECTION_STATUS, GET_LOGS, GET_COMPLETED_INCENTIVE, GET_COMPLETED_FC, GET_COMPLETED_DOP } from "../../../apiCalls/urls/investor/enterprizeProfile"
import { VIEW_PAYMENT_STATUS_FC, VIEW_PAYMENT_STATUS_LICENSE } from "../../../apiCalls/urls/investor/fc"
import { GET_ENTERPRISE } from "../../../apiCalls/urls/investor/enterprise/enterpriseURL"
import { VIEW_PAYMENT_STATUS } from "../../../apiCalls/urls/investor/reviewSubmit";
import { EXPAND_UNIT } from "../../../apiCalls/urls/investor/unit/CRUD/unitCrudURL"
import { checkTabStatus } from "../../../shared/investor/functions"
import { caf1Tabs } from "../../../shared/investor/tabConstants"
import { apiLoading, apiLoadingEnd } from "../../../store/notification/action";
import { getUnitList } from "../../../store/unit/CRUD/actions"
import { getDataFromInvestorStore } from "../../../store/getStore"
import { actionNotifier } from '../../../components/ui/toast/toast';


const softwareSection = process.env.REACT_APP_INVESTOR_SECTION_NAME

export const fetchCafId = async (unitId) => {
    let data = {
        "unitId": unitId
    }
    let cafId = await postAPI(GET_CAF_ID, data, softwareSection)
    return cafId
}

export const fetchSectionStatus = async (cafId) => {
    return await postAPI(GET_CAF_SECTION_STATUS, { "cafId": cafId }, softwareSection)
}

export const expandUnit = async (data) => {
    return await postAPI(EXPAND_UNIT, data, softwareSection)
}

export const getEnterpriseList = async () => {
    return await getAPI(GET_ENTERPRISE, {}, softwareSection);
};

export const getLogs = async (data) => {
    return await postAPI(GET_LOGS, data, softwareSection);
};

export const getCompletedIncentive = async (data) => {
    return await postAPI(GET_COMPLETED_INCENTIVE, data, softwareSection);
};
export const getCompletedFC = async (data) => {
    return await postAPI(GET_COMPLETED_FC, data, softwareSection);
};
export const getCompletedDOP = async (data) => {
    return await postAPI(GET_COMPLETED_DOP, data, softwareSection);
};
export const completePayment = async (cafId) => {
    // return await postAPIData(COMPLETE_PAYMENT, { caf_id: cafId }, softwareSection);
};
export const paymentStatus = async (cafId) => {
    return await postAPI(VIEW_PAYMENT_STATUS, { caf_id: cafId }, softwareSection);
};


export const paymentStatusFC = async (fcId) => {
    return await postAPI(VIEW_PAYMENT_STATUS_FC, { fc_id: fcId }, softwareSection);
};

export const paymentStatusLicense = async (serviceType, licenseId) => {
    let data = {
        serviceType,
        licenseId
    }

    return await postAPI(VIEW_PAYMENT_STATUS_LICENSE, data, softwareSection);
};


export const redirectToNextPage = async (navigate, operation, eid, section, unitId, wizardSection, extraSection, method) => {
    let path = "/investor/investor-home/dashboard/" + operation + "/" + eid;
    if (section) {
        path = path + "/" + section;
    }
    if (unitId && wizardSection) {
        path = path + "/" + unitId + "/" + wizardSection;
    }
    if (extraSection) {
        path = path + "/" + extraSection;
    }
    if (method == "window") {
        window.open(path, "_self")
    }
    else {
        navigate(path)
    }

    window.scrollTo(0, 0)
}

export const redirectToNextPage11 = async (navigate, id, section, wizardSection) => {
    let path = "/investor/investor-home/incentive/"
    if (!id) {
        path = path + "id/" + section + "/" + wizardSection;
    }
    else {
        path = path + id + "/" + section + "/" + wizardSection;
    }
    navigate(path)
    window.scrollTo(0, 0)
}

export const onGetLogs = (unitId) => {
    return async (dispatch) => {
        // console.log(unitId)
        dispatch(updateLogs([]))
        dispatch(apiLoading())
        let res = await getLogs({ unitId: unitId });
        if (res.status) {
            dispatch(updateLogs(res.data))
        }
        dispatch(apiLoadingEnd())
    }
}

export const onLoadInitial = () => {
    return async (dispatch) => {
        // dispatch(apiLoading())
        let result = await getEnterpriseList();
        if (result.status) {
            dispatch(updateEnterprise(result.data.enterpriseProfile ? result.data.enterpriseProfile : []))
        }
        // dispatch(apiLoadingEnd())
    }
}

export const onLoad = (unitId) => {
    return async (dispatch) => {
        let cafId = await fetchCafId(unitId);
        if (cafId.status) {
            dispatch(updateCafId(cafId.data.cafId, cafId.data.caf ? cafId.data.caf : null))
            let result = await fetchSectionStatus(cafId.data.cafId)
            // console.log(result);
            if (result.status) {
                let tabsStatus = checkTabStatus(result.data.submittedSections, result.data.lockedSections, result.data.queriedSections, caf1Tabs)
                console.log(tabsStatus)
                dispatch(updateTabs("caf1Tabs", tabsStatus))
            }
        }
    }
}

export const onUnitExpand = (data, callback) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        let dashboard = getDataFromInvestorStore("Dashboard")
        // console.log(data, dashboard)
        let result = await expandUnit(data);
        if (result.status) {
            dispatch(getUnitList(dashboard.enterpriseId))
            if (callback) {
                callback()
            }
        }
        dispatch(apiLoadingEnd())
    }
}

export const onGetCompletedIncentive = (unit) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await getCompletedIncentive({ unitId: unit });
        if (result.status) {
            dispatch(updateCompletedIncentive(result.data))
        }
        dispatch(apiLoadingEnd())
    }
}

export const onGetCompletedFC = (unit) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await getCompletedFC({ unitId: unit });
        if (result.status) {
            dispatch(updateCompletedFC(result.data))
        }
        dispatch(apiLoadingEnd())
    }
}

export const onGetCompletedDOP = (unit) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await getCompletedDOP({ unitId: unit, "serviceType": "DOP", });
        if (result.status) {
            dispatch(updateCompletedDOP(result.data))
        }
        dispatch(apiLoadingEnd())
    }
}

export const onCompletePayment = (cafId) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await completePayment(cafId);
        console.log(result)
        if (result.status) {
            dispatch(updatePaymentHtml(result.data))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}


export const onFetchPaymentStatus = (cafId) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await paymentStatus(cafId);
        console.log(result)
        if (result.status) {
            dispatch(updatePaymentInfo(result.data[0] ? result.data[0] : null))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}

export const onFetchPaymentStatusFc = (fcId) => {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await paymentStatusFC(fcId);
        console.log(result)
        if (result.status) {
            dispatch(updatePaymentInfo(result.data[0] ? result.data[0] : null))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}

export function updateOperation(operation) {
    return {
        type: actionTypes.UPDATE_DASHBOARD_OPERATION,
        payload: operation
    };
}

export function updateEnterprizeId(eId, name) {
    // console.log(eId, name)
    name = name && name != "null" ? name : ""
    localStorage.setItem("swc-enterprise-name", name)
    return {
        type: actionTypes.UPDATE_ENTERPRIZE_ID,
        payload: { eId, name }
    };
}

export function updateEnterprizeName(name) {
    // console.log("asdasdsa")
    name = name && name != "null" ? name : ""
    localStorage.setItem("swc-enterprise-name", name)
    return {
        type: actionTypes.UPDATE_ENTERPRIZE_NAME,
        payload: name
    };
}

export function updateUnitId(unitId) {
    return {
        type: actionTypes.UPDATE_UNIT_ID,
        payload: unitId
    };
}

export const updateCafId = (id, cafData) => {
    return {
        type: actionTypes.UPDATE_CAF_ID,
        payload: { id, cafData }
    };
}

export function updateSelectedCaf(index) {
    return {
        type: actionTypes.UPDATE_SELECTED_CAF,
        payload: index
    };
}

export function updateSelectedSection(index) {
    return {
        type: actionTypes.UPDATE_SELECTED_SECTION,
        payload: index
    };
}

export function updateTabs(tabs, data) {
    return {
        type: actionTypes.UPDATE_TABS,
        payload: { tabs, data }
    };
}

export function updateUnit(section, data) {
    // console.log(section, data)
    return {
        type: actionTypes.UPDATE_UNIT,
        payload: { section, data }
    };
}

export function updateEnterprise(data) {
    return {
        type: actionTypes.UPDATE_ALL_ENTERPRISE,
        payload: data
    };
}

export function updateLogs(data) {
    return {
        type: actionTypes.UPDATE_LOGS,
        payload: data
    };
}

export function updateCompletedIncentive(data) {
    return {
        type: actionTypes.COMPLETED_INCENTIVE,
        payload: data
    };
}

export function updateCompletedFC(data) {
    return {
        type: actionTypes.COMPLETED_FC,
        payload: data
    };
}

export function updateCompletedDOP(data) {
    return {
        type: actionTypes.COMPLETED_DOP,
        payload: data
    };
}

export function updatePaymentInfo(data) {
    return {
        type: actionTypes.UPDATE_PAYMENT_INFO,
        payload: data
    };
}

export function updatePaymentHtml(data) {
    return {
        type: actionTypes.UPDATE_PAYMENT_HTML,
        payload: data
    };
}
