import * as actionTypes from './actionTypes';
import { landRegistration } from "../tabs"
const initialState = {
    licenseId: "",
    basicData: {},
    landRegistrationTabs: {
        ...landRegistration
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_LICENCE_ID:
            state = {
                ...state,
                licenseId: action.payload,
            }
            break;
        case actionTypes.UPDATE_BASIC_DATA:
            state = {
                ...state,
                basicData: action.payload,
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                landRegistrationTabs: action.payload
            }
            break;
    }
    return state;
}

export default reducer;