const scene = "investor/caf1/dashboard";


export const UPDATE_UNIT_ID = `${scene}UPDATE_UNIT_ID`;
export const UPDATE_ENTERPRIZE_ID = `${scene}UPDATE_ENTERPRIZE_ID`;
export const UPDATE_ENTERPRIZE_NAME = `${scene}UPDATE_ENTERPRIZE_NAME`;
export const UPDATE_CAF_ID = `${scene}UPDATE_CAF_ID`;
export const UPDATE_CAF1_INDEX = `${scene}UPDATE_CAF1_INDEX`;
export const UPDATE_SELECTED_CAF = `${scene}UPDATE_SELECTED_CAF`;
export const UPDATE_SELECTED_SECTION = `${scene}UPDATE_SELECTED_SECTION`;
export const UPDATE_DASHBOARD_OPERATION = `${scene}UPDATE_DASHBOARD_OPERATION`;
export const UPDATE_TABS = `${scene}UPDATE_TABS`;
export const UPDATE_UNIT = `${scene}UPDATE_UNIT`;
export const UPDATE_ALL_ENTERPRISE = `${scene}UPDATE_ALL_ENTERPRISE`;
export const UPDATE_LOGS = `${scene}UPDATE_LOGS`;
export const COMPLETED_INCENTIVE = `${scene}COMPLETED_INCENTIVE`;
export const COMPLETED_FC = `${scene}COMPLETED_FC`;
export const COMPLETED_DOP = `${scene}COMPLETED_DOP`;
export const UPDATE_PAYMENT_INFO = `${scene}UPDATE_PAYMENT_INFO`;
export const UPDATE_PAYMENT_HTML = `${scene}UPDATE_PAYMENT_HTML`;






