import classes from "./styles.module.scss";
const Button = (props) => {
    return (
        <>
            {
                props.type === "submit"
                    ?
                    <button
                        className={classes.secondaryBtn + " " + (classes[props.className] ? classes[props.className] : "")}
                        style={props.style}
                        type="submit"
                    >
                        {props.title}
                    </button>
                    :
                    <button
                        onClick={props.onClick}
                        className={classes.secondaryBtn + " " + (classes[props.className] ? classes[props.className] : "")}
                        style={props.style}
                        type={props.type ? props.type : "button"}
                    >
                        {props.title}
                    </button>
            }
        </>

    )
}

export default Button