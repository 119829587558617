export const caf1Tabs = {
    enterprise: "new",
    stakeholders: "new",
    unit: "new",
    product: "new",
    investment: "new",
    employment: "new",
    finance: "new",
    upload: "new",
    review: "new"
};
export const enterpriseTabs = {
    enterprise: "new",
    stakeholders: "new",
    upload: "new"
};

export const unitTabs = {
    general: "new",
    location: "new",
    land: "new"
};

export const fcTabs = {
    application: "new",
    document: "new",
    review: "new"
};

export const incentive4Tabs = {
    general: "new",
    incentive: "new",
    document: "new",
    review: "new"
};

export const incentive5Tabs = {
    general: "new",
    investment:"new",
    incentive: "new",
    document: "new",
    review: "new"
};

export const caf2Tabs = {
    wizard: "new",
    services:"new",
};

export const caf3Tabs = {
    wizard: "new",
    services:"new",
};

export const quarterReport = {
    application: "new",
    document: "new",
    review: "new"
};