import * as actionTypes from './actionTypes';

const initialState = {
    Amodal: false,
    Bmodal: false,
    allQuery: [],
    allQueryLength: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_MODAL_A:
            state = {
                ...state,
                Amodal: action.payload.data,
            }
            break;

            case actionTypes.UPDATE_MODAL_B:
            state = {
                ...state,
                Bmodal: action.payload.data,
            }
            break;
            case actionTypes.UPDATE_QUERY:
                state = {
                    ...state,
                    allQuery: action.payload,
                    allQueryLength: action.payload?.queryChat ? action.payload.queryChat?.length : 0
                }
                break;
    }
    return state;
}

export default reducer;