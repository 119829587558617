import { saveAs } from 'file-saver'

export const downloadImage = (url, file) => {
    saveAs(url, file)
}

export const openInNewTab = (url) => {
    window.open(url, "_blank");
}

export const openInSameTab = (url) => {
    window.location.href = url;
}

