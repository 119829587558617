import  React, { useRef } from 'react';
import IdleTimer  from 'react-idle-timer';
import { useNavigate } from 'react-router-dom'


const IdleTimerComponent = (props) =>{

    const navigate = useNavigate();
    const idleTimerRef = useRef(null);

    const onIdle = () =>{
        // console.log(window.location.pathname)
        localStorage.removeItem("swc-doi-investor-data");
        localStorage.removeItem("swc-doi-investor-login-token");
        localStorage.removeItem("swc-department-login-data");
        navigate("/investor/homepage");
        window.location.reload();
    }

    return(
        <>
            <IdleTimer 
                ref={idleTimerRef}
                timeout={90*60*1000}
                // timeout={5*1000}
                onIdle={onIdle}
            />
        </>
    )
}

export default IdleTimerComponent;