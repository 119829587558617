
const scene = "investor/caf1/stakeHolders";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ONLOAD = `${scene}UPDATE_ONLOAD`;
export const UPDATE_STACK_HOLDERS = `${scene}UPDATE_STACK_HOLDERS`;
export const UPDATE_SOCIAL_TYPE_DESIGNATION = `${scene}UPDATE_SOCIAL_TYPE_DESIGNATION`;
export const UPDATE_ADDRESS = `${scene}UPDATE_ADDRESS`;
export const ADD_UPDATE_PROFILE = `${scene}ADD_UPDATE_PROFILE`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
export const UPDATE_INITIAL = `${scene}UPDATE_INITIAL`;
export const COMPLETE = `${scene}COMPLETE`;
