import {
    SET_REGISTER_USER_DATA,
    SET_SEND_OTP_DATA,
    SET_VERIFY_OTP_DATA,
    SET_USER_LOGIN_DATA,
    SET_GENDER_LIST_DATA,
    SET_AUTH_LOADING,
    SET_AUTH_SUCCESS,
    SET_AUTH_ERROR,
    OTP_ACTION,
    UPDATE_GENDER,
    SENT_FP_OTP,
} from "./actionTypes";
import { actionNotifier } from "../../components/ui/toast/toast";

import { getAPI, putAPIWithoutToken, postAPIWithoutToken, putAPI, getAPIWithoutToken } from "../../apiCalls/functions";
import {
    GET_GENDER,
    LOGIN_USER,
    REGISTER_USER,
    SEND_OTP,
    VERIFY_OTP,
    FORGOT_PASSWORD_OTP,
    UPDATE_PASSWORD_URL,
    PARICHAY_IS_TOKEN_VALID,
    PARICHAY_LOGOUT,
} from "../../apiCalls/urls/investor/auth/authURL";
import * as APIs from "../../apiCalls/urls/investor/auth/authURL";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action";
import { getDataFromStore } from "../../store/getStore";
import { fetchGender } from "../../containers/investorDashboard/dashboard/caf1Tabs/stakeholders/actions";

//APIS
export const sendOTPForPassword = (email) => {
    return postAPIWithoutToken(FORGOT_PASSWORD_OTP, { email: email });
};
export const updatePassword = (data) => {
    return putAPIWithoutToken(UPDATE_PASSWORD_URL, { ...data });
};

export const isParichayTokenValid = (clientToken, sid, sessionId, browserId) => {
    return getAPIWithoutToken(
        PARICHAY_IS_TOKEN_VALID + `?clientToken=${clientToken}&sid=${sid}&sessionId=${sessionId}&browserId=${browserId}`
    );
};

export const parichayLogout = (clientToken, sid, sessionId, browserId) => {
    return getAPIWithoutToken(
        PARICHAY_LOGOUT + `?clientToken=${clientToken}&sid=${sid}&sessionId=${sessionId}&browserId=${browserId}`
    );
};

export const getBanner = (banner) => {
    return postAPIWithoutToken(APIs.GET_BANNER, { type: banner });
};

export const getContactUs = () => {
    return getAPIWithoutToken(APIs.GET_CONTACT_US);
};


export const registerUser = (data, setLoginPage) => async (dispatch) => {
    const { Email, Password, FirstName, LastName, Gender, Dob, Mobile, type, policyScheme } = data;
    const payload = {
        email: Email,
        password: Password,
        firstName: FirstName,
        lastName: LastName,
        gender: Gender?.value,
        dob: Dob,
        policyScheme:policyScheme.value,
        phone: Mobile,
        type: type,
    };
    let registerUserData = await authAPIHandler(postAPIWithoutToken, REGISTER_USER, payload, dispatch);
    console.log(registerUserData);
    if (registerUserData.status) {
        actionNotifier.success("Account created successfully.");
        setLoginPage(true)
        dispatch(setRegisterUserData(registerUserData.data));
    } else {
        actionNotifier.error(registerUserData.message);
    }
};

export const onLoad = () => {
    return async (dispatch) => {
        let res = await fetchGender();
        console.log(res);
        if (res.status) {
            dispatch(updateGender(res.data));
        }
    };
};

export const sendOTPByType = (data, section) => async (dispatch) => {
    // dispatch(apiLoading())
    let result = await authAPIHandler(postAPIWithoutToken, SEND_OTP, data, dispatch);
    // console.log(result, "azsdasdasdasdsa")
    if (result.status) {
        actionNotifier.success("OTP sent successfully.");
        dispatch(setOTPAction(section == "email" ? "emailOTPAction" : "phoneOTPAction", "send"));
    } else {
        actionNotifier.error(result.message);
    }
    dispatch(apiLoadingEnd());
};

export const verifyOTPByType = (data, section) => async (dispatch) => {
    dispatch(apiLoading());
    let result = await putAPIWithoutToken(VERIFY_OTP, data);
    // console.log(result)
    if (result.status) {
        actionNotifier.success("OTP verified successfully.");
        dispatch(setOTPAction(section == "email" ? "emailOTPAction" : "phoneOTPAction", "verified"));
    } else {
        actionNotifier.error(result.message);
    }
    dispatch(apiLoadingEnd());
};

export const sendOtp = (sendOtpData) => async (dispatch) => {
    let sendOtpResponse = await authAPIHandler(postAPIWithoutToken, SEND_OTP, sendOtpData, dispatch);
    dispatch(setSendOtpData(sendOtpResponse));
};

export const verifyOtp = (verifyOtpData) => async (dispatch) => {
    let verifyOtpResponse = await authAPIHandler(putAPIWithoutToken, VERIFY_OTP, verifyOtpData, dispatch);
    dispatch(setVerifyOtpData(verifyOtpResponse));
};

export const loginUser = (loginUserData) => async (dispatch) => {
    let loginResponse = await authAPIHandler(postAPIWithoutToken, LOGIN_USER, loginUserData, dispatch);
    console.log(loginResponse);
    if (loginResponse && loginResponse.status) {
        dispatch(setUserLoginData(loginResponse?.data));
    } else {
        actionNotifier.error(loginResponse.message);
    }
};

export const getGenderList = () => async (dispatch) => {
    let genderList = await authAPIHandler(getAPI, GET_GENDER, null, dispatch);
    dispatch(setGenderListData(genderList));
};

export const updateLogin = () => async (dispatch) => {
    let data = localStorage.getItem("swc-doi-investor-data");
    if (data) {
        data = JSON.parse(data);
        let parichayCheck = localStorage.getItem("swc-doi-investor-jan-parichay");
        if (parichayCheck && parichayCheck == "true") {
            let parichayData = JSON.parse(localStorage.getItem("swc-doi-investor-jan-parichay-data"));
            let validity = await isParichayTokenValid(
                parichayData.clientToken,
                parichayData.serviceId,
                parichayData.sessionId,
                parichayData.browserId
            );
            console.log(validity);
            if (validity.status == "success" && validity.data.status == "success" && validity.data.tokenValid == "true") {
                dispatch({ type: SET_USER_LOGIN_DATA, loginUserData: data });
            } else {
                localStorage.removeItem("swc-doi-investor-data");
                localStorage.removeItem("swc-doi-investor-jan-parichay");
                localStorage.removeItem("swc-doi-investor-jan-parichay-data");
                localStorage.removeItem("swc-doi-investor-login-token");
                dispatch(setUserLoginData({}));
                // navigate('/investor/register');
                window.location.href = "/investor/homepage";
            }
        }
        dispatch({ type: SET_USER_LOGIN_DATA, loginUserData: data });
    }
};

export const logoutInvestor = (navigate) => {
    return async (dispatch) => {
        dispatch(apiLoading());
        localStorage.removeItem("swc-doi-investor-data");
        localStorage.removeItem("swc-doi-investor-login-token");
        dispatch(setUserLoginData({}));
        let parichayCheck = localStorage.getItem("swc-doi-investor-jan-parichay");
        let parichayData = JSON.parse(localStorage.getItem("swc-doi-investor-jan-parichay-data"));
        if (parichayCheck && parichayCheck == "true") {
            let logout = await parichayLogout(
                parichayData.clientToken,
                parichayData.serviceId,
                parichayData.sessionId,
                parichayData.browserId
            );
            console.log(logout);
            getAPIWithoutToken(logout.data);
            localStorage.removeItem("swc-doi-investor-jan-parichay");
            localStorage.removeItem("swc-doi-investor-jan-parichay-data");
        }
        navigate("/investor/register");
        window.location.href = "/investor/register";
        dispatch(apiLoadingEnd());
    };
};

export const updateAuthOnProfileChange = (data) => {
    return async (dispatch) => {
        let auth = getDataFromStore("Auth");
        let user = { ...auth.userLoginData };
        user.data[0].firstName = data.firstName;
        user.data[0].lastName = data.lastName;
        localStorage.setItem("swc-doi-investor-data", JSON.stringify(user));
        // console.log(auth, user)
        dispatch(setUserLoginData(user));
    };
};

export const updatePhoneChange = (phone) => {
    return async (dispatch) => {
        let auth = getDataFromStore("Auth");
        let user = { ...auth.userLoginData };
        user.data[0].phone = phone;
        localStorage.setItem("swc-doi-investor-data", JSON.stringify(user));
        dispatch(setUserLoginData(user));
    };
};

//Forgot Password Actions
export const onSendForgotOTP = (email) => {
    // console.log(phone)
    return async (dispatch) => {
        dispatch(apiLoading());
        // console.log(phone)
        let res = await sendOTPForPassword(email);
        // console.log(res, "asdasdsadsa")
        if (res.status) {
            dispatch(updateSendForgotOtp("otp"));
            actionNotifier.success("OTP sent successfully.");
        } else {
            actionNotifier.error(res.message);
        }
        dispatch(apiLoadingEnd());
    };
};

export const onUpdatePassword = (data) => {
    return async (dispatch) => {
        data = {
            ...data,
            newPassword: data.password,
        };
        delete data.password;
        dispatch(apiLoading());
        let res = await updatePassword(data);
        // console.log(res)
        if (res.status) {
            actionNotifier.success("Password updated successfully.");
            dispatch(updateSendForgotOtp("completed"));
        } else {
            actionNotifier.error(res.message);
        }
        dispatch(apiLoadingEnd());
    };
};

export const setRegisterUserData = (registerUserData) => {
    return {
        type: SET_REGISTER_USER_DATA,
        registerUserData,
    };
};

export const setSendOtpData = (sendOtpData) => {
    return {
        type: SET_SEND_OTP_DATA,
        sendOtpData,
    };
};

export const setVerifyOtpData = (verifyOtpData) => {
    return {
        type: SET_VERIFY_OTP_DATA,
        verifyOtpData,
    };
};

export const setUserLoginData = (loginUserData) => {
    return {
        type: SET_USER_LOGIN_DATA,
        loginUserData,
    };
};

export const setGenderListData = (genderListData) => {
    return {
        type: SET_GENDER_LIST_DATA,
        genderListData,
    };
};

export const setAuthLoading = (authLoadingValue) => {
    return {
        type: SET_AUTH_LOADING,
        authLoadingValue,
    };
};

export const setAuthSuccess = (authSuccessValue) => {
    return {
        type: SET_AUTH_SUCCESS,
        authSuccessValue,
    };
};

export const setAuthError = (authErrorValue) => {
    return {
        type: SET_AUTH_ERROR,
        authErrorValue,
    };
};
export const setOTPAction = (section, action) => {
    return {
        type: OTP_ACTION,
        payload: { section, action },
    };
};

export const updateGender = (data) => {
    return {
        type: UPDATE_GENDER,
        payload: data,
    };
};

export const updateSendForgotOtp = (status) => {
    return {
        type: SENT_FP_OTP,
        payload: status,
    };
};

const authAPIHandler = async (callback, action, payload, dispatch) => {
    try {
        dispatch(setAuthLoading(true));
        dispatch(setAuthSuccess(false));
        dispatch(setAuthError(false));
        let apiResponse = await callback(action, payload);
        console.log(apiResponse);
        // if (apiResponse?.status === false) {
        //   throw new Error();
        // }
        dispatch(setAuthLoading(false));
        dispatch(setAuthSuccess(true));
        return apiResponse;
    } catch (err) {
        dispatch(setAuthError(true));
        dispatch(setAuthLoading(false));
        dispatch(setAuthSuccess(false));
    }
};
