import { axios } from "../../shared/axios";
import store from "../../store/";
const errorMessage = "Something went wrong. Please try again";
const errorObject = {
    status: false,
    message: errorMessage,
};



export const getToken = (section) => {
    let auth;

    if (section === "INVESTOR") {
        // console.log(section)
        // let auth = store.getState().Auth.user;
        auth = localStorage.getItem("swc-doi-investor-login-token");
    }
    else if(section === "DEPARTMENT"){
        auth = localStorage.getItem("swc-department-login-data");
        auth = JSON.parse(auth)
        auth = auth.token;
    }
    if (auth) return auth;
    else return localStorage.getItem("swc-doi-investor-login-token");
};

//Without token
export const getAPIWithoutToken = async (url) => {
    let data;
    await axios
        .get(url, {})
        .then((res) => {
            data = res.data;
        })
        .catch((err) => {
            console.log(err);
            data = errorObject;
        });
    return data;
};

export const postAPIWithoutToken = async (url, postData) => {
    let data;
    await axios
        .post(url, postData, {})
        .then((res) => {
            data = res.data;
        })
        .catch((err) => {
            console.log(err.response.data);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    return data;
};

export const putAPIWithoutToken = async (url, postData) => {
    let data;
    await axios
        .put(url, postData, {})
        .then((res) => {
            data = res.data;
        })
        .catch((err) => {
            console.log(err.response.data);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    return data;
};

//with token

export const getAPI = async (url, section) => {
    let token = getToken(section);
    // console.log(token);
    let data;
    const config = {
        headers: { token: `${token}` },
    };
    await axios
        .get(url, config)
        .then((res) => {
            data = res.data;
        })
        .catch((err) => {
            console.log(err.response.data);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};

export const postAPI = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    // console.log(token)
    const config = {
        headers: { token: `${token}` },
    };
    await axios
        .post(url, postData, config)
        .then((res) => {
            console.log(res)
            if (res.data) {
                data = {
                    data: res.data.data,
                    status: true,
                    message: "Success",
                };
            } else {
                data = {
                    status: false,
                    message: "This action is not allowed",
                };
            }
        })
        .catch((err) => {
            console.log(err.response.data.message);
            console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};

export const postAPIData = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    // console.log(token)
    const config = {
        headers: { token: `${token}` },
    };
    await axios
        .post(url, postData, config)
        .then((res) => {
            console.log(res)
            if (res.data) {
                data = {
                    data: res.data,
                    status: true,
                    message: "Success",
                };
            } else {
                data = {
                    status: false,
                    message: "This action is not allowed",
                };
            }
        })
        .catch((err) => {
            console.log(err.response.data.message);
            console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};

export const putAPI = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    // console.log(token)
    const config = {
        headers: { token: `${token}` },
    };
    console.log(postData);
    await axios
        .put(url, postData, config)
        .then((res) => {
            console.log(res);
            if (res.data) {
                data = {
                    data: res.data.data,
                    status: true,
                    message: "Success",
                };
            } else {
                data = {
                    status: false,
                    message: "This action is not allowed",
                };
            }
        })
        .catch((err) => {
            console.log(err.response.data.message);
            console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};

export const deleteAPI = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    // console.log(token)
    const config = {
        headers: { token: `${token}` },
        data: postData,
    };
    console.log(postData);
    await axios
        .delete(url, config)
        .then((res) => {
            console.log(res);
            if (res.data) {
                data = {
                    data: res.data.data,
                    status: true,
                    message: "Success",
                };
            } else {
                data = {
                    status: false,
                    message: "This action is not allowed",
                };
            }
        })
        .catch((err) => {
            console.log(err.response.data.message);
            console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};

export const postAPIBlob = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    // console.log(token)
    const config = {
        headers: { token: `${token}` },
        responseType: "blob",
    };
    await axios
        .post(url, postData, config)
        .then((res) => {
            console.log(res);
            data = res.data;
        })
        .catch((err) => {
            console.log(err.response);
            data = errorObject;
        });
    // console.log(data);
    return data;
};

export const putFormDataAPI = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    const config = {
        headers: {
            "content-type": "multipart/form-data",
            token: `${token}`,
            onUploadProgress: (progressEvent) => {
                console.log("Upload Progress: " + Math.round((progressEvent.loaded / progressEvent.total) * 100) + "%");
            },
        },
    };
    await axios
        .put(url, postData, config)
        .then((res) => {
            data = res.data;
        })
        .catch((err) => {
            console.log(err.response.data.message);
            console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    return data;
};

export const postFormDataAPI = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    const config = {
        headers: {
            "content-type": "multipart/form-data",
            token: `${token}`,
        },
    };
    await axios
        .post(url, postData, config)
        .then((res) => {
            data = res.data;
        })
        
        .catch((err) => {
            console.log(err.response.data.message);
            console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    return data;
};


