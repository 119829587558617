import * as actionTypes from './actionTypes';

const initialState = {
    unitLicenceId: "",
    wizardSection: "",
    cafSection: "",
    sectionOperation: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_BASIC_DATA:
            state = {
                ...state,
                unitLicenceId: action.payload.unitLicenceId,
                wizardSection: action.payload.wizardSection,
                cafSection: action.payload.cafSection,
                sectionOperation: action.payload.sectionOperation,
            }
            break;
    }
    return state;
}

export default reducer;