
const scene = "investor/caf1/enterprizeProfile";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ONLOAD = `${scene}UPDATE_ONLOAD`;
export const UPDATE_ORG_OFFICE = `${scene}UPDATE_ORG_OFFICE`;
export const UPDATE_CAF_ID = `${scene}UPDATE_CAF_ID`;
export const UPDATE_PROFILE = `${scene}UPDATE_PROFILE`;
export const UPDATE_ADDRESS = `${scene}UPDATE_ADDRESS`;
export const ADD_UPDATE_PROFILE = `${scene}ADD_UPDATE_PROFILE`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
export const UPDATE_HEAD_OFFICE = `${scene}UPDATE_HEAD_OFFICE`;
export const UPDATE_INITIAL = `${scene}UPDATE_INITIAL`;
export const COMPLETE = `${scene}COMPLETE`;
export const UPDATE_ONLY_PROFILE = `${scene}UPDATE_ONLY_PROFILE`;

