import * as actionTypes from "./actionTypes";

const initialState = {
  modalTermLoan: false,
  modalQuarterly: false,
  modalAvailed: false,
  allQuery: [],
  allQueryLength: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MODAL_TERM_LOAN:
      state = {
        ...state,
        modalTermLoan: action.payload.data
      };
      break;
    case actionTypes.UPDATE_MODAL_QUARTERLY:
      state = {
        ...state,
        modalQuarterly: action.payload.data,
      };
      break;
    case actionTypes.UPDATE_AVAILED_INT_SUB:    
      state = {
        ...state,
        modalAvailed: action.payload.data,  
      };
      break;
      case actionTypes.UPDATE_QUERY:
        state = {
            ...state,
            allQuery: action.payload,
            allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
        }
  }
  return state;
};

export default reducer;
