import * as actionTypes from './actionTypes';


const initialState = {
    services: [],
    isNsws:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SERVICES:
            state = {
                ...state,
                services: action.payload.services,
                isNsws: action.payload.isNsws,
            }
            break;
    }
    return state;
}

export default reducer;