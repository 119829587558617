import { combineReducers } from "redux";

import Sample from "./reducer";
import DepartmentAddUser from "../pages/department/addUser/reducer";
import Auth from "./auth/reducer";
import EnterpriseProfile from "./enterprise/reducer";
import Notification from "./notification/reducer";


import UnitCrudReducer from "./unit/CRUD/reducer";
import UnitLocationDetailsReducer from "./unit/locationDetails/reducer";
//Investor

import InvestorReducer from "./allReducer/investor";
import DepartmentReducer from "./allReducer/department"
import HomeReducer from "./homePage/reducer"
import Licence from "../pages/licencePermitWizard/reducer"
import StickySidebar from "../containers/homepage/stickysidebar/reducer"
import DMS from "../store/dms/reducer"


const rootReducer = combineReducers({
  Sample,
  // Auth
  Auth,
  DepartmentAddUser,
  Notification,
  DepartmentReducer,
  DMS,

  // Unit
  UnitCrudReducer,
  UnitLocationDetailsReducer,

  // Enterprise
  EnterpriseProfile,

  //Investor
  InvestorReducer,

  //Home
  HomeReducer,
  Licence,
  StickySidebar
});

export default rootReducer;
