import { useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import PagesRoute from "./routes/Routes";
import Toast from "./components/ui/toast/toast";
import ToastLong from "./components/ui/toast/longToast";
import IdleTimer from "./containers/idleTimer"

import Loader from "./components/loader";
import "./App.css";

import "react-toastify/dist/ReactToastify.css";
import { updateLogin } from "./store/auth/actions";
import { importDepartmentDataFromLocal } from "./pages/department/login/actions";
import { toggleDms } from "./store/dms/actions"
import ModalLayout from "./components/modalLayout"
import DMS from "./containers/dms"

function App(props) {
    const location = useLocation()
    // console.log(props);
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log("sdfdsf")
        dispatch(updateLogin());
        dispatch(importDepartmentDataFromLocal());
    }, []);


    return (
        <>
            <div className="App">
                <IdleTimer />
                {/* {
                props.notification.message ? <Notification body={props.notification.message} type={"success"} /> : null
            }
            {
                props.notification.error ? <Notification body={props.notification.error} type={"error"} /> : null
            } */}
                {props.notification.loading ? <Loader active={props.notification.loading} /> : null}
                <div id="google_translate_element"></div>
                <PagesRoute />
                {
                    location.pathname.includes("investor/register")
                        ?
                        <ToastLong />
                        : <Toast />
                }
                {/* <button onClick={() => props.toggleDms()}>DMS</button> */}

            </div>
            {
                props.dms.dmsModal
                    ?
                    <ModalLayout
                        isOpen={props.dms.dmsModal}
                        toggleModal={() => props.toggleDms()}
                        title="DMS"
                    >
                        <DMS />
                    </ModalLayout >
                    : null
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        notification: state.Notification,
        dms: state.DMS
    };
};

export default connect(mapStateToProps, { toggleDms })(App);
