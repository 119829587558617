import * as actionTypes from './actionTypes';
import { getAPI, putAPI, getAPIWithoutToken, postAPI } from "../../../../../apiCalls/functions"
import { GET_STAKEHOLDERS_DETAILS, ADD_STAKEHOLDERS_DETAILS, GET_STAKEHOLDERS_INITIAL, GET_GENDER } from "../../../../../apiCalls/urls/investor/stakeholders"
import { apiLoading, apiLoadingEnd } from "../../../../../store/notification/action";
import { getDataFromInvestorStore } from "../../../../../store/getStore"
import { actionNotifier } from "../../../../../components/ui/toast/toast";
import { deleteDatFromObj } from "../../../../../shared/objFunctions"
import { deleteFromResponse } from "../../../../../shared/deleteJson"
import { fetchQueryBySection, replyQueryBySection } from "../enterpriseProfile/actions"


const softwareSection = process.env.REACT_APP_INVESTOR_SECTION_NAME

//AllAPIS
export const fetchStackHolderType = async () => {
    let type = await getAPI(GET_STAKEHOLDERS_INITIAL, softwareSection)
    return type
}
export const fetchGender = async () => {
    let gender = await getAPIWithoutToken(GET_GENDER)
    return gender
}
export const fetchStackHolders = async (enterpriseId) => {
    let data = {
        "generalDetailId": enterpriseId
    }
    let stackHolder = await postAPI(GET_STAKEHOLDERS_DETAILS, data, softwareSection)
    return stackHolder
}
export const addStackHolders = async (apiData) => {
    return await putAPI(ADD_STAKEHOLDERS_DETAILS, apiData, softwareSection)
}

export function getStackHolder() {
    return async (dispatch) => {
        dispatch(apiLoading())
        let type = await fetchStackHolderType();
        let gender = await fetchGender();
        // console.log(gender)
        if (type.status) {
            dispatch(updateTypeSocialDesignation(type.data.caste, type.data.designation, type.data.type, gender.data))
        }
        let dashboard = getDataFromInvestorStore("Dashboard")
        dispatch(onfetchQuery())
        let stackHolder = await fetchStackHolders(dashboard.enterpriseId);
        if (stackHolder.data && stackHolder.data.length > 0) {
            let allData = []
            stackHolder.data && stackHolder.data.forEach((s) => {
                let single = deleteDatFromObj(s, [...deleteFromResponse, "stakeholderId", "mOtherBusinessEntityDetailBusinessEntityDetailId", "created"])
                let _address = [];
                // console.log(single)
                if (single.address && single.address.length > 0) {
                    for (let i = 0; i < single.address.length; i++) {
                        let singleAddress = deleteDatFromObj(single.address[i], [...deleteFromResponse, "stakeholderId"])
                        _address.push(
                            singleAddress
                        )
                    }
                }
                single.entity = [..._address]
                delete single.address;
                allData.push(single)
            })
            // console.log(allData)
            dispatch(updateStackHolderData(allData))

        }
        dispatch(apiLoadingEnd())

        // console.log(profile)
        // dispatch(updateProfile(1))
    }
}

export const addUpdateStackHolders = () => {
    return async (dispatch) => {

        dispatch(apiLoading())
        let stakeHolderReducer = getDataFromInvestorStore("StakeHolderReducer");
        let dashboardReducer = getDataFromInvestorStore("Dashboard")

        let data = {
            stakeholder: stakeHolderReducer.allStackHolder,
            "generalDetailId": dashboardReducer.enterpriseId,
        }
        console.log(data);

        let stackHolder = await addStackHolders(data)
        console.log(stackHolder);
        if (stackHolder.status) {
            actionNotifier.success(stackHolder.message);
            dispatch(updateComplete(true))
        }
        else {
            actionNotifier.error(stackHolder.message);
        }
        dispatch(apiLoadingEnd())
    }
}

export const onfetchQuery = () => {
    return async (dispatch) => {
        let dashboardReducer = getDataFromInvestorStore("Dashboard")
        let result = await fetchQueryBySection(dashboardReducer.cafId, 2)
        console.log(result)
        if (result.status) {
            dispatch(updateQuery(result.data[0]))
        }
    }
}

export const onReplyQuery = (message) => {
    return async (dispatch) => {
        let stakeHolderReducer = getDataFromInvestorStore("StakeHolderReducer")
        let length = stakeHolderReducer.allQuery.queryChat ? stakeHolderReducer.allQuery.queryChat.length : 0;
        if (length > 0) {
            let result = await replyQueryBySection(stakeHolderReducer.allQuery.queryId,
                stakeHolderReducer.allQuery.queryChat[length - 1].fromUser,
                stakeHolderReducer.allQuery.queryChat[length - 1].fromUserRole,
                message
            )
            console.log(result)
            if (result.status) {
                dispatch(onfetchQuery())
            }
        }
    }
}

export const updateStackHolderData = (data) => {
    return {
        type: actionTypes.UPDATE_STACK_HOLDERS,
        payload: data
    };
}

export const updateTypeSocialDesignation = (social, designation, type, gender) => {
    return {
        type: actionTypes.UPDATE_SOCIAL_TYPE_DESIGNATION,
        payload: { social, designation, type, gender }
    };
}

export const updateAddress = (data) => {
    return {
        type: actionTypes.UPDATE_ADDRESS,
        payload: data
    };
}
export const updateQuery = (data) => {
    return {
        type: actionTypes.UPDATE_QUERY,
        payload: data
    };
}

export const updateInitialStack = () => {
    return {
        type: actionTypes.UPDATE_INITIAL,
        payload: {}
    };
}

export const updateComplete = (status) => {
    return {
        type: actionTypes.COMPLETE,
        payload: status
    };
}

