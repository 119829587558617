export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';

export const SET_REGISTER_USER_DATA = 'SET_REGISTER_USER_DATA';
export const SET_SEND_OTP_DATA = 'SET_SEND_OTP_DATA';
export const SET_VERIFY_OTP_DATA = 'SET_VERIFY_OTP_DATA';
export const SET_USER_LOGIN_DATA = 'SET_USER_LOGIN_DATA';
export const SET_GENDER_LIST_DATA = 'SET_GENDER_LIST_DATA';
export const OTP_ACTION = 'OTP_ACTION';
export const UPDATE_GENDER = 'UPDATE_GENDER';

export const SENT_FP_OTP = 'SENT_FP_OTP';
