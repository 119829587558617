const scene = "department/sipbMeeting";

export const ON_LOAD = `${scene}ON_LOAD`;
export const ON_LOAD_SUCCESS = `${scene}ON_LOAD_SUCCESS`;
export const ON_GET_COMPLETED_APPLICATIONS = `${scene}ON_GET_COMPLETED_APPLICATIONS`;
export const ON_GET_MEETINGS = `${scene}ON_GET_MEETINGS`;
export const UPDATE_ALL_DATA = `${scene}UPDATE_ALL_DATA`;
export const UPDATE_SELECTED_MEETING = `${scene}UPDATE_SELECTED_MEETING`;
export const UPDATE_SECTION = `${scene}UPDATE_SECTION`;
export const UPDATE_TABLE_PARAMETER = `${scene}UPDATE_TABLE_PARAMETER`;
export const UPDATE_ASSIGNED = `${scene}UPDATE_ASSIGNED`;
export const UPDATE_PROCESSED = `${scene}UPDATE_PROCESSED`;
export const UPDATE_ASSIGNED_CAF_DATA = `${scene}UPDATE_ASSIGNED_CAF_DATA`;
export const UPDATE_ASSIGNED_FC_DATA = `${scene}UPDATE_ASSIGNED_FC_DATA`;
export const UPDATE_INPROGRESS_CAF_DATA = `${scene}UPDATE_INPROGRESS_CAF_DATA`;
export const UPDATE_INPROGRESS_FC_DATA = `${scene}UPDATE_INPROGRESS_FC_DATA`;
export const UPDATE_SELECTED_CAF1 = `${scene}UPDATE_SELECTED_CAF1`;
export const UPDATE_SELECTED_FC = `${scene}UPDATE_SELECTED_FC`;
export const UPDATE_LATEST_MEETING = `${scene}UPDATE_LATEST_MEETING`;