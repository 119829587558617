import { combineReducers } from "redux";

//CAF1
import Dashboard from "../../containers/investorDashboard/dashboard/reducer"
import EnterprizeReducer from "../../containers/investorDashboard/dashboard/caf1Tabs/enterpriseProfile/reducer"
import StakeHolderReducer from "../../containers/investorDashboard/dashboard/caf1Tabs/stakeholders/reducer"
import unitReducer from "../../containers/investorDashboard/dashboard/caf1Tabs/unitDetails/reducer"
import ProductRawMaterial from "../../containers/investorDashboard/dashboard/caf1Tabs/productAndRawMaterials/reducer"
import Investment from "../../containers/investorDashboard/dashboard/caf1Tabs/investment/reducer"
import Employment from "../../containers/investorDashboard/dashboard/caf1Tabs/employment/reducer"
import Finance from "../../containers/investorDashboard/dashboard/caf1Tabs/financeAndTimeline/reducer"
import Document from "../../containers/investorDashboard/dashboard/caf1Tabs/uploadDocuments/reducer"
import ReviewAndSubmit from "../../containers/investorDashboard/dashboard/caf1Tabs/reviewAndSubmit/reducer"

//FC
import FinancialClearance from "../../containers/investorDashboard/financialClearance/reducer"
import FinancialClearanceUpload from "../../containers/investorDashboard/financialClearance/uploadDocuments/reducer"

import Grievance from "../../containers/investorDashboard/grievance/reducer"
import Licence from "../../containers/investorDashboard/licence/reducer"
import Settings from "../../containers/investorDashboard/settings/reducer"
import PaymentHistory from "../../containers/investorDashboard/paymentHistory/reducer"
import DOP from "../../containers/investorDashboard/dateOfProduction/dop/reducer"
import DOPDoc from "../../containers/investorDashboard/dateOfProduction/dop/documents/reducer"


//INCENTIVE
import Incentive from "../../containers/investorDashboard/incentive/reducer"
import IncentiveStamp from "../../containers/investorDashboard/incentive/stampDuty/reducer"
import IncentiveLandConversion from "../../containers/investorDashboard/incentive/landConversion/reducer"
import IncentiveHpStamp from "../../containers/investorDashboard/incentive/HighPriorityStampDuty/reducer"
import IncentiveHpLandConversion from "../../containers/investorDashboard/incentive/HighPriorityLandConversion/reducer"
import IncentiveInterestSubvention from "../../containers/investorDashboard/incentive/interestSubvention/reducer"
import IncentiveElectricityDuty from "../../containers/investorDashboard/incentive/electricityDuty/reducer"
import IncentiveDocument from "../../containers/investorDashboard/incentive/uploadDocuments/reducer"
import IncentiveReview from "../../containers/investorDashboard/incentive/reviewAndSubmit/reducer"
import IncentiveTax from "../../containers/investorDashboard/incentive/taxReimbursement/reducer"
import IncentiveEmploySubsidy from "../../containers/investorDashboard/incentive/employmentSubsidy/reducer"
import CapitalSubsidy from "../../containers/investorDashboard/incentive/capitalSubsidy/reducer"
import EmploymentSubsidyNew from "../../containers/investorDashboard/incentive/employmentSubsidyNew/reducer"
import PatentRegistration from "../../containers/investorDashboard/incentive/patentRegistration/reducer"
import IncentiveSkillSubsidy from "../../containers/investorDashboard/incentive/skillSubsidy/reducer"
import PowerTarrif from "../../containers/investorDashboard/incentive/powerTarrif/reducer"
import FreightReimbursement from "../../containers/investorDashboard/incentive/freightReimbursement/reducer"


//INCENTIVE
import Incentive11Form from "../../containers/investorDashboard/incentive11Form/reducer"

//INCENTIVE11
import Incentive11 from "../../containers/investorDashboard/incentive11/reducer"
import Incentive11Doc from "../../containers/investorDashboard/incentive11Form/uploadDocuments/reducer"


//CAF2
import CAF2Wizard from "../../containers/investorDashboard/dashboard/caf2Tabs/wizard/reducer"
import CAF2Licence from "../../containers/investorDashboard/dashboard/caf2Tabs/licenses/reducer"
import CAF2Basic from "../../containers/investorDashboard/dashboard/caf2Tabs/reducer"

//CAF2-3
import FireProvisionalBasic from "../../containers/investorDashboard/dashboard/caf2-3Section/fire/provisionalNoc/basicDetails/reducer"
import FireReducer from "../../containers/investorDashboard/dashboard/caf2-3Section/fire/provisionalNoc/reducer"
import FireDocument from "../../containers/investorDashboard/dashboard/caf2-3Section/fire/provisionalNoc/documents/reducer"
import FireFinalReducer from "../../containers/investorDashboard/dashboard/caf2-3Section/fire/finalNoc/reducer"

import BIADAApplication from "../../containers/investorDashboard/dashboard/caf2-3Section/biada/landAllotment/applicationDetails/reducer"
import BIADALand from "../../containers/investorDashboard/dashboard/caf2-3Section/biada/landAllotment/reducer"
import LandRegistration from "../../containers/investorDashboard/dashboard/caf2-3Section/rcd/registration/reducer"
import RoadCutting from "../../containers/investorDashboard/dashboard/caf2-3Section/rcd/roadCutting/reducer"
import WaterSupply from "../../containers/investorDashboard/dashboard/caf2-3Section/water/supplyOfWater/reducer"

import TreeFeeling from "../../containers/investorDashboard/dashboard/caf2-3Section/forest/treeFeeling/reducer"
import TreeTransit from "../../containers/investorDashboard/dashboard/caf2-3Section/forest/treeTransit/reducer"
import QueryList from "../../containers/investorDashboard/queryList/reducer"

//QUARTER REPORT
import QuarterReport from "../../containers/investorDashboard/quarterReport/reducer"
import QuarterReportDoc from "../../containers/investorDashboard/quarterReport/uploadDocuments/reducer"




const investorReducer = combineReducers({
    Dashboard,
    EnterprizeReducer,
    StakeHolderReducer,
    unitReducer,
    ProductRawMaterial,
    Investment,
    Employment,
    Finance,
    Document,
    ReviewAndSubmit,

    FinancialClearance,
    FinancialClearanceUpload,
    Grievance,
    Settings,
    Licence,
    PaymentHistory,
    DOP,
    DOPDoc,

    Incentive,
    IncentiveStamp,
    IncentiveDocument,
    IncentiveLandConversion,
    IncentiveInterestSubvention,
    IncentiveReview,
    IncentiveElectricityDuty,
    IncentiveHpStamp,
    IncentiveHpLandConversion,
    IncentiveEmploySubsidy,
    CapitalSubsidy,
    EmploymentSubsidyNew,
    PatentRegistration,
    IncentiveSkillSubsidy,
    PowerTarrif,
    FreightReimbursement,
    
    Incentive11,
    Incentive11Form,
    Incentive11Doc,

    CAF2Wizard,
    CAF2Licence,
    CAF2Basic,
    IncentiveTax,
    IncentiveReview,

    FireProvisionalBasic,
    FireReducer,
    FireDocument,
    FireFinalReducer,

    BIADAApplication,
    BIADALand,

    LandRegistration,
    RoadCutting,

    WaterSupply,

    TreeFeeling,
    TreeTransit,
    QueryList,
    QuarterReport,
    QuarterReportDoc
});

export default investorReducer;
