import * as actionTypes from "./actionTypes";

const allFeatures = {
    filter: true,
    search: true,
    add: true,
    edit: true,
    delete: true
}

const initialState = {
    dmsModal: false,
    allDocument: [],
    allStructDoc: [],
    features: { ...allFeatures },
    percent: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_DMS:
            return {
                ...state,
                dmsModal: !state.dmsModal,
                allDocument: state.dmsModal ? [] : state.allDocument,
                allStructDoc: state.dmsModal ? [] : state.allStructDoc
            };
        case actionTypes.UPDATE_DOCUMENT:
            return {
                ...state,
                allDocument: action.payload.data,
                allStructDoc: action.payload.allStructDoc,
                features: action.payload.features ? action.payload.features : { ...allFeatures },
                dmsModal: true,

            };
        case actionTypes.UPDATE_PERCENT:
            state = {
                ...state,
                percent: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
