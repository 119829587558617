export const SET_ENTERPRISE_LOADING = "SET_ENTERPRISE_LOADING";
export const SET_ENTERPRISE_SUCCESS = "SET_ENTERPRISE_SUCCESS";
export const SET_ENTERPRISE_ERROR = "SET_ENTERPRISE_ERROR";

export const SET_ENTERPRISE_DATA = "SET_ENTERPRISE_DATA";
export const SET_ORGINATION_TYPE_DATA = "SET_ORGINATION_TYPE_DATA";
export const SET_OFFICE_TYPE_DATA = "SET_OFFICE_TYPE_DATA";
export const SET_CAF_DATA = "SET_CAF_DATA";
export const SET_ENTERPRISE_BY_ID_DATA = "SET_ENTERPRISE_BY_ID_DATA";
export const SET_NEW_ENTERPRISE_DATA = "SET_NEW_ENTERPRISE_DATA";
