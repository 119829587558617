import * as actionTypes from './actionTypes';

const initialState = {
    tableParameter: {
        "search": "",
        "sort": {
            "attributes": ["id"],
            "sorts": ["desc"]
        },
        "pageNo": 1,
        "itemsPerPage": parseInt(process.env.REACT_APP_ITEMS_PER_PAGE)
    },
    allData: [],
    totalCount: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TABLE_PARAMETER:
            state = {
                ...state,
                tableParameter: action.payload,
            }
            break;
        case actionTypes.UPDATE_ALL_DATA:
            state = {
                ...state,
                allData: action.payload.data,
                totalCount: action.payload.totalCount,
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;