const initials = "";

export const REGISTER_USER = `${initials}register`;
export const SEND_OTP = `${initials}register/send-otp`;
export const VERIFY_OTP = `${initials}register`;
export const LOGIN_USER = `${initials}login/`;
export const GET_GENDER = `${initials}register/gender`;

export const FORGOT_PASSWORD_OTP = `${initials}/forgot-password/send-otp`;
export const UPDATE_PASSWORD_URL = `${initials}/forgot-password/reset-password`;
export const PARICHAY_IS_TOKEN_VALID = `${initials}/parichay/is-token-valid`
export const PARICHAY_LOGOUT = `${initials}/parichay/logout`
export const GET_BANNER = `${initials}/banner`;
export const GET_CONTACT_US = `${initials}/contact-us`;