import * as actionTypes from './actionTypes';
import { incentive4Tabs } from "../../../shared/investor/tabConstants"

const commonSection = {
    "maleOtherThanScSt": "",
    "maleScSt": "",
    "totalFemale": "",
    "dateOfProduction": "",
    "totalMonthOfProduction": "",
    "claimDate": "",
    "employeeContribution": "",
    "employerContribution": "",
    "chalanNumber": ""
}

const epf = [
    {
        "name": "Management & Office Staff",
        "section": "management",
        ...commonSection
    },
    {
        "name": "Supervisor",
        "section": "supervisor",
        ...commonSection,
    },
    {
        "name": "Skilled Workers",
        "section": "skilled",
        ...commonSection
    },
    {
        "name": "Unskilled Workers",
        "section": "unskilled",
        ...commonSection
    },
    {
        "name": "Other Staffs",
        "section": "other",
        ...commonSection
    }
]

const esi = [
    {
        "name": "Management & Office Staff",
        "section": "management",
        ...commonSection
    },
    {
        "name": "Supervisor",
        "section": "supervisor",
        ...commonSection,
    },
    {
        "name": "Skilled Workers",
        "section": "skilled",
        ...commonSection
    },
    {
        "name": "Unskilled Workers",
        "section": "unskilled",
        ...commonSection
    },
    {
        "name": "Other Staffs",
        "section": "other",
        ...commonSection
    }
]



const initialState = {
    incentiveQuery: {},
    selectedWizard: 1,
    incentiveTabs: {
        ...incentive4Tabs
    },
    finalSubmit: false,
    generalData: [],
    financialDetails: [],
    incentiveId: null,
    // Stamp
    stampDutyData: [],
    // land-reimbursement
    landConversionData: [],
    // electricity-reimbursement
    electricityData: [],
    // interest
    interestTermLoanData: [],
    interestIntSubClaimData: [],
    interestAvailedSubData: [],
    interestCheck: "No",
    // tax
    taxDataA: [],
    taxDataB: [],
    // stamp-exemption
    HighStampDuty: [],
    // land-exemption
    HighLandConversion: [],
    allQuery: [],
    allQueryLength: 0,
    // employment-subsidy   
    allEpf: [...epf],
    allEsi: [...esi],
    finance: {},
    // capital subsidy
    capitalSubsidy: [],
    employmmentSubsidy: [],
    establisment:{},
    skillSubsidy: [],
    powerTarrif: [],
    patentRegistration: {},
    freightReimbursement: [],
    otherFreightData:{}


}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ALL:
            state = {
                ...state,
                allCaf1: action.payload
            }
            break;
        case actionTypes.UPDATE_FINAL_SUBMIT:
            state = {
                ...state,
                finalSubmit: action.payload
            }
            break;
        case actionTypes.UPDATE_GENERAL_DATA:
            state = {
                ...state,
                generalData: action.payload.data,
                incentiveId: action.payload.incentiveId
            }
            break;
        case actionTypes.UPDATE_WIZARD:
            state = {
                ...state,
                selectedWizard: action.payload,
            }
            break;
        case actionTypes.UPDATE_STAMP_DATA:
            state = {
                ...state,
                stampDutyData: action.payload,
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                incentiveTabs: action.payload
            }
            break;
        case actionTypes.UPDATE_LAND_CONVERSION_DATA:
            state = {
                ...state,
                landConversionData: action.payload,
            }
            break;
        case actionTypes.UPDATE_ELECTRICITY_DATA:
            state = {
                ...state,
                electricityData: action.payload,
            }
            break;
        case actionTypes.UPDATE_TERM_LOAN_DATA:
            state = {
                ...state,
                interestTermLoanData: action.payload,
            }
            break;
        case actionTypes.UPDATE_INT_SUB_CLAIM_DATA:
            state = {
                ...state,
                interestIntSubClaimData: action.payload,
            }
            break;
        case actionTypes.UPDATE_INT_AVAILED_DATA:
            state = {
                ...state,
                interestAvailedSubData: action.payload,
            }
            break;
        case actionTypes.UPDATE_INT_CHECK:
            state = {
                ...state,
                interestCheck: action.payload,
            }
            break;
        case actionTypes.UPDATE_TAX_DETAILS_A:
            state = {
                ...state,
                taxDataA: action.payload,
            }
            break;
        case actionTypes.UPDATE_TAX_DETAILS_B:
            state = {
                ...state,
                taxDataB: action.payload,
            }
            break;
        case actionTypes.UPDATE_STAMP_DATA_HP:
            state = {
                ...state,
                HighStampDuty: action.payload,
            }
            break;
        case actionTypes.UPDATE_LAND_CONVERSION_DATA_HP:
            state = {
                ...state,
                HighLandConversion: action.payload,
            }
            break;

        case actionTypes.UPDATE_EMPLOY_DETAILS:
            state = {
                ...state,
                EmployDetails: action.payload,
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;

        case actionTypes.UPDATE_EMPLOYMENT_DATA:
            state = {
                ...state,
                allEpf: action.payload.epf,
                allEsi: action.payload.esi,
            }
            break;
        case actionTypes.UPDATE_DATA:
            state = {
                ...state,
                [action.payload.section]: action.payload.data
            }
            break;
        case actionTypes.UPDATE_FINANCE_DATA:
            state = {
                ...state,
                finance: action.payload.data
            }
            break;
        case actionTypes.UPDATE_INITIAL_DATA:
            state = {
                ...state,
                finalSubmit: false,
            }
            break;
        case actionTypes.UPDATE_CAPITAL_SUBSIDY:
            state = {
                ...state,
                capitalSubsidy: action.payload
            }
            break;
        case actionTypes.UPDATE_EMPLOYMENT_SUBSIDY:
            state = {
                ...state,
                employmmentSubsidy: action.payload.data,
                establisment: action.payload.data2,
            }
            break;
        case actionTypes.UPDATE_SKILL_SUBSIDY:
            state = {
                ...state,
                skillSubsidy: action.payload
            }
            break;
        case actionTypes.UPDATE_POWER_TARRIF:
            state = {
                ...state,
                powerTarrif: action.payload
            }
            break;
        case actionTypes.UPDATE_PATENT_REGISTRATION:
            state = {
                ...state,
                patentRegistration: action.payload
            }
            break;
        case actionTypes.UPDATE_FREIGHT_REIMBURSEMENT:
            state = {
                ...state,
                freightReimbursement: action.payload.data,
                otherFreightData:action.payload.data2

            }
            break;
        default:
            state = { ...state }
    }

    return state;
}

export default reducer;