import * as actionTypes from './actionTypes';
const total = {
    male: "",
    female: "",
    total: ""
}
const allEmployee = [
    {
        "name": "Management & Office Staff",
        "section": "management",
        male: "",
        female: "",
        total: ""
    },
    {
        "name": "Supervisor",
        "section": "supervisor",
        male: "",
        female: "",
        total: ""
    },
    {
        "name": "Skilled Workers",
        "section": "skilled",
        male: "",
        female: "",
        total: ""
    },
    {
        "name": "Unskilled Workers",
        "section": "unskilled",
        male: "",
        female: "",
        total: ""
    },
    {
        "name": "Other Staffs",
        "section": "other",
        male: "",
        female: "",
        total: ""
    }
]

const initialState = {
    total: {
        ...total
    },
    allEmployee: [
        ...allEmployee
    ],
    totalExisting: {
        ...total
    },
    allEmployeeExisting: [
        ...allEmployee
    ],
    allQuery: {},
    allQueryLength: 0,
    complete: false,
    isExpand: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_EMPLOYMENT:
            state = {
                ...state,
                allEmployee: action.payload.allEmployee,
                total: action.payload.total,
            }
            break;
        case actionTypes.UPDATE_INITIAL:
            state = {
                ...state,
                allEmployee: [
                    ...allEmployee
                ],
                total: {
                    ...total
                },
            }
            break;
        case actionTypes.UPDATE_EXPAND:
            state = {
                ...state,
                isExpand: action.payload
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload?.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.COMPLETE:
            state = {
                ...state,
                complete: action.payload
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;