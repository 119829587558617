import * as actionTypes from './actionTypes';
import { fcTabs } from "../../../shared/investor/tabConstants"

const initialState = {
    fcQuery: {},
    fcFilled: {},
    allCaf1: [],
    selectedCaf1: "",
    fcId: "",
    selectedWizard: 1,
    fcData: {},
    stakeholders: [],
    investmentCaf: {},
    financeCaf: {},

    social: [],
    designation: [],
    stakeholderType: [],
    gender: [],

    district: [],
    locatedIn: [],
    ownership: [],
    mainActivity: [],

    otpSent: false,
    finalSubmit: false,
    fcTabs: {
        ...fcTabs
    },
    applicationComplete: false,
    allBanks: [],
    allLandCategory: [],
    allSectorCategory: [],
    allUnitCategory: [],
    allQuery: [],
    allQueryLength: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ALL_CAF1:
            state = {
                ...state,
                allCaf1: action.payload
            }
            break;
        case actionTypes.UPDATE_CAF1_ID:
            state = {
                ...state,
                selectedCaf1: action.payload
            }
            break;
        case actionTypes.UPDATE_WIZARD_SECTION:
            state = {
                ...state,
                selectedWizard: action.payload
            }
            break;
        case actionTypes.UPDATE_FC_DATA:
            state = {
                ...state,
                fcData: action.payload
            }
            break;
        case actionTypes.UPDATE_FC_ALL_DATA:
            state = {
                ...state,
                fcId: action.payload.fcId,
                fcData: action.payload.fcData,
                stakeholders: action.payload.stakeholders,
                investmentCaf: action.payload.investmentCaf,
                financeCaf: action.payload.financeCaf
            }
            break;
        case actionTypes.UPDATE_SOCIAL_TYPE_DESIGNATION:
            state = {
                ...state,
                social: action.payload.social,
                stakeholderType: action.payload.type,
                gender: action.payload.gender,
                district: action.payload.district,
                mainActivity: action.payload.list,
                locatedIn: action.payload.locatedIn,
            }
            break;
        case actionTypes.UPDATE_DROPDOWN:
            state = {
                ...state,
                district: action.payload.district,
                locatedIn: action.payload.locatedIn,
                ownership: action.payload.ownership,
            }
            break;
        case actionTypes.UPDATE_FINAL_SUBMIT:
            state = {
                ...state,
                finalSubmit: action.payload,
            }
            break;
        case actionTypes.UPDATE_OTP_SENT:
            state = {
                ...state,
                otpSent: action.payload,
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                [action.payload.tabs]: action.payload.data
            }
            break;
        case actionTypes.APPLICATION_COMPLETE:
            state = {
                ...state,
                applicationComplete: action.payload
            }
            break;
        case actionTypes.UPDATE_SECTOR_LAND_BANK:
            state = {
                ...state,
                allBanks: action.payload.bank,
                allLandCategory: action.payload.land,
                allSectorCategory: action.payload.sector,
                allUnitCategory: action.payload.unit
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
}

export default reducer;