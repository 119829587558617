import * as actionTypes from './actionTypes'

const initialState = {
    allQuery: [],
    allQueryLength: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DATA:
            state = {
                ...state,
                [action.payload.section]: action.payload.data
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload?.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.UPDATE_MODAL:
            state = {
                ...state,
                modal: action.payload,
            }
            break;
        default:
            state ={ ...state}
    }
    return state;
}

export default reducer;