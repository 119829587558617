import * as actionTypes from './actionTypes';

const allInvestment = {
    "machine": [],
    "land": null,
    "landDevelopment": null,
    "civilWorkTech": null,
    "civilWorkNontech": null,
    "proposedSite": null,
    "totalSite": null,
    "indigenous": null,
    "import": null,
    "proposedPlant": null,
    "plantTotal": null,
    "fixedAsset": null,
    "furniture": null,
    "preOperative": null,
    "interestConstruction": null,
    "contingencies": null,
    "proposedCapital": null,
    "othersTotal": null,
    "grossTotal": null,
    proposedSite: null,
    proposedPlant: null,
    proposedCapital: null
}

const initialState = {
    investment: {
        ...allInvestment
    },
    investmentExisting: {
        ...allInvestment
    },
    allUnit: [],
    allQuery: {},
    allQueryLength: 0,
    complete: false,
    isExpand: false,
    fcData: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ONLOAD:
            state = {
                ...state,
                allData: action.allData,
                total: action.total
            }
            break;
        case actionTypes.UPDATE_INVESTMENT:
            state = {
                ...state,
                investment: action.payload
            }
            break;
        case actionTypes.UPDATE_INVESTMENT_UNIT:
            state = {
                ...state,
                investment: action.payload.investment ? {
                    ...action.payload.investment, proposedSite: null,
                    proposedPlant: null,
                } : {
                    ...allInvestment
                },
                allUnit: action.payload.unit,
                investmentExisting: action.payload.existing ? action.payload.existing : state.investmentExisting,
                isExpand: action.payload.isExpand
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.COMPLETE:
            state = {
                ...state,
                complete: action.payload
            }
            break;
        case actionTypes.UPDATE_REDUCER:
            state = {
                ...state,
                [action.payload.key]: action.payload.data
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;