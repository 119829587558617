import * as actionTypes from './actionTypes';


const initialState = {
    // formDetails: {
    //     "nameOfCompany": "",
    //     "constitutionType": "",
    //     "companyCategory": "",
    //     "registrationDate": "",
    //     "address1": "",
    //     "address2": "",
    //     "district": "",
    //     "state": "",
    //     "pincode": "",
    //     "email": "",
    //     "mobile": "",
    //     "alternate_mobile": "",
    //     "telephone": "",
    //     "pan": "",
    //     "gstin": "",
    //     "udyog_aadhar": "",
    // },
    formDetails:null,
    promoterDetails: [],
    promoterDetailsUploadFields: [],
    allCaste: [],
    allGender: [],
    allOrg: [],
    allCat:[]
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_BASIC:
            state = {
                ...state,
                formDetails: action.payload.formDetails,
                promoterDetails: action.payload.promoterDetails,
                promoterDetailsUploadFields: action.payload.promoterDetailsUploadFields,
            }
            break;
        case actionTypes.UPDATE_TYPE_GENDER:
            state = {
                ...state,
                allCaste: action.payload.caste,
                allGender: action.payload.gender,
                allOrg: action.payload.org,
                allCat: action.payload.cat,
            }
            break;
        case actionTypes.UPDATE_KEY_VALUE:
            state = {
                ...state,
                [action.payload.key]: action.payload.value,
            }
            break;
    }
    return state;
}

export default reducer;