import * as actionTypes from "../../../store/homePage/actionTypes";

const initialState = {
    allData: [],
}

const reducer = (state = initialState, action) => {
    // console.log(action)
    switch (action.type) {
        case actionTypes.UPDATE_DATA:
            state = {
                ...state,
                allData: action.payload,
            }
            break;
    }
    return state;
}


export default reducer;