const initials = "/caf-one";

export const GET_ORGANIZATION_TYPE = `${initials}/org-type`;
export const GET_OFFICE_TYPE = `${initials}/office-type`;
export const GET_CAF_ID = `${initials}`;
export const GET_ENTERPRIZE_PROFILE = `${initials}/enterprise-profile`;
export const ADD_ENTERPRIZE_PROFILE = `${initials}/enterprise-profile`;
export const EDIT_ENTERPRIZE_PROFILE = `${initials}/edit-enterprise-profile`;

export const GET_STATE = "/unit/get-states/";
export const GET_DISTRICT = "/unit/get-district/";
export const GET_BLOCK = "/unit/get-block/";
export const GET_CITY = "/unit/get-city/";

export const GET_LOGS = "/unit/get-logs"
export const GET_COMPLETED_INCENTIVE = "/incentive/common/get-old-incentives"
export const GET_COMPLETED_FC = "/fc/get-old-fc-details"
export const GET_COMPLETED_DOP = "/dop/view-all-submitted"



export const GET_CAF_SECTION_STATUS = `${initials}/get-section-details`;
