
const scene = "swc/department/singleApplication";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_DETAILS = `${scene}UPDATE_DETAILS`;
export const UPDATE_ID_TYPE = `${scene}UPDATE_ID_TYPE`;
export const SET_USERS_OF_ROLE = `${scene}SET_USERS_OF_ROLE`;
export const SET_DRAFT_QUERIES = `${scene}SET_DRAFT_QUERIES`
export const UPDATE_LOGS = `${scene}UPDATE_LOGS`
export const SET_DROPDOWN_VALUES = `${scene}SET_DROPDOWN_VALUES`
export const OPEN_CONFIRMATION_MODAL = `${scene}OPEN_CONFIRMATION_MODAL`