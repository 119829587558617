import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import ProgressBar from "@ramonak/react-progress-bar";
import classes from "./styles.module.scss";
import Input from "../../components/ui/input";
import Buttons from "../../components/ui/buttons";
import { getAllFle, uploadFile, updateDesc } from "../../store/dms/actions";
import { fileType } from "./options";
import pdfImg from "../../assets/images/PDF_file_icon.svg.png"
import { getDocWithSection } from "../../shared/objFunctions";
import { apiLoadingEnd, apiLoading } from "../../store/notification/action"

const SingleWindowTab = (props) => {
    const uploadOneRef = useRef();

    const [section, setSection] = useState("")
    const [search, setSearch] = useState("")
    const [allStructDoc, setAllStructDoc] = useState([])

    const [singleFile, setSingleFile] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)


    const handleUploadClick = () => {
        uploadOneRef.current.click();
    };

    const handleChange = (e, name) => {
        let _singleFile = { ...singleFile }
        if (name === "file") {
            _singleFile[name] = e.target.files[0];
            _singleFile.name = e.target.files[0].name;
        }
        else
            _singleFile[name] = e;
        setSingleFile(_singleFile)
    }


    const handleFileClick = (f, i, j) => {
        console.log(f);
        const section = document.querySelector( '#file-section' );
        section.scrollIntoView( { behavior: 'smooth'} );
        setSingleFile({ ...f })
        setSelectedFile({ i, j })
    }


    useEffect(() => {
        if (props.dms.allStructDoc.length)
            setAllStructDoc(JSON.parse(JSON.stringify(props.dms.allStructDoc)))
    }, [props.dms.allStructDoc])

    useEffect(async () => {
        if (section) {
            let _allStructDoc = JSON.parse(JSON.stringify(props.dms.allStructDoc))
            let data = _allStructDoc.filter((f) => f.name === section)
            let final = []
            if (data.length && search) {
                // console.log(section, search);
                data[0].children.forEach((c, j) => {
                    if (c.documentName.includes(search)) {
                        final.push(c)
                    }
                })
                data[0].children = [...final]
                setAllStructDoc([...data])
            }
            else {
                // console.log(section, search);
                // console.log(data)
                setAllStructDoc([...data])
            }
        }
        else if (search) {
            let final = []
            props.dms.allDocument.forEach((c, j) => {
                if (c.documentName.includes(search)) {
                    final.push(c)
                }
            })
            // console.log(final)
            // props.apiLoading()
            // console.log(1);
            let _data = await getDocWithSection(final)
            // console.log(2);
            // console.log(_data)
            // props.apiLoadingEnd()

            setAllStructDoc(_data)
        }
        else {
            setAllStructDoc(JSON.parse(JSON.stringify(props.dms.allStructDoc)))
        }


    }, [section, search])

    const handleSubmit = async (e) => {
        // e.preventDefault()
        // if (singleFile.documentId) {
        //     let res = await updateDesc(singleFile.tag, singleFile.documentId);
        //     if (res.status) {

        //     }
        // }
        // else {
        //     let res = props.uploadFile(singleFile)
        //     console.log(res);
        // }
    }

    console.log(selectedFile);

    return (
        <div id="file-section" className={classes.dmdContainer}>
            <div className={classes.imageZone} style={selectedFile ? {} : { width: "100%" }}>
                <div className={classes.formGrid3}>
                    <Input
                        title={"Section"}
                        type={"select"}
                        options={props.dms.allStructDoc.map((c) => { return { label: c.name, value: c.name } })}
                        optionLabel="label"
                        optionValue="value"
                        value={section}
                        onChange={(e) => setSection(e)}
                    />
                    {/* <Input
                        title={"Date after"}
                        type={"date"}
                        value={filter.date}
                        onChange={(e) => handleFilter(e, "date")}
                    /> */}
                    <Input
                        title={"Search file name"}
                        placeholder={"Search file name"}
                        type={"text"}
                        value={search}
                        onChange={(e) => setSearch(e)}
                    />
                </div>

                {/* <div onClick={handleUploadClick} className={classes.eachFile}>
                        <div className={classes.thumbnail}>
                            <div className={classes.centered}>
                                <img className={classes.dmsImg} src="https://jkfenner.com/wp-content/uploads/2019/11/default.jpg" alt={""}></img>
                            </div>
                            <div>Click here to upload file</div>
                        </div>
                        <input
                            type="file"
                            style={{ display: "none" }}
                            ref={uploadOneRef}

                            accept="application/pdf,image/x-png,image/gif,image/jpeg,image/jpg"
                            onChange={(e) => handleChange(e, "file")}
                        />
                    </div> */}
                {
                    allStructDoc.map((a, i) => (
                        <>
                            <div className={classes.imageTitle}>{a.name}</div>
                            <div className={classes.allImages}>
                                {
                                    a.children.map((c, j) => (
                                        <div className={classes.imageSection}>
                                            <div onClick={() => handleFileClick(c, i, j)} title={c.documentName} key={j} className={selectedFile?.i == i && selectedFile?.j == j ? `${classes.eachFile} ${classes.active}` : classes.eachFile}>
                                                <img className={classes.dmsFile} src={c.documentUrl.includes("image") ? c.documentUrl : pdfImg} alt={c.documentUrl}></img>
                                            </div>
                                            <div className={classes.eachFileName}>
                                                {c.documentName}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    ))
                }
                {/* <div>
                    {
                        total > filter.limit
                            ?
                            <Pagination
                                activePage={filter.pageNo}
                                itemsCountPerPage={filter.limit}
                                totalItemsCount={total}
                                pageRangeDisplayed={3}
                                onChange={(e) => handleFilter(e, "pageNo")}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                            : null
                    }

                </div> */}
            </div>
            {
                selectedFile ?
                    <form onSubmit={handleSubmit} className={classes.infoZone}>
                        <div >
                            <div className={classes.fileName}>
                                <strong>Section: </strong>{singleFile.serviceType}
                            </div>
                            <div className={classes.fileName}>
                                <strong>Application No: </strong>{singleFile.applicationNo}
                            </div>
                            <div className={classes.fileName}>
                                <strong>Filename: </strong>{singleFile.documentName}
                            </div>
                            <div className={classes.fileName}>
                                <strong>Description: </strong>
                                <Input
                                    title={""}
                                    type={"textarea"}
                                    value={singleFile.documentDesc}
                                    disabled={true}
                                    // onChange={(e) => handleChange(e, "tag")}
                                    placeholder={"File description"}
                                />
                            </div>
                            {
                                singleFile.documentUrl && <div className={classes.fileName}>
                                    <strong>URL: </strong>
                                    <a href={singleFile.documentUrl} target="_blank">{singleFile.documentUrl}</a>
                                </div>
                            }
                            {
                                props.dms.percent ?
                                    <div className={classes.fileUpload}>
                                        <ProgressBar completed={props.dms.percent}
                                            labelSize={'10px'}
                                            height={'15px'}
                                            labelClassName={classes.labelClassName}
                                        />
                                    </div>
                                    : null

                            }
                            {/* <Buttons type="submit" title="Save" className="auto" /> */}
                        </div>
                    </form>

                    : null
            }
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        dms: state.DMS,
        auth: state.Auth
    };
};

const mapDispatchToProps = { uploadFile, apiLoadingEnd, apiLoading }

export default connect(mapStateToProps, mapDispatchToProps)(SingleWindowTab);
