import * as actionTypes from './actionTypes';

const commonSection = {
    "maleOtherThanScSt": "",
        "maleScSt": "",
        "totalFemale": "",
        "dateOfProduction": "",
        "totalMonthOfProduction": "",
        "claimDate": "",
        "employeeContribution": "",
        "employerContribution": "",
        "chalanNumber": ""
}

const epf = [
    {
        "name": "Management & Office Staff",
        "section": "management",
        ...commonSection
    },
    {
        "name": "Supervisor",
        "section": "supervisor",
        ...commonSection,
    },
    {
        "name": "Skilled Workers",
        "section": "skilled",
        ...commonSection
    },
    {
        "name": "Unskilled Workers",
        "section": "unskilled",
        ...commonSection
    },
    {
        "name": "Other Staffs",
        "section": "other",
        ...commonSection
    }
]

const esi = [
    {
        "name": "Management & Office Staff",
        "section": "management",
        ...commonSection
    },
    {
        "name": "Supervisor",
        "section": "supervisor",
        ...commonSection,
    },
    {
        "name": "Skilled Workers",
        "section": "skilled",
        ...commonSection
    },
    {
        "name": "Unskilled Workers",
        "section": "unskilled",
        ...commonSection
    },
    {
        "name": "Other Staffs",
        "section": "other",
        ...commonSection
    }
]

const initialState = {
    allEpf:[...epf],
    allEsi:[...esi],
    allQuery: [],
    allQueryLength: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_EMPLOYMENT_DATA:
            state = {
                ...state,
                allEpf: action.payload.epf,
                allEsi: action.payload.esi,
            }
            break;
        case actionTypes.UPDATE_DATA:
            state = {
                ...state,
                [action.payload.section]: action.payload.data
            }
            break;
            case actionTypes.UPDATE_QUERY:
        state = {
            ...state,
            allQuery: action.payload,
            allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
        }
        break;
    }
    return state;
}

export default reducer;