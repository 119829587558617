import React from "react";
import { useNavigate, Link } from "react-router-dom"
import classes from "./middleTopFooter.module.scss";

import indianGov from "../../../../assets/images/homepage/footer/indianGov.png";
import swcManual from "../../../../assets/pdf/SWC User Manual .pdf";

const BottomFooter = () => {

    const navigate = useNavigate();

    return (
        <div className={classes.mainContainer}>
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    <div className={classes.header}>
                        If you need any assistance, please contact us on 18003456214 or
                        email to{" "}
                        <span className={classes.subHeader}><a href="mailto:sipb.care@bihar.gov.in"> sipb.care@bihar.gov.in</a> </span>
                    </div>
                </div>
                <div className={classes.social}>
                    <div className={classes.links}>
                        <div className={classes.linkHeader}>
                            LINKS
                        </div>

                        <div className={classes.linksSection}>
                            <Link className={classes.eachLink} to="/investor/quick-links"> Quick Links</Link>
                            <Link className={classes.eachLink} to="/investor/accessibility-statement"> Accessibility Statement</Link>
                            <Link className={classes.eachLink} to="/investor/hyperlink-policy"> Hyperlinking Policy </Link>
                            {/* <Link to="/investor/help"> Private Policy</Link> | */}
                            <Link className={classes.eachLink} to="/investor/copyright"> Copyright Policy</Link>
                            {/* <Link to="/investor/help"> Terms & Conditions</Link> | */}
                            <Link className={classes.eachLink} to="/investor/help"> Help</Link>
                            <Link className={classes.eachLink} to="/investor/privacy-policy"> Privacy Policy</Link>
                            <Link className={classes.eachLink} to="/investor/terms-of-use"> Terms of Use</Link>
                            <Link className={classes.eachLink} I to={swcManual} target={"_blank"}> SWC User Manual</Link>
                        </div>

                    </div>
                    <div className={classes.socialLinks}>
                        <div>
                            <div className={classes.linkHeader}>
                                FACEBOOK FEED
                            </div>
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FBiharIndustriesDept%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" width="300" height="400" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                    <div className={classes.socialLinks}>
                        <div>
                            <div className={classes.linkHeader}>
                                TWITTER FEED
                            </div>
                            <div className={classes.twitter} style={{
                                overflowY: 'scroll',
                                overflowX: 'hidden'
                            }}>
                                <a class="twitter-timeline" href="https://twitter.com/IndustriesBihar" data-tweet-limit="3"></a>
                            </div>

                        </div>
                    </div>
                    <div className={classes.links}>
                        <div className={classes.linkHeader}>
                            ADDRESS
                        </div>
                        <div style={{ color: '#000000', marginTop: 0, fontSize: 15, textAlign: "start" }}>
                            <p>
                                State Investments Promotion Board,<br /><br />
                                Department of Industries,<br />
                                2nd Floor, Vikas Bhawan,<br />
                                New Secretariat,<br />
                                Bailey road<br />
                                Patna- 800015

                            </p>
                            <span>Phone No.:<a href="tel:18003456214" title="Call to 18003456214"> 18003456214</a></span>
                            <br />
                            <span>Email:<a href="mailto:sipb.care@bihar.gov.in" title="Email to sipb.care@bihar.gov.in"> sipb.care@bihar.gov.in</a></span>

                        </div>
                    </div>

                </div>
                <div className={classes.rtiContainer}>
                    {/* <div className={classes.rti}>
                        <i className="fa-regular fa-file"></i> RTI
                    </div> */}
                    <div
                        onClick={() => navigate("/department-login")}
                        className={classes.department}
                    >
                        <i className="fa-solid fa-user"></i> Department Login
                    </div>
                </div>
                {/* <div className={classes.policiesContainer}>
                    <div className={classes.policies}>
                        <Link to="/investor/accessibility-statement"> Accessibility Statement</Link> |
                        <Link to="/investor/hyperlink-policy"> Hyperlinking Policy </Link>|
                        <Link to="/investor/copyright"> Copyright Policy</Link> |
                        <Link to="/investor/help"> Help</Link> |
                        <Link to="/investor/privacy-policy"> Privacy Policy</Link> |
                        <Link to="/investor/terms-of-use"> Terms of Use</Link> |
                        <Link to={swcManual} target={"_blank"}> SWC User Manual</Link>

                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default BottomFooter;
