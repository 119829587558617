import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import classes from "./bottomNav.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import "tippy.js/animations/shift-away.css";
import DropdownTippy from "./dropdownTippy/dropdownTippy";
import ServicesAndSchemesDropdown from "./servicesAndSchemesDropdown/servicesAndSchemesDropdown";
import InvestorZoneDropdown from "./investorZoneDropdown/investorZoneDropdown";
import EODBDropdown from "./eodb";
import swcManual from "../../../../assets/pdf/SWC User Manual .pdf";
import { openInNewTab } from "../../../../shared/saveAs";

import ContactUsDropdown from "./contactUsDropdown/contactUsDropdown";
import QuickLinkDropdown from "./quickLinkDropdown/quickLinkDropdown";
import AboutUsDropdown from "./aboutUsDropdown/aboutUsDropdown";
import LoggedIn from "./dropdownTippy/departmentLoggedIn";

import SubMenu from "./subMenu";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Container, Nav, NavDropdown, Offcanvas, Form, FormControl, Button, MenuItem, NavItem } from 'react-bootstrap';
import './nav.custom.css';

const departmentLoggedIn = [
    {
        name: "Change Password",
        path: "/department/change_password",
        compulsory: true,
        children: [],
    },
    {
        name: "Logout",
        path: "/logout/department",
        compulsory: true,
        children: [],
    },
];

const investorLoggedIn = [
    {
        name: "Dashboard",
        path: "/investor/investor-home/dashboard",
        compulsory: true,
        children: [],
    },
    {
        name: "Settings",
        path: "/investor/investor-home/settings",
        compulsory: true,
        children: [],
    },
    {
        name: "Logout",
        path: "/logout/investor",
        compulsory: true,
        children: [],
    },
];

const BottomNav = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [aboutUsNavbarHover, setAboutUsNavbarHover] = useState(false);
    const [servicesAndSchemesNavbarHover, setServicesAndSchemesNavbarHover] = useState(false);
    const [noticeBoardNavbarHover, setNoticeBoardNavbarHover] = useState(false);
    const [investorZoneNavbarHover, setInvestorZoneNavbarHover] = useState(false);
    const [contactUsNavbarHover, setContactUsNavbarHover] = useState(false);
    const [quickLinksNavbarHover, setQuickLinksNavbarHover] = useState(false);
    const [eodbHover, setEodbHover] = useState(false);

    const [expanded, setExpanded] = useState(false)

    const expand = () => {
        setExpanded(!expanded)
    }

    const generateLoginSection = (section) => {
        if (section === "department") {
            // console.log(props.departmentLogin.authDetails)
            if (props.departmentLogin.authDetails.token) {
                return (
                    <div className={classes.loginWrapper}>
                        <Tippy
                            placement="bottom"
                            arrow={false}
                            delay={[0, 0]}
                            animation="scale-subtle"
                            interactive={true}
                            content={<LoggedIn menu={departmentLoggedIn} />}
                            className={classes.tippy}>
                            <button className={classes.loginBtn}>
                                Namaste, {props.departmentLogin.authDetails?.data?.firstName} <i className="fa-solid fa-caret-down"></i>
                            </button>
                        </Tippy>
                    </div>
                );
            } else {
                return (
                    <div className={classes.loginWrapper}>
                        <Tippy
                            placement="bottom"
                            arrow={false}
                            delay={[0, 0]}
                            animation="scale-subtle"
                            interactive={true}
                            className={classes.tippy}>
                            <button className={classes.loginBtn}>Department Login</button>
                        </Tippy>
                    </div>
                );
            }
        } else {
            // console.log(props.departmentLogin.authDetails)
            if (props.investorLogin.userLoginData.token) {
                return (
                    <div className={classes.loginWrapper}>
                        <Tippy
                            placement="bottom"
                            arrow={false}
                            delay={[0, 0]}
                            animation="scale-subtle"
                            interactive={true}
                            content={<LoggedIn menu={investorLoggedIn} />}
                            className={classes.tippy}>
                            <button className={classes.loginBtn}>
                                Namaste, {props.investorLogin.userLoginData?.data[0]?.firstName}{" "}
                                <i className="fa-solid fa-caret-down"></i>
                            </button>
                        </Tippy>
                    </div>
                );
            } else {
                return (
                    <div onClick={() => navigate("/investor/register")} className={classes.loginWrapper}>
                        <Tippy
                            placement="bottom"
                            arrow={false}
                            delay={[0, 0]}
                            animation="scale-subtle"
                            interactive={false}
                            // content={<DropdownTippy />}
                            className={classes.tippy}>
                            <button className={classes.loginBtn}>Login/Register</button>
                        </Tippy>
                    </div>
                );
            }
        }
    };
    const [menuOpen, setMenuOpen] = useState(false)
    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const handleClose = () => setMenuOpen(false)

    return (
        <>
            <div className={classes.container} >
                {/* <div><div className={classes.container} > </div> */}
                <div className={classes.hamBerger} onClick={expand}>
                    <div className={classes.hamBergerBar}></div>
                    <div className={classes.hamBergerBar}></div>
                    <div className={classes.hamBergerBar}></div>
                    <div className={classes.hamBergerBar}></div>
                </div>
                <div className={classes.navWrapper}>
                    {/* <div className={classes.navWrapper}></div> */}
                    {props.section === "department" ? (
                        <div className={classes.linksWrapperSection}>
                            {props.departmentLogin &&
                                props.departmentLogin.allMenu &&
                                props.departmentLogin.allMenu.map((d, i) => <SubMenu subMenu={d} key={i} />)}
                        </div>
                    ) : (
                        <div className={classes.linksWrapper}>
                            <div
                                className={location.pathname === "/investor/homepage" ? classes.linksActive : classes.links}
                                onClick={() => navigate("/")}>
                                Home
                            </div>
                            <Tippy
                                placement="bottom-start"
                                arrow={false}
                                delay={[0, 0]}
                                animation="shift-away"
                                interactive={true}
                                content={<AboutUsDropdown setAboutUsNavbarHover={setAboutUsNavbarHover} />}
                                className={classes.tippyAlt}>
                                <div
                                    className={
                                        location.pathname === "/investor/biharhaitaiyar" ||
                                            location.pathname === "/investor/about-us/overview" ||
                                            location.pathname === "/investor/about-us/department-functions" ||
                                            location.pathname === "/investor/about-us/organizational-structure" ||
                                            aboutUsNavbarHover
                                            ? classes.linksActive
                                            : classes.links
                                    }>
                                    About Us
                                </div>
                            </Tippy>
                            <Tippy
                                placement="bottom-start"
                                arrow={false}
                                delay={[0, 0]}
                                animation="shift-away"
                                interactive={true}
                                content={
                                    <ServicesAndSchemesDropdown
                                        noticeBoardNavbarHover={noticeBoardNavbarHover}
                                        setNoticeBoardNavbarHover={setNoticeBoardNavbarHover}
                                        setServicesAndSchemesNavbarHover={setServicesAndSchemesNavbarHover}
                                    />
                                }
                                className={classes.tippyAlt}>
                                <div
                                    // onClick={() => navigate("/investor/services-and-schemes")}
                                    className={
                                        location.pathname === "/investor/services-and-schemes/single-window" ||
                                            location.pathname === "/investor/services-and-schemes/schemes" ||
                                            servicesAndSchemesNavbarHover
                                            ? classes.linksActive
                                            : classes.links
                                    }>
                                    Services & Schemes
                                </div>
                            </Tippy>
                            <Tippy
                                placement="bottom-end"
                                arrow={false}
                                delay={[0, 0]}
                                animation="shift-away"
                                interactive={true}
                                content={<InvestorZoneDropdown setInvestorZoneNavbarHover={setInvestorZoneNavbarHover} />}
                                className={classes.tippyAlt}>
                                <div
                                    className={
                                        location.pathname === "/investor/sectors" ||
                                            location.pathname === "/investor/faq" ||
                                            location.pathname === "/investor/important-downloads" ||
                                            location.pathname.includes("investor-home") ||
                                            investorZoneNavbarHover
                                            ? classes.linksActive
                                            : classes.links
                                    }
                                // onClick={() => navigate("/investor/investor-home")}
                                >
                                    Investors Zone
                                </div>
                            </Tippy>
                            <Tippy
                                placement="bottom-end"
                                arrow={false}
                                delay={[0, 0]}
                                animation="shift-away"
                                interactive={true}
                                content={<EODBDropdown setEodbHover={setEodbHover} />}
                                className={classes.tippyAlt}>
                                <div
                                    className={
                                        location.pathname === "/investor/grievance-report" ||
                                            location.pathname === "/investor/incentive-granted" ||
                                            location.pathname.includes("/investor/eodb") ||
                                            location.pathname === "/investor/queries-handled" ||
                                            location.pathname === "/investor/calculator" ||
                                            eodbHover
                                            ? classes.linksActive
                                            : classes.links
                                    }
                                // onClick={() => navigate("/investor/investor-home")}
                                >
                                    EODB
                                </div>
                            </Tippy>
                            <div
                                className={location.pathname === "/investor/guidelines" ? classes.linksActive : classes.links}
                                onClick={() => navigate("/investor/guidelines")}>
                                Guidelines
                            </div>
                            <div
                                className={location.pathname === "/investor/policies-acts" ? classes.linksActive : classes.links}
                                onClick={() => navigate("/investor/policies-acts")}>
                                Policies/Acts
                            </div>
                            {/* <div className={classes.links}>Notice Board</div> */}
                            <Tippy
                                placement="bottom-end"
                                arrow={false}
                                delay={[0, 0]}
                                animation="shift-away"
                                interactive={true}
                                content={<ContactUsDropdown setContactUsNavbarHover={setContactUsNavbarHover} />}
                                className={classes.tippyAlt}>
                                <div
                                    className={
                                        location.pathname === "/investor/contact-us/key-contacts" ||
                                            location.pathname === "/investor/contact-us/head-office" ||
                                            location.pathname === "/investor/contact-us/dic" ||
                                            location.pathname === "/investor/contact-us/authority" ||
                                            location.pathname === "/investor/contact-us/others" ||
                                            contactUsNavbarHover
                                            ? classes.linksActive
                                            : classes.links
                                    }>
                                    Contact Us
                                </div>
                            </Tippy>
                            {/* <Tippy
                                placement="bottom-start"
                                arrow={false}
                                delay={[0, 0]}
                                animation="shift-away"
                                interactive={true}
                                // content={<QuickLinkDropdown setQuickLinksNavbarHover={setQuickLinksNavbarHover} />}
                                className={classes.tippyAlt}>
                                <div
                                    onClick={() => navigate("/investor/quick-links")}
                                    className={
                                        location.pathname === "/investor/quick-links" || quickLinksNavbarHover
                                            ? classes.linksActive
                                            : classes.links
                                    }>
                                    Quick Links
                                </div>
                            </Tippy> */}
                        </div>
                    )}
                    {generateLoginSection(props.section)}
                </div>
            </div>
            <Navbar bg="custom" expand={false} >
                <Container fluid>
                    {/* <Navbar.Brand href="#">Navbar Brand</Navbar.Brand>   */}
                    {generateLoginSection(props.section)}
                    <Navbar.Toggle aria-controls="offcanvasNavbar" aria-labelledby='offcanvasNavbarLabel' onClick={toggleMenu} />
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        placement='start'
                        restoreFocus={false}
                        show={menuOpen}
                        onHide={handleClose}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbarLabel">Sidebar</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                {props.section === "department" ? (

                                    // <div className={classes.linksWrapperSection}>
                                    //     {props.departmentLogin &&
                                    //         props.departmentLogin.allMenu &&
                                    //         props.departmentLogin.allMenu.map((d, i) => <SubMenu subMenu={d} key={i} />)}
                                    // </div> 
                                    <Nav>
                                        {props.departmentLogin &&
                                            props.departmentLogin.allMenu &&
                                            props.departmentLogin.allMenu.map((d, i) => {
                                                return (
                                                    <>
                                                        {
                                                            d.children?.length ?
                                                                <NavDropdown title={d.name} id="offcanvasNavbarDropdown">
                                                                    {
                                                                        d.children && d.children.map((c) => {
                                                                            return (
                                                                                <NavDropdown.Item onClick={() => { navigate(c.path); toggleMenu() }}>{c.name}</NavDropdown.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </NavDropdown>
                                                                :
                                                                <Nav.Link onClick={() => { navigate(d.path); toggleMenu() }} >{d.name}</Nav.Link>
                                                        }
                                                    </>
                                                )
                                            })}
                                        {/* <Nav.Link onClick={() => navigate("/department/mis_report")} >MIS Report</Nav.Link> */}

                                    </Nav>
                                ) : (
                                    <Nav>
                                        <Nav.Link onClick={() => { navigate("/"); toggleMenu() }} >Home</Nav.Link>
                                        <NavDropdown title="About Us" id="offcanvasNavbarDropdown">
                                            <NavDropdown.Item onClick={() => { navigate("/investor/about-us/overview"); toggleMenu() }}>Overview</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/about-us/department-functions"); toggleMenu() }}>Department Functions</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/about-us/organizational-structure"); toggleMenu() }}>Organizational Structure</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Services & Schemes" id="offcanvasNavbarDropdown">
                                            <NavDropdown.Item onClick={() => { navigate("/investor/services-and-schemes/single-window"); toggleMenu() }}>Single Window</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/services-and-schemes/schemes"); toggleMenu() }}>Schemes</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Investors Zone" id="offcanvasNavbarDropdown">
                                            <NavDropdown.Item onClick={() => { navigate("/investor/sectors"); toggleMenu() }}>Sectors</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/faq"); toggleMenu() }}>Faq</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/notice-board"); toggleMenu() }}>Notice Board</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { openInNewTab(swcManual); toggleMenu() }}>SWC User Manual</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/licence-permit-wizard"); toggleMenu() }}>License Permit Wizard</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/district"); toggleMenu() }}>District</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/important-downloads"); toggleMenu() }}>Important Downloads</NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="EODB" id="offcanvasNavbarDropdown">
                                            <NavDropdown.Item onClick={() => { navigate("/investor/eodb"); toggleMenu() }}>Dashboard</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/grievance-report"); toggleMenu() }}>Grievance Redressal Dashboard</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/calculator"); toggleMenu() }}>Incentive Calculator</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/incentive-granted"); toggleMenu() }}>Incentives Granted Dashboard</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/queries-handled"); toggleMenu() }}>Queries Handled Dashboard</NavDropdown.Item>
                                        </NavDropdown>
                                        <Nav.Link onClick={() => { navigate("/investor/guidelines"); toggleMenu() }} >Guidelines</Nav.Link>
                                        <Nav.Link onClick={() => { navigate("/investor/policies-acts"); toggleMenu() }} >Policies/Acts</Nav.Link>
                                        <NavDropdown title="Contact Us" id="offcanvasNavbarDropdown">
                                            <NavDropdown.Item onClick={() => { navigate("/investor/contact-us/key-contacts"); toggleMenu() }}>Key Contacts</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/contact-us/head-office"); toggleMenu() }}>Head Office</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/contact-us/dic"); toggleMenu() }}>DICs</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/contact-us/authority"); toggleMenu() }}>Authority/Board/Corp</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => { navigate("/investor/contact-us/others"); toggleMenu() }}>Others</NavDropdown.Item>
                                        </NavDropdown>
                                        {/* <Nav.Link onClick={() => navigate("/investor/quick-links")} >Quick Links</Nav.Link> */}
                                    </Nav>
                                )}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        departmentLogin: state.DepartmentReducer.Login,
        investorLogin: state.Auth,
    };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BottomNav));
