import * as actionTypes from './actionTypes';
import { nocTabs } from "../tabs"

const initialState = {
    licenseId: "",
    nocTabs: {
        ...nocTabs
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_LICENCE_ID:
            state = {
                ...state,
                licenseId: action.payload,
            }
            break;
        case actionTypes.UPDATE_TABS:
            state = {
                ...state,
                nocTabs: action.payload
            }
            break;
    }
    return state;
}

export default reducer;