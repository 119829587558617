
const scene = "department/ownership";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ALL_DATA = `${scene}UPDATE_ALL_DATA`;
export const UPDATE_SECTION = `${scene}UPDATE_SECTION`;
export const UPDATE_TABLE_PARAMETER = `${scene}UPDATE_TABLE_PARAMETER`;
export const UPDATE_ASSIGNED = `${scene}UPDATE_ASSIGNED`;
export const UPDATE_PROCESSED = `${scene}UPDATE_PROCESSED`;
export const UPDATE_NEW = `${scene}UPDATE_NEW`;
export const INITIAL_STATE = `${scene}INITIAL_STATE`;
export const UPDATE_FILTER = `${scene}UPDATE_FILTER`;
