
const scene = "investor/caf1/employment";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ONLOAD = `${scene}UPDATE_ONLOAD`;
export const UPDATE_EMPLOYMENT = `${scene}UPDATE_EMPLOYMENT`;
export const ADD_EMPLOYMENT = `${scene}ADD_EMPLOYMENT`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
export const UPDATE_EXPAND = `${scene}UPDATE_EXPAND`;
export const COMPLETE = `${scene}COMPLETE`;
export const UPDATE_INITIAL = `${scene}UPDATE_INITIAL`;

