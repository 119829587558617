import React from "react";
import classes from "../commonCss/dropdownTippyStyles.module.scss";
import { useNavigate } from "react-router-dom";

const AboutUsDropdown = ({ setAboutUsNavbarHover }) => {
	const navigate = useNavigate();
	return (
		<div
			className={classes.listContainer}
			onMouseLeave={() => setAboutUsNavbarHover(false)}
			onMouseEnter={() => setAboutUsNavbarHover(true)}
		>
			<div className={classes.items} onClick={() => navigate("/investor/about-us/overview")}>
				Overview
			</div>
			<div className={classes.items} onClick={() => navigate("/investor/about-us/department-functions")}>
				Department Functions
			</div>
			<div className={classes.items} onClick={() => navigate("/investor/about-us/organizational-structure")}>
				Organizational Structure
			</div>
			<div className={classes.items} onClick={() => navigate("/investor/biharhaitaiyar")}>
			Our Success Stories
			</div>
		</div>
	);
};

export default AboutUsDropdown;
