import { combineReducers } from "redux";

import Login from "../../pages/department/login/reducer"
import Dashboard from "../../pages/department/dashboard/reducer"
import ApplicationDetails from "../../pages/department/applicationDetails/reducer"
import SingleApplication from "../../pages/department/singleApplication/reducer"
import AddUser from "../../pages/department/addUser/reducer"
import MisReport from "../../pages/department/misReport/reducer";
import SIPBMeeting from "../../pages/department/sipbMeeting/reducer";
import AdminDashboard from "../../pages/department/adminDashboard/reducer";
import Ownership from "../../pages/department/ownership/reducer";
import UploadProceeding from "../../pages/department/uploadProceeding/reducer";
import DocumentManagement from "../../pages/department/documentManagement/reducer";
import Roles from "../../pages/department/addRoles/reducer";


const departmentReducer = combineReducers({
    Login,
    Dashboard,
    SingleApplication,
    AddUser,
    ApplicationDetails,
    MisReport,
    SIPBMeeting,
    AdminDashboard,
    Ownership,
    UploadProceeding,
    DocumentManagement,
    Roles
})

export default departmentReducer;
