
const scene = "department/adminDashboard";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ALL_DATA = `${scene}UPDATE_ALL_DATA`;
export const UPDATE_SECTION = `${scene}UPDATE_SECTION`;
export const UPDATE_TABLE_PARAMETER = `${scene}UPDATE_TABLE_PARAMETER`;
export const UPDATE_ASSIGNED = `${scene}UPDATE_ASSIGNED`;
export const UPDATE_INNERDATA = `${scene}UPDATE_INNERDATA`;
export const UPDATE_INNERDATA_INC11 = `${scene}UPDATE_INNERDATA_INC11`;
export const UPDATE_PROCESSED = `${scene}UPDATE_PROCESSED`;
export const UPDATE_PROCESSED_INITAL = `${scene}UPDATE_PROCESSED_INITAL`;
export const UPDATE_PENDENCY= `${scene}UPDATE_PENDENCY`;