import * as actionTypes from './actionTypes';

const allData = {
    "equityContribution": null,
    "govtGrant": null,
    "termLoan": null,
    "unsecuredLoan": null,
    "other": null,
    "fcOther": null,
    "existing": null,
    "proposed": null,
    "total": null,
    "commecementConstruction": "",
    "civilConstruction": "",
    "trialProduction": "",
    "commercialProduction": ""
}

const initialState = {
    allData: {
        ...allData
    },
    allQuery: [],
    allQueryLength: 0,
    complete: false,
    isExpand: false,
    fcData: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_FINANCE_ON_LOAD:
            state = {
                ...state,
                allData: action.payload.data ? action.payload.data : state.allData,
                allDataExisting: action.payload.existing ? action.payload.existing : state.allDataExisting,
                isExpand: action.payload.isExpand
            }
            break;
        case actionTypes.UPDATE_INITIAL:
            state = {
                ...state,
                allData: { ...allData },
            }
            break;
        case actionTypes.UPDATE_FINANCE:
            state = {
                ...state,
                allData: action.payload
            }
            break;
        case actionTypes.UPDATE_QUERY:
            state = {
                ...state,
                allQuery: action.payload,
                allQueryLength: action.payload.queryChat ? action.payload.queryChat.length : 0
            }
            break;
        case actionTypes.COMPLETE:
            state = {
                ...state,
                complete: action.payload
            }
            break;
        case actionTypes.UPDATE_REDUCER:
            state = {
                ...state,
                [action.payload.key]: action.payload.data
            }
            break;
        default:
            state = { ...state };
            break;

    }
    // console.log(state);
    return state;
}

export default reducer;