import {
    SET_CRUD_LOADING,
    SET_CRUD_ERROR,
    SET_CRUD_SUCCESS,
    SET_UNIT_LIST_DATA,
    SET_UNIT_BY_ID_DATA,
    SET_CREATE_UNIT_DATA,
    SET_EDIT_UNIT_DATA,
    SET_DELETE_UNIT_DATA,
} from "./actionTypes";

import { getAPI, putAPI, deleteAPI, postAPI } from "../../../apiCalls/functions";
import {
    UNIT_LIST,
    UNIT_BY_ID,
    CREATE_UNIT,
    EDIT_UNIT,
    DELETE_UNIT,
} from "../../../apiCalls/urls/investor/unit/CRUD/unitCrudURL";

import { actionNotifier } from "../../../components/ui/toast/toast";
import { apiLoading, apiLoadingEnd } from "../../notification/action";

const softwareSection = process.env.REACT_APP_INVESTOR_SECTION_NAME

// Create Unit

export const createUnit = (unitData) => async (dispatch) => {
    dispatch(setCrudLoading(true));
    let createUnitData = await postAPI(CREATE_UNIT, unitData, softwareSection);
    console.log(createUnitData)
    if (createUnitData.status) {
        actionNotifier.success("Unit Added Successfully")
        dispatch(setCreateUnitData(createUnitData));
    }
    else{
        actionNotifier.error(createUnitData.message);
    }
    dispatch(setCrudLoading(false));
};

export const setCreateUnitData = (createUnitData) => {
    return {
        type: SET_CREATE_UNIT_DATA,
        createUnitData,
    };
};

// Unit List

export const getUnitList = (generalDetailId) => async (dispatch) => {
    dispatch(apiLoading())
    let unitListData = await unitCrudAPIHandler(postAPI, UNIT_LIST, { generalDetailId }, dispatch);
    dispatch(setUnitListData(unitListData));
    dispatch(apiLoadingEnd())
};

export const setUnitListData = (unitListData) => {
    return {
        type: SET_UNIT_LIST_DATA,
        unitListData,
    };
};

// Unit By ID

export const getUnitById = (id) => async (dispatch) => {
    let unitByIdData = await unitCrudAPIHandler(getAPI, `${UNIT_BY_ID}/${id}`, null, dispatch);
    dispatch(setUnitByIdData(unitByIdData));
};

export const setUnitByIdData = (unitByIdData) => {
    return {
        type: SET_UNIT_BY_ID_DATA,
        unitByIdData,
    };
};

// Edit Unit

export const editUnit = (unitData) => async (dispatch) => {
    let editUnitData = await unitCrudAPIHandler(putAPI, EDIT_UNIT, unitData, dispatch);
    if (editUnitData.status) {
        actionNotifier.success("Unit Edited Successfully");
        dispatch(setEditUnitData(editUnitData));
    }
    else{
        actionNotifier.error("Unit Editing Failed! Please try again");
    }
    
};

export const setEditUnitData = (editUnitData) => {
    return {
        type: SET_EDIT_UNIT_DATA,
        editUnitData,
    };
};

// Delete Unit

export const deleteUnit = (id) => async (dispatch) => {
    let deleteUnitData = await unitCrudAPIHandler(deleteAPI, `${DELETE_UNIT}/${id}`, null, dispatch);
    dispatch(setDeleteUnitData(deleteUnitData));
};

export const setDeleteUnitData = (deleteUnitData) => {
    return {
        type: SET_DELETE_UNIT_DATA,
        deleteUnitData,
    };
};

// Loading

export const setCrudLoading = (crudLoadingValue) => {
    return {
        type: SET_CRUD_LOADING,
        crudLoadingValue,
    };
};

// Error

export const setCrudError = (crudErrorValue) => {
    return {
        type: SET_CRUD_ERROR,
        crudErrorValue,
    };
};

// Success

export const setCrudSuccess = (crudSuccessValue) => {
    return {
        type: SET_CRUD_SUCCESS,
        crudSuccessValue,
    };
};

const unitCrudAPIHandler = async (callback, action, payload, dispatch) => {
    try {
        dispatch(setCrudLoading(true));
        dispatch(setCrudError(false));
        dispatch(setCrudSuccess(false));
        let apiResponse = await callback(action, payload);
        if (apiResponse?.status === false) {
            throw new Error();
        }
        dispatch(setCrudLoading(false));
        dispatch(setCrudSuccess(true));
        return apiResponse;
    } catch (error) {
        dispatch(setCrudError(true));
        dispatch(setCrudLoading(false));
        dispatch(setCrudSuccess(false));
    }
};
