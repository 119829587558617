import React from "react";
import { Link } from "react-router-dom";

import classes from "./middleNav.module.scss";
import DOI from "../../../../assets/images/homepage/nav/doi.png";
import SW from "../../../../assets/images/homepage/nav/sw.png";
import MAKE from "../../../../assets/images/homepage/nav/make.jpg";
import EMBLEM from "../../../../assets/images/homepage/nav/emblem.png";
import NSWS from "../../../../assets/images/logo_nsws-removebg-preview.png";



const MiddleNav = () => {
    return (
        <div className={classes.container}>
            <div className={classes.navWrapper}>
                <div className={classes.leftImgWrapper}>
                    <Link to="investor/homepage">
                        <img src={DOI} alt="logos" className={classes.leftImg}></img>
                    </Link>
                </div>
                
                <div className={classes.rightImgWrapper}>
                    {/* <img src={SW} alt="logos" className={classes.rightImg}></img>
                    <img src={MAKE} alt="logos" className={classes.rightImg}></img> */}
                    {/* <img src={NSWS} alt="logos" className={classes.rightImg}></img> */}
                </div>
            </div>
        </div>
    );
};

export default MiddleNav;
