const scene = "investor/incentive11/";

export const UPDATE_DATA = `${scene}UPDATE_DATA`;

export const UPDATE_INITIAL_DATA = `${scene}UPDATE_INITIAL_DATA`;
export const UPDATE_TABS = `${scene}UPDATE_TABS`;
export const UPDATE_WIZARD = `${scene}UPDATE_WIZARD`;
export const UPDATE_INCENTIVE_ID = `${scene}UPDATE_INCENTIVE_ID`;
export const UPDATE_IS_EXPANSION = `${scene}UPDATE_IS_EXPANSION`;




