import * as actionTypes from "./actionTypes";

const initialState = {
  sample: "",
};

const sample = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ON_LOAD:
      state = {
        ...state,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default sample;
