import * as actionTypes from './actionTypes';


const initialState = {
    selectedSection: "pre-establishment",
    "pre-establishment": [],
    "pre-operation": [],
    result: [],
    showResult: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SELECTED_SECTION:
            state = {
                ...state,
                selectedSection: action.payload,
            }
            break;
        case actionTypes.UPDATE_QUESTION:
            state = {
                ...state,
                [action.payload.section]: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_RESULT:
            state = {
                ...state,
                result: action.payload,
            }
            break;
        case actionTypes.UPDATE_RESULT_BOOLEAN:
            state = {
                ...state,
                showResult: action.payload,
            }
            break;
    }

    return state;
}

export default reducer;