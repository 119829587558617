
const scene = "investor/caf1/enterprizeProfile";

export const ON_LOAD = `${scene}ON_LOAD`;
export const UPDATE_ONLOAD = `${scene}UPDATE_ONLOAD`;
export const UPDATE_DOCUMENT = `${scene}UPDATE_DOCUMENT`;
export const UPDATE_DOCUMENT_TYPE = `${scene}UPDATE_DOCUMENT_TYPE`
export const ADD_DOCUMENT = `${scene}ADD_DOCUMENT`;
export const DELETE_DOCUMENT = `${scene}DELETE_DOCUMENT`;


export const TOGGLE_LOADING = `${scene}TOGGLE_LOADING`;
export const UPDATE_LOADING_PERCENT = `${scene}UPDATE_LOADING_PERCENT`;
export const UPDATE_DOCUMENT_ON_SUCCESS = `${scene}UPDATE_DOCUMENT_ON_SUCCESS`;
export const UPDATE_DOCUMENT_ON_FAILURE = `${scene}UPDATE_DOCUMENT_ON_FAILURE`;
export const COMPLETE = `${scene}COMPLETE`;
export const UPDATE_QUERY = `${scene}UPDATE_QUERY`;
