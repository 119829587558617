import * as actionTypes from './actionTypes';
const allData = {
    refNo:"",
    "plotArea": "",
    "mauzaWardNo": "",
    "msCsPlotNo": "",
    "khataNoStreetNo": "",
    "taujiNoCircleNo": "",
    "thanaNoMohallaNo": "",
    "district": "",
    "policeStationDistrict": "",
    "fireStation": "",
    "routeDetails": "",
    "sourceOfWater": "",
    "waterSourceDistance": "",
    "widthOfRoad": "",
    "licensingAuthorityDesignation": "",
    "licensingAuthorityAddress": "",
    "landOwnerName": "",
    "landOwnerEmail": "",
    "landOwnerContactNo": "",
    "ownershipType": "",
    "landOwnerAddress": "",
    landOwnerFax:"",
    "architectName": "",
    "architectEmail": "",
    "architectContactNo": "",
    "architectLicenseNo": "",
    "architectAddress": "",
    "builderName": "",
    "builderEmail": "",
    "builderContactNo": "",
    "builderLicenseNo": "",
    "builderAddress": ""
}

const initialState = {
    allData: {
        ...allData
    },
    district: [],
    ownershipType: [],
    allWater: []


}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ALL_DATA:
            state = {
                ...state,
                allData: action.payload,
            }
            break;
        case actionTypes.RESET_DATA:
            state = {
                ...state,
                allData: { ...allData },
            }
            break;
        case actionTypes.UPDATE_DROPDOWN:
            state = {
                ...state,
                district: action.payload.district,
                ownershipType: action.payload.ownershipType,
                allWater: action.payload.water
            }
            break;
    }
    return state;
}

export default reducer;