import * as actionTypes from './actionTypes';

const initialState = {
    incentiveType: "",
    id:"",
    allData: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DATA:
            state = {
                ...state,
                allData: action.payload.data,
                incentiveType:action.payload.type
            }
            break;
    }
    return state;
}

export default reducer;