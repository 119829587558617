import { useRef, useEffect } from "react";
import classes from "./styles.module.scss";
import { withTranslation } from "react-i18next";

const Input = ({ type, title, min, step, name, className, placeholder, value, defaultValue, required, disabled, options, optionValue, optionLabel, onChange, onChangeData, error, forms, max, ...props }) => {
    // console.log(forms)
    const quantityInputRef = useRef(null);
    const checkData = (type, step, data) => {
        // console.log(defaultValue, value, type)
        if (type === "number" && step) {
            return parseFloat(data)
        }
        else if (type === "number")
            return parseInt(data)
        else
            return data

    }

    const handleOnChange = (e) => {
        // console.log(defaultValue, value, type, e.target.value)
        onChange(checkData(type, step, e.target.value))
    }

    useEffect(() => {
        const ignoreScroll = (e) => {
            // console.log(e)
            e.preventDefault();
        };
        quantityInputRef.current && quantityInputRef.current.addEventListener("wheel", ignoreScroll);
    }, [quantityInputRef]);

    const onWheel = (e) => {
        e.currentTarget.blur()
        // console.log(e)
    }

    const renderByType = (type) => {
        if (type === "textarea") {
            return (
                <>
                    <textarea
                        type={type}
                        className={classes.formInput + " " + (classes[className] ? classes[className] : "")}
                        placeholder={placeholder}
                        onChange={(e) => handleOnChange(e)}
                        value={value}
                        defaultValue={defaultValue}
                        required={required}
                        disabled={disabled}
                        name={name}
                        style={{ height: 'auto' }}
                        rows="4"
                        {...forms}

                    />
                    {error ? <span className={classes.errorText}>{error}</span> : <span className={classes.errorText}></span>}
                </>
            )
        }
        else if (type === "select") {
            // console.log(options)
            return (
                <>
                    <select
                        type={type}
                        className={classes.formInput + " " + (classes[className] ? classes[className] : "")}
                        defaultValue={defaultValue}
                        onChange={(e) => handleOnChange(e)}
                        value={value}
                        name={name}
                        required={required}
                        disabled={disabled}
                        {...forms}
                    >
                        <option value={""} selected>Select {placeholder}</option>
                        {
                            options && options.map((o, i) => (
                                <option key={i} value={o[optionValue]}>{o[optionLabel]}</option>
                            ))
                        }
                    </select>
                    {error ? <span className={classes.errorText}>{error}</span> : <span className={classes.errorText}></span>}
                </>
            )
        }
        else if (type === "radio") {
            return (
                <span className={classes.radio} style={{ marginTop: '-8px', ...props.optionStyle }}>
                    {
                        options && options.map((p, i) => (
                            <span className={classes.singleRadio}>
                                <input
                                    type="radio"
                                    className={classes.checkbox}
                                    required={required}
                                    onChange={e => handleOnChange(e)}
                                    {...forms}
                                    name={name}
                                    value={p[optionValue]}
                                    checked={p[optionValue] === value ? true : false}
                                />
                                <span>{p[optionLabel]}</span>
                            </span>
                        ))
                    }

                </span>
            )
        }
        else {
            return (
                <>
                    <input
                        type={type}
                        min={min}
                        className={classes.formInput + " " + (classes[className] ? classes[className] : "")}
                        placeholder={placeholder}
                        onChange={(e) => handleOnChange(e)}
                        value={value}
                        defaultValue={defaultValue ? defaultValue : ""}
                        required={required}
                        disabled={disabled}
                        step={step}
                        name={name}
                        // autoFocus="autofocus"
                        style={props.style}
                        onWheel={(e) => onWheel(e)}
                        {...forms}
                        max={max}
                    />
                    {error ? <span className={classes.errorText}>{error}</span> : (props.hideError ? "" : <span className={classes.errorText}></span>)}
                    {
                        props.showEye && <i class={`fa fa-eye ${classes.passwordEye}`} onClick={() => props.setEye(!props.eye)} aria-hidden="true"></i>
                    }


                </>
            )
        }
    }

    const handleChangeCheck = (e) => {
        if (e.target.checked) {
            onChange("Yes")
        }
        else {
            onChange("No")
        }

    }

    return (
        <>
            {
                type === "checkbox"
                    ?
                    <>
                        <div className={classes.checkboxWrapper}>
                            <input
                                type="checkbox"
                                className={classes.checkbox}
                                required={required}
                                onChange={e => handleChangeCheck(e, name)}
                                checked={value === "Yes" ? true : false}
                                disabled={disabled}
                            />
                            <span style={{ textAlign: 'left' }}>{title}</span>
                        </div>
                        {error ? <span className={classes.errorText}>{error}</span> : null}
                    </>
                    :
                    <div className={classes.inputSection} style={type === "radio" ? { display: "flex", ...props.sectionStyle } : {}}>
                        {
                            title
                                ?
                                <div className={classes.inputLabelText} style={{ ...props.labelStyle }}>{title}&nbsp;</div>
                                : null
                        }
                        {renderByType(type)}
                    </div>
            }
        </>

    )
}

export default (withTranslation()(Input));